import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    z-index: 100;
    
    transform: translate(-50%, 0);

    @media all and (max-width:576px){
        width: 100%;
    }
`;

export const Wrapper = styled.div`
    padding: 0 10px;
    width: 100%;
    box-sizing: border-box;
`;

export const SearchWrapper = styled.div`
    /* max-width: 600px; */
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 50px;
    border-radius: 15px;
    background-color: #fff;
    box-sizing: border-box;
    overflow: hidden;
    padding: 0 10px;
    border: 1.5px solid #00000000;
    transition: all .3s;
    box-shadow: var(--defaultBoxShadow3);

    @media all and (max-width:576px){
        width: 100%;
    }

    .iconBox{
        width: 24px;
    }

    input{
        border: none;
        border-radius: 0;
        font-weight: 600;
        background: #00000000;

        &:focus{
            ~ .iconBox{
                scale: 1.2;
            }
            // code in here
        }
    }

    button{
        width: 50px;
        height: 30px;
        background: #efefef;
        border: none;
        border-radius: 10px;
        font-size: 13px;
        font-weight: 600;
    }
`;