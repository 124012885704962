import styled from 'styled-components';

export const Container = styled.div`
    max-width: 600px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    box-sizing: border-box;
`;

export const LinksWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    .iconLink{
        cursor: pointer;
        width: 35px;
        height: 35px;

        &:hover{
            scale: 1.1;
        }
        svg{
            width: 100%;
            height: 100%;
        }
    }
`;