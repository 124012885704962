import axios from "axios"

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function UserPagesApi(props) {
    return {
        searchOne: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/user-pages/${params?.id}`, {
                headers: headers
            });
        },
        searchByDomainName: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/user-pages/domainName/${params?.domainName}`, {
                headers: headers
            });
        },
        searchByAuthUser: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/user-pages/search/authUser`, {
                headers: headers,
            })
        },
        isExistedSameDomain: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/user-pages/isExistedSameDomain`, body, {
                headers: headers,
            })
        },
        create: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/user-pages/create`, body, {
                headers: headers,
            })
        },
        update: async ({ body, headers }) => {
            return await axios.put(`${mainApiHost}/user-pages/update`, body, {
                headers: headers,
            })
        },
        changeOne_backgroundDesign: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/user-pages/one/backgroundDesign`, body, {
                headers: headers,
            })
        },
        changeOne_designs: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/user-pages/one/designs`, body, {
                headers: headers,
            })
        },
        changeOne_viewCategoryFlag: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/user-pages/one/viewCategoryFlag`, body, {
                headers: headers,
            })
        },
        changeOne_noticeConfig: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/user-pages/one/noticeConfig`, body, {
                headers: headers,
            })
        },
        changeOne_searchConfig: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/user-pages/one/searchConfig`, body, {
                headers: headers,
            })
        },
        changeOne_socialConfig: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/user-pages/one/socialConfig`, body, {
                headers: headers,
            })
        }
    }
}