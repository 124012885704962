import styled from 'styled-components';

export const Container = styled.div`

`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 15px;
    flex-wrap: wrap;
    button{
        width: auto;
        padding: 0 16px;
        font-weight: 700;
        border-radius: 10px;
        background: #000;
        color: #fff;
        border: none;

        &:hover{
            background: #202020;
        }
    }
`;

export const NoticeModalContainer = styled.div`
    padding: 20px;

    .wrapper{
        .wrapper__switchBox{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            section.first{
                font-weight: 600;
            }
        }

        .wrapper__inputBox{
            position: relative;
            margin-top: 20px;
            .counter{
                font-size: 10px;
                margin-top: 5px;
                padding-right: 20px;
                text-align: right;
                font-weight: 600;
            }
        }

        .wrapper__designLink{
            margin-top: 5px;
            span{
                cursor: pointer;
                font-size: 12px;
                color: #888;
                font-weight: 600;

                &:hover{
                    color: #777;
                }
            }
        }

        .wrapper__buttonGroup{
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 40px;

            button{
                width: 100px;
                border-radius: 10px;
                border: none;
                font-weight: 700;
            }

            button.cancel{
                background-color: var(--defaultElementBC);
            }

            button.confirm{
                background-color: var(--mainColor);
                color: #fff;
            }
        }
    }
`;

export const SearchConsoleModalContainer = styled.div`
    padding: 20px;
    .wrapper{
        .wrapper__switchBox{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            section.first{
                font-weight: 600;
            }
        }

        .wrapper__inputBox{
            position: relative;
            margin-top: 20px;

            .counter{
                font-size: 10px;
                margin-top: 5px;
                padding-right: 20px;
                text-align: right;
                font-weight: 600;
            }
        }

        .wrapper__designLink{
            margin-top: 5px;
            span{
                cursor: pointer;
                font-size: 12px;
                color: #888;
                font-weight: 600;

                &:hover{
                    color: #777;
                }
            }
        }

        .wrapper__buttonGroup{
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 40px;

            button{
                width: 100px;
                border-radius: 10px;
                border: none;
                font-weight: 700;
            }

            button.cancel{
                background-color: var(--defaultElementBC);
            }

            button.confirm{
                background-color: var(--mainColor);
                color: #fff;
            }
        }
    }
`;

export const MdSocialSetting = {
    BodyContainer: styled.div`
        /* padding:20px; */

        .header{
            padding: 0 20px;
            button{
                border-radius: 30px;
                font-weight: 700;
                font-size: 14px;
                color: #fff;
                background: #000;
                border: none;
            }
        }

        .body{
            margin-top: 20px;
            padding: 0 20px;
            .card{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 10px;
                border-radius: 10px;
                background-color: #ffffff;

                section.type{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    figure.icon{
                        width: 28px;
                        height: 28px;
                        margin: 0;

                        svg{
                            width: 100%;
                            height: 100%;
                        }

                        &.dnd{
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .name{
                        font-weight: 600;
                    }
                }

                section.edit{
                    button{
                        width: 24px;
                        height: 24px;
                        border-radius: 5px;
                        border: none;
                        &:hover{
                            background: #efefef;
                        }
                    }

                    figure.icon{
                        margin:0;
                        width: 70%;
                        height: 70%;
                    }
                }
            }
        }

        .footerWrapper{
            background-color: #fff;
            display: flex;
            justify-content: flex-end;
            gap: 10px;
            margin-top: 40px;
            padding: 20px;

            button{
                width: 100px;
                border-radius: 10px;
                border: none;
                font-weight: 700;
            }

            button.cancel{
                background-color: var(--defaultElementBC);
            }

            button.confirm{
                background-color: var(--mainColor);
                color: #fff;
            }
        }
    `,
    AddListContainer: styled.div`
        padding: 20px;

        .body{
            margin-top: 20px;
            .card{
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 10px;
                border-radius: 10px;

                &:hover{
                    background: #efefef;
                }
                section.type{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    flex-wrap: wrap-reverse;
                    figure.icon{
                        width: 28px;
                        height: 28px;
                        margin: 0;

                        svg{
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .alreadyAddedBadge{
                        font-size: 12px;
                        font-weight: 500;
                        color:var(--greenColor1)
                    }

                    .name{
                        font-weight: 600;
                    }
                }

                section.go{
                    figure.icon{
                        margin:0;
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    `,
    AddContainer: styled.div`
        padding: 20px;

        .body{
            margin-top: 20px;

            .targetSocial{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                section.icon{
                    width: 24px;
                    height: 24px;

                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }

                section.name{
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .inputBox{
                margin-top: 20px;
            }
        }

        .footer{
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            button{
                border-radius: 30px;
                border: none;
                background: var(--mainColor);
                font-weight: 700;
                color: #fff;
                transition: all .3s;
            }
        }
    `,
    EditContainer: styled.div`
        padding: 20px;

        .body{
            margin-top: 20px;

            .targetSocial{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                section.icon{
                    width: 24px;
                    height: 24px;

                    svg{
                        width: 100%;
                        height: 100%;
                    }
                }

                section.name{
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .inputBox{
                margin-top: 20px;
            }
        }

        .footer{
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            button{
                border-radius: 30px;
                border: 1px solid #00000000;
                font-weight: 700;
                transition: all .3s;
                
                &.edit{
                    color: #fff;
                    background: var(--mainColor);
                }

                &.delete{
                    border: 1px solid #e0e0e0;

                    &:hover{
                        border: 1px solid #00000000;
                        background: #efefef;
                    }
                }
            }

            .deleteConfirmBox {
                /* margin-top: 20px; */
                .notice{
                    font-size: 16px;
                    font-weight: 600;
                    text-align: center;
                }

                .buttonGroup{
                    margin-top: 30px;
                    display:flex;
                    gap: 10px;

                    button.delete{
                        background: var(--redColor1);
                        color: #ffffff;
                        border: 1px solid #00000000;
                    }

                    button.cancel{
                        border: 1px solid #e0e0e0;

                        &:hover{
                            border: 1px solid #00000000;
                            background: #efefef;
                        }
                    }
                }
            }
        }
    `,
}