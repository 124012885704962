import { UsersApi } from "../../../apis/UsersApi";
import { tokenRefreshBeforeRequest } from "../../../firebase/auth";

const usersApi = UsersApi();

export function useApiHook(props) {
    const reqUserSync = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await usersApi.sync({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
    }

    return {
        reqUserSync
    }
}