import { createUserWithEmailAndPassword, getAuth, onAuthStateChanged, sendEmailVerification } from "firebase/auth";
import CustomBlockButton from "../../components/button/CustomBlockButton";
import firebaseAuth from "../../firebase/auth";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useApiHook } from "./hooks/useApiHook";
import { customToast } from "../../components/toast/custom-react-toastify/v1";
import * as St from './index.styled';
import { CustomRatioImage } from "../../components/image/CustomRatioImage";
import { CustomInput } from "../../components/input/CustomInput";
import { CustomBackdropController } from "../../components/backdrop/default/v1/core";

const isEmailFormatValid = (email) => {
    let regex = /^([\w._-])*[a-zA-Z0-9]+([\w._-])*([a-zA-Z0-9])+([\w._-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/;
    return regex.test(email);
}

const isPasswordFormatValid = (password) => {
    var num = password.search(/[0-9]/g);
    var eng = password.search(/[a-z]/ig);
    var spe = password.search(/[\\!@#$%^&*()\-_=+[\]{};:`"',.<>/?|~]/gi);

    if (password.length < 8 || password.length > 50) { // 글자수 제한
        return false;
    } else if (password.search(/\s/) !== -1) { // 공백 체크
        return false;
    } else if (num < 0 || eng < 0 || spe < 0) { // 영문, 숫자, 특수문자 혼합 체크
        return false;
    } else {
        return true;
    }
}

const isComparePasswordFormatValid = (password, passwordChecker) => {
    if (password !== passwordChecker) {
        return false;
    } else {
        return true;
    }
};

const customBackdropController = CustomBackdropController();

export function MainView(props) {
    const navigate = useNavigate();

    const apiHook = useApiHook();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordChecker, setPasswordChecker] = useState('');
    const [isSuccessSignup, setIsSuccessSignup] = useState(false);

    const handleChangeEmailFromEvent = (e) => {
        let value = e.target.value;
        setEmail(value);
    }

    const handleChangePasswordFromEvent = (e) => {
        let value = e.target.value;
        setPassword(value);
    }

    const handleChangePasswordCheckerFromEvent = (e) => {
        let value = e.target.value;
        setPasswordChecker(value);
    }

    const handleSignup = async () => {
        if (!isEmailFormatValid(email)) {
            customToast.error('이메일을 바르게 입력해주세요.');
            return;
        }

        if (!isPasswordFormatValid(password)) {
            customToast.error('비밀번호는 최소 8자 이상 영문, 숫자, 특수문자를 혼합하여 입력해 주세요.');
            return;
        }

        if (!isComparePasswordFormatValid(password, passwordChecker)) {
            customToast.error('비밀번호를 다시 확인해 주세요.');
            return;
        }

        customBackdropController.showBackdrop();
        try {
            const signupResult = await createUserWithEmailAndPassword(firebaseAuth, email, password)

            if (signupResult) {
                apiHook.reqUserSync({ body: null, headers: null });
                const sendEmailResult = await sendEmailVerification(signupResult?.user);
                setIsSuccessSignup(true);
            }
        } catch (err) {
            switch (err.code) {
                case 'auth/invalid-email':
                    customToast.error('이메일을 바르게 입력해주세요.');
                    break
                case 'auth/weak-password':
                    customToast.error('비밀번호는 최소 6자리 이상으로 설정해 주세요.');
                    break
                case 'auth/email-already-in-use':
                    customToast.error('이미 등록된 이메일 입니다.');
                    break
                default:
                    customToast.error('회원가입 시도 중 에러가 발생했습니다.');
                    break
            }
        }
        customBackdropController.hideBackdrop();
    }

    return (
        <>
            <St.Container>
                {isSuccessSignup ?
                    <St.Wrapper>
                        <St.LogoWrapper>
                            <Link to='/'>
                                <CustomRatioImage width={1} height={1}>
                                    <CustomRatioImage.Image src={'/assets/images/shooluk_logo.png'} />
                                </CustomRatioImage>
                            </Link>
                        </St.LogoWrapper>
                        <St.IntroduceWrapper>
                            <div className='mainDescription flex'>
                                <section>
                                    회원가입 성공
                                </section>
                                <section className='icon'>
                                    <CustomRatioImage>
                                        <CustomRatioImage.Image src='/assets/icons/firecracker.svg' />
                                    </CustomRatioImage>
                                </section>
                            </div>
                            <div className='mainDescription'>입력하신 이메일로 인증 메일을 발송했습니다.</div>
                            <div className='mainDescription'>메일 인증을 완료 후 로그인해 주시기 바랍니다.</div>
                        </St.IntroduceWrapper>
                        <St.FooterFieldWrapper>
                            <div>
                                <Link
                                    to='/login'
                                >
                                    <CustomBlockButton
                                        type='button'
                                        className='button'
                                    >
                                        로그인
                                    </CustomBlockButton>
                                </Link>
                            </div>
                        </St.FooterFieldWrapper>
                    </St.Wrapper>
                    :
                    <form onSubmit={(e) => { e.preventDefault(); handleSignup(e); }}>
                        <St.Wrapper>
                            <St.LogoWrapper>
                                <Link to='/'>
                                    <CustomRatioImage width={1} height={1}>
                                        <CustomRatioImage.Image src={'/assets/images/shooluk_logo.png'} />
                                    </CustomRatioImage>
                                </Link>
                            </St.LogoWrapper>
                            <St.IntroduceWrapper>
                                <div className='mainDescription'>슈룩 회원가입</div>
                                <div className='subDescription'>여러가지 링크를 하나의 링크로 관리해 보세요.</div>
                            </St.IntroduceWrapper>
                            <St.InputGroupWrapper>
                                <CustomInput
                                    type='email'
                                    value={email || ''}
                                    onChange={(e) => handleChangeEmailFromEvent(e)}
                                    placeholder='이메일'
                                />
                                <CustomInput
                                    type='password'
                                    value={password || ''}
                                    onChange={(e) => handleChangePasswordFromEvent(e)}
                                    placeholder='비밀번호'
                                />
                                <CustomInput
                                    type='password'
                                    value={passwordChecker || ''}
                                    onChange={(e) => handleChangePasswordCheckerFromEvent(e)}
                                    placeholder='비밀번호 확인'
                                />
                            </St.InputGroupWrapper>
                            <St.LoginButtonGroup>
                                <CustomBlockButton
                                    type='submit'
                                    className='login'
                                >
                                    이메일 회원가입
                                </CustomBlockButton>
                            </St.LoginButtonGroup>
                            <St.FooterFieldWrapper>
                                <div className='text'>기존 아이디가 있으신가요?</div>
                                <div>
                                    <Link
                                        to='/login'
                                    >
                                        <CustomBlockButton
                                            type='button'
                                            className='button'
                                        >
                                            로그인
                                        </CustomBlockButton>
                                    </Link>
                                </div>
                            </St.FooterFieldWrapper>
                        </St.Wrapper>
                    </form>
                }
            </St.Container>
        </>
    );
}