import { useLocation } from "react-router-dom";
import { useUserPageContextValueHook } from "../../../contexts/UserPageContextProvider";
import { useUserContextValueHook } from "../../../contexts/UserContextProvider";
import { MainView } from "../../../views/[userPageId]/mypage";

export default function Component(props) {
    const location = useLocation();
    const pathUserPageId = location?.pathname?.split('/')?.filter(r => r !== '')[0];

    const userContextValueHook = useUserContextValueHook();
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPage = userPageContextValueHook?.userPage;

    if (userPageContextValueHook?.isLoading || userContextValueHook?.isLoading) {
        return 'Loading...';
    }

    if ((!pathUserPageId || !userPage?.id) || pathUserPageId !== userPage?.id || !userContextValueHook?.user) {
        return 'Not Found';
    }


    return <MainView />;
}