import styled from 'styled-components';

export const Container = styled.div`
    margin-top: 20px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    box-sizing: border-box;
`;

export const ModuleItems = {
    Container: styled.div`
        display: flex;
        flex-direction: column;
        gap: 20px;
    `,
    LinkItem: styled.div`
        .linkItemWrapper{
            box-shadow: var(--defaultBoxShadow);
            padding: 10px 10px;
            min-height: 80px;
            border-radius: 15px;
            display: flex;
            align-items: center;
            gap: 10px;
            background-color: #fff;
            box-sizing: border-box;
            width: 100%;
            overflow: hidden;
            justify-content: space-between;
            transition: all .3s;
            cursor: pointer;

            &:hover{
                scale: 1.02;
                box-shadow: var(--defaultBoxShadow3);
            }

            @media all and (max-width:576px){
                min-height: 72px;
                padding: 10px;
            }

            section.first{
                width: 60px;

                @media all and (max-width:576px){
                    width: 50px;
                }

                .thumbnailBox{
                    width: 100%;
                    border-radius: 15px;
                    overflow: hidden;
                }
            }

            section.second{
                flex:1;
                display: flex;
                flex-direction: column;
                width: 100%;
                text-align:left;
                gap: 3px;
                .title{
                    font-size: 16px;
                    font-weight: 700;

                    max-width: 100%;
                    
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;

                    @media all and (max-width:576px){
                        font-size: 14px;
                    }
                }

                .description{
                    font-size: 11px;
                    font-weight: 500;
                    color: #404040;
                    max-width: 100%;
                    
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;

                    @media all and (max-width:576px){
                        font-size: 11px;
                    }
                }
            }
            section.third{
                width: 30px;
                display: flex;
                justify-content: center;
                
                @media all and (max-width:576px){
                    width: 50px;
                }
                
                .iconBox{
                    width: 16px;

                    @media all and (max-width:576px){
                        width: 16px;
                    }
                }
            }
        }
    `,
    TextItem: styled.div`
        text-align: center;
        font-size: 14px;
        white-space: pre-line;
        font-weight: 600;
    `,
    SeparatorItem: styled.div`
    
    `,
    Footer: styled.div`
        .moreLoadingBox{
            display: flex;
            justify-content: center;
            height: 50px;

            .progress{
                color: #b0b0b0;
            }
        }

        .moreButton{
            width: 50px;
            height: 50px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
            border: 1px solid #f0f0f0;

            .icon{
                width: 60%;
            }
        }

        .lastContent{
            font-size: 14px;
            color: #606060;
            font-weight: 600;
            background-color: #fff;
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
            padding: 10px 20px;
            border-radius: 10px;
            border: 1px solid #f0f0f0;
        }
    `,
}