import React, { useState } from 'react';
import styled from 'styled-components';

const BoxEl = styled.div`
    position: relative;
    border: 1.5px solid ${props => props.$isFocus ? '#000000' : '#00000000'};
    border-radius: 15px;
    overflow: hidden;
    background: var(--defaultElementBC);
    box-sizing: border-box;
`;

const LabelEl = styled.label`
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 14px;
    color: #606060;
    font-weight: 500;
    transition: top 0.3s, left 0.3s, font-size 0.3s;
    pointer-events: none;
`;

const TextareaEl = styled.textarea`
    width: 100%;
    resize: vertical;
    display: block;
    box-sizing: border-box;
    border: 1px solid #00000000;
    border-top: 25px solid #00000000;
    padding: 0 20px;
    outline: none;
    min-height: 70px;
    background: none;
    font-weight: 700;
    font-size: 14px;

    &:focus ~ ${LabelEl},
    &:not(:placeholder-shown) ~ ${LabelEl} {
        font-size: 11px; /* 라벨 크기를 조정할 수 있습니다. */
        top: 5px;
    }
`;

const Textarea = React.forwardRef(function Textarea({
    boxClassName = '',
    className,
    placeholder,
    labelElement,
    ...props
}, ref) {
    const [isFocus, setIsFocus] = useState(false);

    return (
        <BoxEl
            className={boxClassName}
            $isFocus={isFocus}
        >
            <TextareaEl
                ref={ref}
                placeholder={placeholder || ''}
                className={className}
                onFocus={() => setIsFocus(true)}
                onBlur={() => setIsFocus(false)}
                {...props}
            ></TextareaEl>
            <LabelEl>
                {labelElement || 'Textarea'}
            </LabelEl>
        </BoxEl>
    );
})

export const CustomLabelTextarea = Object.assign(Textarea, {});