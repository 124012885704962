import { CustomRatioImage } from '../../../../../components/image/CustomRatioImage';
import { FdAlert } from '../FdAlert/FdAlert';
import * as St from './FdHeaderBanner.styled';

export function FdHeaderBanner({
    userPage
}) {
    const noticeConfig = userPage?.content?.noticeConfig;

    return (
        <>
            {(noticeConfig && noticeConfig?.viewFlag) &&
                <FdAlert
                    noticeConfig={noticeConfig}
                />
            }
            <St.Container>
                <St.BannerWrapper>
                    <img src={`${userPage?.content?.topBannerImageUrl ? userPage?.content?.topBannerImageUrl : '/assets/images/profileBannerImage_default.jpg'}`} alt={'topBannerImage'} />
                </St.BannerWrapper>
                <div
                    style={{
                        padding: '0 10px',
                    }}
                >
                    <St.HeaderWrapper
                        $backgroundImageUrl={userPage?.content?.backgroundImageUrl}
                        style={{
                            background: userPage?.content?.profileBackgroundColor ? userPage?.content?.profileBackgroundColor : '#ffffff'
                        }}
                    >
                        <div className='profileImageWrapper'>
                            <CustomRatioImage width={1} height={1}>
                                <CustomRatioImage.Image src={userPage?.content?.profileImageUrl ? userPage?.content?.profileImageUrl : '/assets/images/space.png'} />
                            </CustomRatioImage>
                        </div>
                        <div
                            className='domainNameWrapper'
                            style={{
                                color: userPage?.content?.domainNameColor ? userPage?.content?.domainNameColor : '#000000'
                            }}
                        >
                            @{userPage?.content?.domainName}
                        </div>
                        {userPage?.content?.description &&
                            <div
                                className='descriptionWrapper'
                                style={{
                                    color: userPage?.content?.descriptionColor ? userPage?.content?.descriptionColor : '#606060'
                                }}
                            >
                                {userPage?.content?.description}
                            </div>
                        }
                    </St.HeaderWrapper>
                </div>
            </St.Container>
        </>
    );
}