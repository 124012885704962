import moment from "moment";

export function CustomDateTimeUtils(props) {
    return {
        conventTimestampToLocalDateTime,
        convertDateTimeToYYYYMMDD,
        convertDateTimeToYYYYMMDDhhmmss,
        convertTimestampToYYYYMMDD,
        getStartDate,
        getEndDate,
        getLocalUtcOffset
    }
}

function conventTimestampToLocalDateTime(timestamp) {
    return new Date(Number(timestamp));
}

function convertDateTimeToYYYYMMDD(idate, dateSeperator) {
    var date = new Date(idate);
    let dsep = dateSeperator ? dateSeperator : '-';

    return moment(date).format(`YYYY${dsep}MM${dsep}DD`);
}

function convertDateTimeToYYYYMMDDhhmmss(idate, dateSeperator, timeSeperator) {
    var date = new Date(idate);
    let dsep = dateSeperator ? dateSeperator : '-';
    let tsep = timeSeperator ? timeSeperator : ':';

    return moment(date).format(`YYYY${dsep}MM${dsep}DD HH${tsep}mm${tsep}ss`);
}

function convertTimestampToYYYYMMDD(iTimestamp, dateSeperator) {
    const dateTime = conventTimestampToLocalDateTime(iTimestamp);
    return convertDateTimeToYYYYMMDD(dateTime, dateSeperator);
}

function getStartDate(date) {
    // New version
    let cdate = new Date(date);
    cdate.setHours(0);
    cdate.setMinutes(0);
    cdate.setSeconds(0);
    cdate.setMilliseconds(0);
    return cdate;
}

// For API Fetch
function getEndDate(date) {
    let cdate = new Date(date);
    cdate.setHours(23);
    cdate.setMinutes(59);
    cdate.setSeconds(59);
    cdate.setMilliseconds(0);
    return cdate
}

function getLocalUtcOffset() {
    const now = new Date();
    const offsetMinutes = now.getTimezoneOffset(); // 분 단위로 반환
    const offsetHours = offsetMinutes / 60; // 시간 단위로 변환
    const sign = offsetHours > 0 ? '-' : '+';
    const absoluteOffsetHours = Math.abs(offsetHours);
    const hours = Math.floor(absoluteOffsetHours);
    const minutes = (absoluteOffsetHours - hours) * 60;

    return {
        hoursOffset: sign === '-' ? -1 * hours : hours,
        minutesOffset: sign === '-' ? -1 * minutes : minutes,
        hours: sign + String(hours).padStart(2, '0'),
        minutes: String(minutes).padStart(2, '0')
    };
}