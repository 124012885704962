import axios from "axios"

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function NoticeItemsApi(props) {
    return {
        searchList: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/notice-items`, {
                params: {
                    noticePath: params?.noticePath,
                },
                headers: headers
            })
        },
    }
}