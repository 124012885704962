import React from 'react';
import styled from 'styled-components';

const InputBox = styled.div`
    position: relative;
`;

const LabelEl = styled.label`
    position: absolute;
    top: 15px;
    left: 20px;
    transition: top 0.3s, left 0.3s, font-size 0.3s;
    pointer-events: none;
    font-size: 14px;
    color: #606060;
    font-weight: 500;
`;

const InputEl = styled.input`
    width:100%;
    height: 48px;
    border-width: 1px;
    border-style: solid;
    border-color: #aaa;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 14px;

    border-radius: 15px;
    height: 50px;
    border: 1.5px solid #00000000;
    background: var(--defaultElementBC);
    padding: 0 20px;
    font-weight: 700;
    outline: none;
    &:focus{
        border-color: #000000;
        padding-top: 15px;
    }

    &:not(:placeholder-shown){
        padding-top: 15px;
    }

    &:focus ~ label,
    &:not(:placeholder-shown) ~ ${LabelEl} {
        top: 5px;
        left: 20px;
        font-size: 11px; /* 라벨 크기를 조정할 수 있습니다. */
    }
`;

function Box({
    children,
    ...props
}) {
    return (
        <InputBox {...props}>
            {children}
        </InputBox>
    );
}

const Input = React.forwardRef(function Input({
    type,
    className,
    placeholder,
    ...props
}, ref) {
    return (
        <InputEl
            ref={ref}
            type={type || 'text'}
            placeholder={placeholder || ''}
            className={className}
            {...props}
        ></InputEl>
    );
})

function Label({
    children,
    ...props
}) {
    return (
        <LabelEl {...props}>{children}</LabelEl>
    );
}

/*
    ex)
    <CustomLabelInput className='inputBox'>
        <CustomLabelInput.Input
            type='text'
        />
        <CustomLabelInput.Label>{label}</CustomLabelInput.Label>
    </CustomLabelInput>
 */
export const CustomLabelInput = Object.assign(Box, {
    Input: Input,
    Label: Label
})