import CustomBlockButton from '../../../../../components/button/CustomBlockButton';
import { customToast } from '../../../../../components/toast/custom-react-toastify/v1';
import { useUserPageContextValueHook } from '../../../../../contexts/UserPageContextProvider';
import * as St from './FdLinkAlert.styled';

export function FdLinkAlert(props) {
    const origin = window.location.origin;
    const userPageContextValueHook = useUserPageContextValueHook();

    const handleCopyLink = async () => {
        try {
            await navigator.clipboard.writeText(`${origin}/${userPageContextValueHook?.userPage?.domainName}`)
            customToast.success('URL을 복사했습니다!');
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <div className='flexWrapper'>
                        <section className='first'>
                            링크가 <span style={{ color: 'var(--greenColor1)' }}>OnAir</span> 되었습니다. 링크를 공유해 보세요.
                        </section>
                        <section className='second'>
                            <CustomBlockButton
                                type='button'
                                onClick={() => handleCopyLink()}
                            >
                                URL 복사
                            </CustomBlockButton>
                        </section>
                    </div>
                </St.Wrapper>
            </St.Container>
        </>
    );
}