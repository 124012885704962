import { useRef, useState } from 'react';
import CustomBlockButton from '../../../../../../../../../components/button/CustomBlockButton';
import { CustomRatioImage } from '../../../../../../../../../components/image/CustomRatioImage';
import * as St from './WegetAddModule.styled';
import { CustomDialog } from '../../../../../../../../../components/dialog/v1/CustomDialog';
import { CustomInput } from '../../../../../../../../../components/input/CustomInput';
import { useQueryParamsHook } from '../../../../../../../../../hooks/useQueryParamsHook';
import { useUserPageContextValueHook } from '../../../../../../../../../contexts/UserPageContextProvider';
import { useLinkCategoryContextValueHook } from '../../../../../../contexts/LinkCategoryContextProvider';
import { customToast } from '../../../../../../../../../components/toast/custom-react-toastify/v1';
import { CustomBackdropController } from '../../../../../../../../../components/backdrop/default/v1';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import firebaseStorage from '../../../../../../../../../firebase/storage';
import { CustomRegexUtils } from '../../../../../../../../../utils/CustomRegexUtils';
import CustomSelect from '../../../../../../../../../components/select/default/v1/CustomSelect';
import { v4 as uuidv4 } from 'uuid';
import { CustomLabelInput } from '../../../../../../../../../components/input/CustomLabelInput';
import { CustomLabelTextarea } from '../../../../../../../../../components/textarea/CustomLabelTextarea';
import { GlobalSeparatorVariables } from '../../../../../../../../../variables/GlobalSeparatorVariables';


export function WegetAddModule({
    onReqCreateLink
}) {
    const [addModalOpen, setAddModalOpen] = useState(false);

    const toggleAddModalOpen = (bool) => {
        setAddModalOpen(bool);
    }

    const handleSubmit = async (body) => {
        await onReqCreateLink(body, () => {
            toggleAddModalOpen(false);
        })
    }

    return (
        <>
            <St.Container>
                <CustomBlockButton
                    type='button'
                    onClick={() => toggleAddModalOpen(true)}
                >
                    <div className='content'>
                        <div className='content__icon'>
                            <CustomRatioImage width={1} height={1}>
                                <CustomRatioImage.Image src='/assets/icons/add_default_ffffff.svg' />
                            </CustomRatioImage>
                        </div>
                        모듈추가
                    </div>
                </CustomBlockButton>
            </St.Container>

            {addModalOpen &&
                <AddModal
                    open={addModalOpen}
                    toggleOpenClose={toggleAddModalOpen}
                    onConfirm={handleSubmit}
                />
            }
        </>
    );
}

// TODO : 모듈추가 분리해서 기능 구현하기 LINK, SEPARATOR, TEXT
function AddModal({
    open,
    toggleOpenClose,
    onConfirm
}) {
    const [viewMode, setViewMode] = useState('DEFAULT') // DEFAULT, LINK, SEPARATOR, TEXT

    const handleChangeViewMode = (viewMode) => {
        setViewMode(viewMode);
    }

    return (
        <>
            <CustomDialog
                open={open}
                onClose={() => toggleOpenClose(false)}
                maxWidth='sm'
            >
                {(!viewMode || viewMode === 'DEFAULT') &&
                    <DefaultMode
                        toggleOpenClose={toggleOpenClose}
                        onChangeViewMode={handleChangeViewMode}
                    />
                }
                {viewMode === 'LINK' &&
                    <AddLinkMode
                        toggleOpenClose={toggleOpenClose}
                        onChangeViewMode={handleChangeViewMode}
                        onConfirm={onConfirm}
                    />
                }
                {viewMode === 'TEXT' &&
                    <AddTextMode
                        toggleOpenClose={toggleOpenClose}
                        onChangeViewMode={handleChangeViewMode}
                        onConfirm={onConfirm}
                    />
                }
                {viewMode === 'SEPARATOR' &&
                    <AddSeparatorMode
                        toggleOpenClose={toggleOpenClose}
                        onChangeViewMode={handleChangeViewMode}
                        onConfirm={onConfirm}
                    />
                }
            </CustomDialog>
        </>
    );
}

function DefaultMode({
    toggleOpenClose,
    onChangeViewMode
}) {
    return (
        <>
            <St.AddModal.DefaultModeContainer>
                <CustomDialog.Header style={{ marginBottom: '20px' }}>
                    <CustomDialog.Header.Fake />
                    <CustomDialog.Header.Title>모듈추가</CustomDialog.Header.Title>
                    <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                </CustomDialog.Header>
                <div className='body'>
                    <div className='itemList'>
                        <div
                            className='itemBox'
                            onClick={() => onChangeViewMode('LINK')}
                        >
                            <div className='flex justifyContent_SpaceBetween gap_10'>
                                <div className='flex gap_10'>
                                    <div className='iconFigure'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src='/assets/icons/link_default_000000.svg' />
                                        </CustomRatioImage>
                                    </div>
                                    <div className='text'>링크</div>
                                </div>
                                <div>
                                    <div className='iconFigure small'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src='/assets/icons/arrowForward_default_000000.svg' />
                                        </CustomRatioImage>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className='itemBox'
                            onClick={() => onChangeViewMode('TEXT')}
                        >
                            <div className='flex justifyContent_SpaceBetween gap_10'>
                                <div className='flex gap_10'>
                                    <div className='iconFigure'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src='/assets/icons/text_default_000000.svg' />
                                        </CustomRatioImage>
                                    </div>
                                    <div className='text'>텍스트</div>
                                </div>
                                <div>
                                    <div className='iconFigure small'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src='/assets/icons/arrowForward_default_000000.svg' />
                                        </CustomRatioImage>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className='itemBox'
                            onClick={() => onChangeViewMode('SEPARATOR')}
                        >
                            <div className='flex justifyContent_SpaceBetween gap_10'>
                                <div className='flex gap_10'>
                                    <div className='iconFigure'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src='/assets/icons/separator_default_darkGray.svg' />
                                        </CustomRatioImage>
                                    </div>
                                    <div className='text'>구분선</div>
                                </div>
                                <div>
                                    <div className='iconFigure small'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src='/assets/icons/arrowForward_default_000000.svg' />
                                        </CustomRatioImage>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </St.AddModal.DefaultModeContainer>
        </>
    );
}

function AddLinkMode({
    toggleOpenClose,
    onChangeViewMode,
    onConfirm
}) {
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook.params?.lcId;

    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();

    const imageUploaderRef = useRef();

    const [createForm, setCreateForm] = useState({
        ...INIT_CREATE_FORM,
        linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r.id === lcId)?.id || '',
        userPageId: userPageContextValueHook?.userPage?.id,
    })

    const [thumbnailDropdownOpen, setThumbnailDropdownOpen] = useState(false);

    const toggleThumbnailDropdownOpen = (bool) => {
        setThumbnailDropdownOpen(bool);
    }


    const handleChangeTitle = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            title: value
        }))
    }

    const handleChangeLinkUrl = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            linkUrl: value
        }))
    }

    const handleChangeDescription = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            description: value
        }))
    }

    const handleChangeLinkCategoryId = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            linkCategoryId: value || ''
        }))
    }

    const handleImageUploaderOpen = () => {
        imageUploaderRef.current.click();
    }

    const handleSelectThumbnail = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            customToast('올바른 이미지 파일을 선택해 주세요.');
            return;
        }

        let fileSize = file.size;
        let extension = file.name.split('.').pop();

        if (fileSize > 5 * 1024 * 1024) {
            customToast.error('이미지 크기는 최대 5MB까지만 허용됩니다.');
            return;
        }

        CustomBackdropController().showBackdrop();

        const fileRef = ref(firebaseStorage, `/assets/user_pages/${userPageContextValueHook?.userPage?.id}/link_thumbnail_images/${uuidv4()}.${extension}`);

        const uploadedUrl = await uploadBytes(fileRef, file).then(async snapshot => {
            return await getDownloadURL(snapshot.ref)
                .then(url => {
                    return url
                })
        });
        CustomBackdropController().hideBackdrop();

        if (uploadedUrl) {
            setCreateForm(prev => ({
                ...prev,
                thumbnailUrl: uploadedUrl
            }))
        };
    }

    const handleRemoveThumbnail = async () => {
        setCreateForm(prev => ({
            ...prev,
            thumbnailUrl: ''
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let newUrl = createForm?.linkUrl;

        if (createForm?.title?.length < 1 || createForm?.title?.length > 50) {
            customToast.error('링크 주제는 1-50 자 내외 필수 입력 입니다.');
            return;
        }

        if (!CustomRegexUtils().hasHttpPrefix(newUrl)) {
            newUrl = `http://${newUrl}`;
        }

        if (!CustomRegexUtils().isValidUrlFormat(newUrl)) {
            customToast.error('올바른 URL을 입력해 주세요. \nex) https://www.example.com');
            return;
        }

        if (createForm?.description?.length > 100) {
            customToast.error('설명은 100자 이내로 입력 가능합니다.');
            return;
        }

        let body = {
            ...createForm,
            title: createForm?.title?.trim(),
            linkUrl: newUrl,
            description: createForm?.description.trim(),
            thumbnailUrl: createForm?.thumbnailUrl,
            linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r?.id === createForm?.linkCategoryId)?.id || '',
            userPageId: userPageContextValueHook?.userPage?.id
        }
        onConfirm(body);
    }

    return (
        <>
            <St.AddModal.AddLinkModeContainer>
                <CustomDialog.Header style={{ marginBottom: '20px' }}>
                    <CustomDialog.Header.Back onClick={() => onChangeViewMode('DEFAULT')} />
                    <CustomDialog.Header.Title>링크 모듈</CustomDialog.Header.Title>
                    <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                </CustomDialog.Header>
                <div className='wrapper'>
                    <CustomLabelInput>
                        <CustomLabelInput.Input
                            type='text'
                            value={createForm?.title}
                            onChange={(e) => handleChangeTitle(e)}
                        />
                        <CustomLabelInput.Label><span style={{ color: '#ef476f' }}>*</span> 링크 주제</CustomLabelInput.Label>
                    </CustomLabelInput>
                    <CustomLabelInput>
                        <CustomLabelInput.Input
                            type='text'
                            value={createForm?.linkUrl}
                            onChange={(e) => handleChangeLinkUrl(e)}
                        />
                        <CustomLabelInput.Label><span style={{ color: '#ef476f' }}>*</span> 링크 URL</CustomLabelInput.Label>
                    </CustomLabelInput>
                    <CustomLabelInput>
                        <CustomLabelInput.Input
                            type='text'
                            value={createForm?.description}
                            onChange={(e) => handleChangeDescription(e)}
                        />
                        <CustomLabelInput.Label>설명</CustomLabelInput.Label>
                    </CustomLabelInput>
                    <div className={`wrapper__thumbnailWrapper ${thumbnailDropdownOpen ? 'open' : ''}`}>
                        <div className={`wrapper__thumbnailWrapper__header ${thumbnailDropdownOpen ? 'open' : ''}`} onClick={() => toggleThumbnailDropdownOpen(!thumbnailDropdownOpen)}>
                            <div className='wrapper__thumbnailWrapper__header__title'>썸네일</div>
                            <div className='wrapper__thumbnailWrapper__header__icon'>
                                {thumbnailDropdownOpen ?
                                    (
                                        <CustomRatioImage width={1} height={1}>
                                            <CustomRatioImage.Image src='/assets/icons/expandLess_000000.svg' />
                                        </CustomRatioImage>
                                    )
                                    :
                                    (
                                        <CustomRatioImage width={1} height={1}>
                                            <CustomRatioImage.Image src='/assets/icons/expandMore_000000.svg' />
                                        </CustomRatioImage>
                                    )}
                            </div>
                        </div>
                        {thumbnailDropdownOpen &&
                            <div className='wrapper__thumbnailWrapper__contentLayout'>
                                <div className='wrapper__thumbnailWrapper__contentLayout__thumbnailBox'>
                                    {createForm?.thumbnailUrl ?
                                        (
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src={createForm?.thumbnailUrl} />
                                            </CustomRatioImage>
                                        )
                                        :
                                        (
                                            <div className='wrapper__thumbnailWrapper__contentLayout__thumbnailBox__blankText'>
                                                권장 이미지 사이즈<br />256 x 256
                                            </div>
                                        )
                                    }
                                </div>
                                <div className='wrapper__thumbnailWrapper__contentLayout__buttonGroup'>
                                    <CustomBlockButton
                                        type='button'
                                        className='select'
                                        onClick={() => handleImageUploaderOpen()}
                                    >
                                        썸네일 선택
                                    </CustomBlockButton>
                                    <CustomBlockButton
                                        type='button'
                                        className='remove'
                                        onClick={() => handleRemoveThumbnail()}
                                    >
                                        썸네일 제거
                                    </CustomBlockButton>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='wrapper__selectBox'>
                        <CustomSelect
                            value={createForm?.linkCategoryId}
                            onChange={(e) => handleChangeLinkCategoryId(e)}
                        >
                            <option value=''>전체</option>
                            {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                return (
                                    <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                );
                            })}
                        </CustomSelect>
                        <label>카테고리{createForm?.linkCategoryId && <span style={{ color: 'var(--redColor1)', paddingLeft: '10px' }}>* 카테고리가 선택 되었어요!</span>}</label>
                    </div>
                    <div className='wrapper__footerButtonGroup'>
                        <CustomBlockButton
                            type='button'
                            onClick={(e) => handleSubmit(e)}
                        >
                            추가
                        </CustomBlockButton>
                    </div>
                </div>
            </St.AddModal.AddLinkModeContainer>
            <input
                ref={imageUploaderRef}
                type="file"
                accept="image/*"
                onClick={(e) => e.target.value = ''}
                onChange={(e) => handleSelectThumbnail(e)}
                hidden
            />
        </>
    );
}

function AddTextMode({
    toggleOpenClose,
    onChangeViewMode,
    onConfirm,
}) {
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook.params?.lcId;

    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();

    const [text, setText] = useState('');
    const [textColorHex, setTextColorHex] = useState('#000000');
    const [linkCategoryId, setLinkCategoryId] = useState(linkCategoryContextValueHook?.content?.find(r => r.id === lcId)?.id || '');

    const handleChangeText = (e) => {
        const value = e.target.value;
        setText(value);
    }

    const handleChangeLinkCategoryId = (e) => {
        const value = e.target.value;

        setLinkCategoryId(value || '');
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (text?.length < 1 || text?.length > 500) {
            customToast.error('텍스트는 1-500자 내외로 입력해 주세요.');
            return;
        }

        let body = {
            ...INIT_CREATE_FORM,
            type: 'TEXT',
            linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r?.id === linkCategoryId)?.id || '',
            userPageId: userPageContextValueHook?.userPage?.id,
            textConfig: {
                text: text,
                textColorHex: textColorHex
            }
        }
        onConfirm(body);
    }

    return (
        <>
            <St.AddModal.AddTextModeContainer>
                <CustomDialog.Header style={{ marginBottom: '20px' }}>
                    <CustomDialog.Header.Back onClick={() => onChangeViewMode('DEFAULT')} />
                    <CustomDialog.Header.Title>텍스트 모듈</CustomDialog.Header.Title>
                    <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                </CustomDialog.Header>
                <div className='wrapper'>
                    <div className='textareaBox'>
                        <CustomLabelTextarea
                            labelElement={<><span style={{ color: 'var(--redColor1)' }}>*</span> 텍스트</>}
                            value={text || ''}
                            onChange={(e) => handleChangeText(e)}
                            maxLength={500}
                        />
                        <div className='counter'>{text?.length} / 500</div>
                    </div>
                    <div className='wrapper__selectBox'>
                        <CustomSelect
                            value={linkCategoryId}
                            onChange={(e) => handleChangeLinkCategoryId(e)}
                        >
                            <option value=''>전체</option>
                            {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                return (
                                    <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                );
                            })}
                        </CustomSelect>
                        <label>카테고리{linkCategoryId && <span style={{ color: 'var(--redColor1)', paddingLeft: '10px' }}>* 카테고리가 선택 되었어요!</span>}</label>
                    </div>
                    <div className='wrapper__footerButtonGroup'>
                        <CustomBlockButton
                            type='button'
                            onClick={(e) => handleSubmit(e)}
                        >
                            추가
                        </CustomBlockButton>
                    </div>
                </div>
            </St.AddModal.AddTextModeContainer>
        </>
    )
}

function AddSeparatorMode({
    toggleOpenClose,
    onChangeViewMode,
    onConfirm,
}) {
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook.params?.lcId;

    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();

    const [linkCategoryId, setLinkCategoryId] = useState(linkCategoryContextValueHook?.content?.find(r => r.id === lcId)?.id || '');

    const [separatorViewMode, setSeparatorViewMode] = useState('DEFAULT'); // DEFAULT, EDIT
    const [separatorType, setSeparatorType] = useState('SPACE');

    const CurrentSeparator = GlobalSeparatorVariables.list.find(r => r.separator === separatorType);

    const handleChangeSeparatorViewMode = (mode) => {
        setSeparatorViewMode(mode);
    }

    const handleChangeLinkCategoryId = (e) => {
        const value = e.target.value;

        setLinkCategoryId(value || '');
    }

    const handleChangeSeparatorType = (type) => {
        setSeparatorType(type);
        handleChangeSeparatorViewMode('DEFAULT');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let body = {
            ...INIT_CREATE_FORM,
            type: 'SEPARATOR',
            linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r?.id === linkCategoryId)?.id || '',
            userPageId: userPageContextValueHook?.userPage?.id,
            separatorConfig: {
                separator: separatorType,
                iconColorHex: '#808080'
            }
        }
        onConfirm(body);
    }

    return (
        <>
            {(!separatorViewMode || separatorViewMode === 'DEFAULT') &&
                <St.AddModal.AddSeparatorModeContainer>
                    <CustomDialog.Header style={{ marginBottom: '20px' }}>
                        <CustomDialog.Header.Back onClick={() => onChangeViewMode('DEFAULT')} />
                        <CustomDialog.Header.Title>구분선 모듈</CustomDialog.Header.Title>
                        <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                    </CustomDialog.Header>
                    <div className='wrapper default'>
                        <div className='itemWrapper'>
                            <label><span style={{ color: '#ef476f' }}>*</span> 구분선</label>
                            <div className='itemBox' onClick={() => handleChangeSeparatorViewMode('EDIT')}>
                                <section className='content'>
                                    <div className='name'>{CurrentSeparator.name}</div>
                                    <CurrentSeparator.Component />
                                </section>
                                <section className='go'>
                                    <figure className='icon'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src={'/assets/icons/arrowForward_default_000000.svg'} alt={'arrow forward'} />
                                        </CustomRatioImage>
                                    </figure>
                                </section>
                            </div>
                        </div>
                        <div className='wrapper__selectBox'>
                            <CustomSelect
                                value={linkCategoryId}
                                onChange={(e) => handleChangeLinkCategoryId(e)}
                            >
                                <option value=''>전체</option>
                                {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                    return (
                                        <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                    );
                                })}
                            </CustomSelect>
                            <label>카테고리{linkCategoryId && <span style={{ color: 'var(--redColor1)', paddingLeft: '10px' }}>* 카테고리가 선택 되었어요!</span>}</label>
                        </div>
                        <div className='wrapper__footerButtonGroup'>
                            <CustomBlockButton
                                type='button'
                                onClick={(e) => handleSubmit(e)}
                            >
                                추가
                            </CustomBlockButton>
                        </div>
                    </div>
                </St.AddModal.AddSeparatorModeContainer>
            }

            {(separatorViewMode === 'EDIT') &&
                <St.AddModal.AddSeparatorModeContainer>
                    <CustomDialog.Header style={{ marginBottom: '20px' }}>
                        <CustomDialog.Header.Back onClick={() => handleChangeSeparatorViewMode('DEFAULT')} />
                        <CustomDialog.Header.Title>구분선 선택</CustomDialog.Header.Title>
                        <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                    </CustomDialog.Header>
                    <div className='wrapper edit'>
                        <div className='body'>
                            <div className='itemList'>
                                {GlobalSeparatorVariables.list.map(Separator => {
                                    return (
                                        <div
                                            key={Separator.separator}
                                            className='itemBox'
                                            onClick={() => handleChangeSeparatorType(Separator.separator)}
                                        >
                                            <section className='content'>
                                                <div className='name'>{Separator.name}</div>
                                                <Separator.Component />
                                            </section>
                                            <section className='go'>
                                                <figure className='icon'>
                                                    <CustomRatioImage>
                                                        <CustomRatioImage.Image src={'/assets/icons/arrowForward_default_000000.svg'} alt={'arrow forward'} />
                                                    </CustomRatioImage>
                                                </figure>
                                            </section>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </St.AddModal.AddSeparatorModeContainer>
            }
        </>
    );
}

const INIT_CREATE_FORM = {
    type: 'LINK', // LINK, TEXT, SEPARATOR
    userPageId: null,
    title: '',
    linkUrl: '',
    description: '',
    thumbnailUrl: '',
    linkCategoryId: '',
    separatorConfig: {
        separator: 'SPACE', // SPACE, DOTTED, LINE, ZIGZAG
        iconColorHex: '#000000'
    },
    textConfig: {
        text: '',
        textColorHex: '#000000'
    }
}