import { Link } from 'react-router-dom';
import { CustomRatioImage } from '../../../../../components/image/CustomRatioImage';
import * as St from './FdLinkItems.styled';
import { GlobalCookieVariables } from '../../../../../variables/GlobalCookieVariables';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import { ClickLogsApi } from '../../../../../apis/ClickLogsApi';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import CustomBlockButton from '../../../../../components/button/CustomBlockButton';
import { GlobalSeparatorVariables } from '../../../../../variables/GlobalSeparatorVariables';

const clickLogsApi = ClickLogsApi();

const getCookieExpiresMS = () => {
    const now = new Date();
    const midnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1, // 다음 날 0시
        0, 0, 0
    );

    return (midnight - now) / 1000;
}

export function FdLinkItems({
    userPage,
    linkItemList,
    hasMore,
    onReqFetchLinkItemList
}) {
    const [moreDisabled, setMoreDisabled] = useState(true);

    const handleClickItem = async (linkItem) => {
        const shoolukClickCookie = Cookies.get(GlobalCookieVariables.SHOOLUK_CLICK);

        let body = {
            clickId: uuidv4(),
            linkItemId: linkItem?.id,
            firstClickFlag: true,
            referrer: document.referrer || 'direct',
            userPageId: userPage?.id,
        }

        try {
            if (!shoolukClickCookie) {
                // 첫 클릭 쿠키 생성
                const newClickId = uuidv4();

                body.clickId = newClickId;
                body.firstClickFlag = true;

                Cookies.set(GlobalCookieVariables.SHOOLUK_CLICK, JSON.stringify({
                    clickId: newClickId,
                    clickLinkItems: [linkItem?.id]
                }), { expires: getCookieExpiresMS() / 86400 });
            } else {
                let shoolukClickCookieJson = JSON.parse(shoolukClickCookie);
                let isFirstClick = !shoolukClickCookieJson?.clickLinkItems?.includes(linkItem?.id);

                // 첫 클릭 기존 쿠키 사용
                if (isFirstClick) {
                    body.clickId = shoolukClickCookieJson?.clickId;
                    body.firstClickFlag = true;

                    shoolukClickCookieJson.clickLinkItems?.push(linkItem?.id);
                    Cookies.set(GlobalCookieVariables.SHOOLUK_CLICK, JSON.stringify(shoolukClickCookieJson), { expires: getCookieExpiresMS() / 86400 });
                } else {
                    body.clickId = shoolukClickCookieJson?.clickId;
                    body.firstClickFlag = false;
                }
            }
        } catch (err) {
            console.log(err);
            Cookies.remove(GlobalCookieVariables.SHOOLUK_CLICK);
        }

        await clickLogsApi.log({ body: body, headers: null })
    }

    const handleMoreItems = async () => {
        setMoreDisabled(true);
        await onReqFetchLinkItemList();
    }

    useEffect(() => {
        setMoreDisabled(false);
    }, [linkItemList]);

    return (
        <>
            <St.Container>
                <St.ModuleItems.Container>
                    {linkItemList?.map(module => {
                        const moduleType = module?.type || 'LINK';

                        if (moduleType === 'LINK') {
                            return (
                                <St.ModuleItems.LinkItem key={module?.id}>
                                    <Link
                                        to={module?.linkUrl}
                                        target='_blank'
                                        onClick={() => handleClickItem(module)}
                                    >
                                        <div className='linkItemWrapper'>
                                            {module?.thumbnailUrl &&
                                                <section className='first'>
                                                    <div className='thumbnailBox'>
                                                        <CustomRatioImage>
                                                            <CustomRatioImage.Image src={module?.thumbnailUrl} />
                                                        </CustomRatioImage>
                                                    </div>
                                                </section>
                                            }
                                            <section className='second' style={!module?.thumbnailUrl ? { textAlign: 'center' } : {}}>
                                                <div className='title'>
                                                    {module?.title}
                                                </div>
                                                {module?.description &&
                                                    <div className='description'>
                                                        {module?.description}
                                                    </div>
                                                }
                                            </section>
                                            {/* <section className='third'>
                                                <div className='iconBox'>
                                                    <CustomRatioImage>
                                                        <CustomRatioImage.Image src='/assets/images/externalLink.png' />
                                                    </CustomRatioImage>
                                                </div>
                                            </section> */}
                                        </div>
                                    </Link>
                                </St.ModuleItems.LinkItem>
                            );
                        }

                        if (moduleType === 'TEXT') {
                            return (
                                <St.ModuleItems.TextItem key={module?.id}>
                                    {module.textConfig?.text}
                                </St.ModuleItems.TextItem>
                            );
                        }

                        if (moduleType === 'SEPARATOR') {
                            const separatorConfig = module?.separatorConfig;
                            const Separator = GlobalSeparatorVariables.list.find(r => r.separator === separatorConfig?.separator);
                            return (
                                <St.ModuleItems.SeparatorItem key={module?.id}>
                                    <Separator.Component />
                                </St.ModuleItems.SeparatorItem>
                            );
                        }
                    })}
                    <St.ModuleItems.Footer>
                        {hasMore ?
                            <>
                                {moreDisabled ?
                                    <div className='moreLoadingBox'>
                                        <CircularProgress className='progress' size={30} />
                                    </div>
                                    :
                                    <CustomBlockButton
                                        type='button'
                                        className='moreButton'
                                        onClick={() => handleMoreItems()}
                                        disabled={moreDisabled}
                                    >
                                        <div className='icon'>
                                            <CustomRatioImage>
                                                <CustomRatioImage.Image src='/assets/icons/add_default_000000.svg' />
                                            </CustomRatioImage>
                                        </div>
                                    </CustomBlockButton>
                                }
                            </>
                            :
                            <div className='lastContent'>
                                마지막 콘텐츠 입니다.
                            </div>
                        }
                    </St.ModuleItems.Footer>
                </St.ModuleItems.Container>
            </St.Container>
        </>
    );
}