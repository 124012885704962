import { useEffect, useState } from 'react';
import CustomBlockButton from '../../../../../../../components/button/CustomBlockButton';
import * as St from './SubFdDesignEditor.styled';
import { CustomDialog } from '../../../../../../../components/dialog/v1/CustomDialog';
import { useApiHook } from '../../../../hooks/useApiHook';
import { useUserPageContextActionsHook, useUserPageContextValueHook } from '../../../../../../../contexts/UserPageContextProvider';
import { CustomInput } from '../../../../../../../components/input/CustomInput';
import { Switch } from '@mui/material';
import { customToast } from '../../../../../../../components/toast/custom-react-toastify/v1';
import { CustomBackdropController } from '../../../../../../../components/backdrop/default/v1';
import { useQueryParamsHook } from '../../../../../../../hooks/useQueryParamsHook';
import { CustomRatioImage } from '../../../../../../../components/image/CustomRatioImage';
import { GlobalSocialVariables } from '../../../../../../../variables/GlobalSocialVariables';
import { CustomLabelInput } from '../../../../../../../components/input/CustomLabelInput';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListUtils } from '../../../../../../../utils/ListUtils';
import { v4 as uuidv4 } from 'uuid';

export function SubFdDesignEditor({
    onRefreshPreviewViewer
}) {
    const [noticeModalOpen, setNoticeModalOpen] = useState(false);
    const [searchConsoleModalOpen, setSearchConsoleModalOpen] = useState(false);
    const [socialSettingModalOpen, setSocialSettingModalOpen] = useState(false);

    const toggleNoticeModalOpen = (bool) => {
        setNoticeModalOpen(bool);
    }

    const toggleSearchConsoleModalOpen = (bool) => {
        setSearchConsoleModalOpen(bool);
    }

    const toggleSocialSettingModalOpen = (bool) => {
        setSocialSettingModalOpen(bool);
    }

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <CustomBlockButton
                        type='button'
                        onClick={() => toggleNoticeModalOpen(true)}
                    >
                        공지설정
                    </CustomBlockButton>
                    <CustomBlockButton
                        type='button'
                        onClick={() => toggleSearchConsoleModalOpen(true)}
                    >
                        검색설정
                    </CustomBlockButton>
                    <CustomBlockButton
                        type='button'
                        onClick={() => toggleSocialSettingModalOpen(true)}
                    >
                        소셜설정
                    </CustomBlockButton>
                </St.Wrapper>
            </St.Container>

            {noticeModalOpen &&
                <MdNotice
                    open={noticeModalOpen}
                    onClose={() => toggleNoticeModalOpen(false)}
                    onRefreshPreviewViewer={() => onRefreshPreviewViewer()}
                />
            }

            {searchConsoleModalOpen &&
                <MdSearchConsole
                    open={searchConsoleModalOpen}
                    toggleOpenClose={toggleSearchConsoleModalOpen}
                    onRefreshPreviewViewer={() => onRefreshPreviewViewer()}
                />
            }

            {socialSettingModalOpen &&
                <MdSocialSetting
                    open={socialSettingModalOpen}
                    toggleOpenClose={toggleSocialSettingModalOpen}
                    onRefreshPreviewViewer={() => onRefreshPreviewViewer()}
                />
            }
        </>
    );
}

function MdNotice({
    open = false,
    onClose = () => { },
    onRefreshPreviewViewer = () => { }
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const noticeConfig = userPageContextValueHook?.userPage?.noticeConfig ? userPageContextValueHook?.userPage?.noticeConfig : {
        viewFlag: false,
        text: '',
        backgroundColorHex: '#e5f6fd',
        textColorHex: '#014361',
        iconColorHex: '#ef476f'
    };

    const queryParamsHook = useQueryParamsHook();

    const [viewFlag, setViewFlag] = useState(noticeConfig?.viewFlag);
    const [text, setText] = useState(noticeConfig?.text);

    const handleChangeViewNoticeFlag = (e) => {
        let checked = e.target.checked;

        setViewFlag(checked);
    }

    const handleChangeNotice = (e) => {
        let value = e.target.value;

        setText(value);
    }

    const handleLinkToDesignSetting = () => {
        const newQueryParams = { ...queryParamsHook?.params };

        queryParamsHook.setSearchParams({ ...newQueryParams, tabType: 'DESIGN_SETTING' }, { replace: true });
    }

    const handleReqChangeNoticeInfo = async (e) => {
        e.preventDefault();

        if (text?.length > 100) {
            customToast.error('공지사항을 0-100자로 입력해 주세요.');
            return;
        }

        let body = {
            id: userPageContextValueHook?.userPage?.id,
            noticeConfig: {
                ...noticeConfig,
                viewFlag: viewFlag,
                text: text,
            }
        }

        CustomBackdropController().showBackdrop();
        const changeResult = await apiHook.reqChangeUserPage_noticeConfig({ body });

        if (changeResult?.content) {
            userPageContextActionsHook.onSetUserPage({ ...changeResult?.content });
            onClose();
            onRefreshPreviewViewer();
        }
        CustomBackdropController().hideBackdrop();
    }

    return (
        <>
            <CustomDialog
                open={open}
                onClose={() => onClose()}
            >
                <CustomDialog.Header style={{ padding: '20px 20px 0 20px' }}>
                    <CustomDialog.Header.IconButton onClick={() => handleLinkToDesignSetting()}>
                        <CustomRatioImage>
                            <CustomRatioImage.Image src={'/assets/icons/color_setting.svg'} alt={'close x'} />
                        </CustomRatioImage>
                    </CustomDialog.Header.IconButton>
                    <CustomDialog.Header.Title>공지설정</CustomDialog.Header.Title>
                    <CustomDialog.Header.Close onClick={() => onClose()} />
                </CustomDialog.Header>
                <St.NoticeModalContainer>
                    <form className='wrapper' onSubmit={(e) => handleReqChangeNoticeInfo(e)}>
                        <div className='wrapper__switchBox'>
                            <section className='first'>노출여부</section>
                            <section>
                                <Switch
                                    size='small'
                                    color='success'
                                    onChange={e => handleChangeViewNoticeFlag(e)}
                                    checked={viewFlag || false}
                                />
                            </section>
                        </div>
                        <div className='wrapper__inputBox'>
                            <CustomLabelInput>
                                <CustomLabelInput.Input
                                    type='text'
                                    value={text || ''}
                                    onChange={e => handleChangeNotice(e)}
                                    maxLength={100}
                                />
                                <CustomLabelInput.Label>공지사항</CustomLabelInput.Label>
                            </CustomLabelInput>
                            <div className='counter'>{text?.length} / 100</div>
                        </div>
                        <div className='wrapper__buttonGroup'>
                            <CustomBlockButton
                                type='button'
                                className='cancel'
                                onClick={() => onClose()}
                            >
                                취소
                            </CustomBlockButton>
                            <CustomBlockButton
                                type='submit'
                                className='confirm'
                            >
                                적용
                            </CustomBlockButton>
                        </div>
                    </form>
                </St.NoticeModalContainer>
            </CustomDialog>
        </>
    );
}

function MdSearchConsole({
    open,
    toggleOpenClose,
    onRefreshPreviewViewer,
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const defaultViewSearchFlag = userPageContextValueHook?.userPage?.searchConfig ? userPageContextValueHook?.userPage?.searchConfig?.viewFlag : true;
    const defaultPlaceholder = userPageContextValueHook?.userPage?.searchConfig ? userPageContextValueHook?.userPage?.searchConfig?.placeholder : '검색어를 입력해 주세요.';

    const [viewFlag, setViewFlag] = useState(defaultViewSearchFlag);
    const [placeholder, setPlaceholder] = useState(defaultPlaceholder);

    const handleChangeViewFlag = (e) => {
        const checked = e.target.checked;

        setViewFlag(checked);
    }

    const handleChangePlaceholder = (e) => {
        const value = e.target.value;

        setPlaceholder(value);
    }

    const handleReqChangeSearchConfig = async (e) => {
        e.preventDefault();

        const searchConfig = {
            viewFlag: viewFlag,
            placeholder: placeholder
        }

        let body = {
            id: userPageContextValueHook?.userPage?.id,
            searchConfig: searchConfig
        }

        CustomBackdropController().showBackdrop();
        const changeResult = await apiHook.reqChangeUserPage_searchConfig({ body: body })

        if (changeResult?.content) {
            userPageContextActionsHook.onSetUserPage({ ...changeResult?.content });
            toggleOpenClose(false);
            onRefreshPreviewViewer();
        }
        CustomBackdropController().hideBackdrop();
    }

    return (
        <>
            <CustomDialog
                open={open}
                onClose={() => toggleOpenClose(false)}
            >
                <CustomDialog.Header style={{ padding: '20px 20px 0 20px' }}>
                    <CustomDialog.Header.Fake />
                    <CustomDialog.Header.Title>검색설정</CustomDialog.Header.Title>
                    <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                </CustomDialog.Header>
                <St.SearchConsoleModalContainer>
                    <form className='wrapper' onSubmit={(e) => handleReqChangeSearchConfig(e)}>
                        <div className='wrapper__switchBox'>
                            <section className='first'>노출여부</section>
                            <section>
                                <Switch
                                    size='small'
                                    color='success'
                                    onChange={e => handleChangeViewFlag(e)}
                                    checked={viewFlag || false}
                                />
                            </section>
                        </div>
                        <div className='wrapper__inputBox'>
                            <CustomLabelInput>
                                <CustomLabelInput.Input
                                    type='text'
                                    value={placeholder || ''}
                                    onChange={e => handleChangePlaceholder(e)}
                                    maxLength={20}
                                />
                                <CustomLabelInput.Label>입력레이블</CustomLabelInput.Label>
                            </CustomLabelInput>
                            <div className='counter'>{placeholder?.length} / 20</div>
                        </div>
                        <div className='wrapper__buttonGroup'>
                            <CustomBlockButton
                                type='button'
                                className='cancel'
                                onClick={() => toggleOpenClose(false)}
                            >
                                취소
                            </CustomBlockButton>
                            <CustomBlockButton
                                type='submit'
                                className='confirm'
                            >
                                적용
                            </CustomBlockButton>
                        </div>
                    </form>
                </St.SearchConsoleModalContainer>
            </CustomDialog>
        </>
    );
}

function MdSocialSetting({
    open,
    toggleOpenClose,
    onRefreshPreviewViewer
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const queryParamsHook = useQueryParamsHook();

    const apiHook = useApiHook();

    const socialConfig = userPageContextValueHook?.userPage?.socialConfig ? userPageContextValueHook?.userPage?.socialConfig : {
        viewFlag: true,
        iconColorHex: '#000000',
        socialList: [],
        position: 'TOP',
    }

    const [targetSocialConfig, setTargetSocialConfig] = useState(socialConfig);

    const [viewMode, setViewMode] = useState('DEFAULT') // DEFAULT, ADD_LIST, ADD, EDIT
    const [targetRefSocial, setTargetRefSocial] = useState(null);
    const [addInputValue, setAddInputValue] = useState('');
    const [editInputValue, setEditInputValue] = useState('');
    const [deleteModeOpen, setDeleteModeOpen] = useState(false);

    useEffect(() => {
        // initialize values
        if (!viewMode || viewMode === 'DEFAULT') {
            setTargetRefSocial(null)
            setAddInputValue('')
            setEditInputValue('')
            setDeleteModeOpen(false)
        }

        if (viewMode === 'ADD_LIST') {
            setTargetRefSocial(null)
            setAddInputValue('')
        }

    }, [viewMode]);

    const handleChangeViewMode = (mode) => {
        setViewMode(mode);
    }

    const handleGotoAddNewSocial = (id) => {
        const social = GlobalSocialVariables.list.find(r => r.id === id);
        setTargetRefSocial(social);
        handleChangeViewMode('ADD');
    }

    const handleChangeAddInputValue = (e) => {
        let value = e.target.value;

        setAddInputValue(value);
    }

    const handleDragEnd = (e) => {
        const source = e.source;
        const destination = e.destination;

        if (!destination) {
            return;
        }
        let orderNumbers = targetSocialConfig.socialList.map(r => r.orderNumber);

        let newList = ListUtils().reorder(targetSocialConfig.socialList, source.index, destination.index)
            .map((r, index) => { return { ...r, orderNumber: orderNumbers[index] } });

        setTargetSocialConfig(prev => {
            return {
                ...prev,
                socialList: newList
            }
        })
    }

    const handleAddSubmit = (e) => {
        e.preventDefault();

        const newSocialList = targetSocialConfig?.socialList ? [...targetSocialConfig?.socialList] : [];

        newSocialList.push({
            uuid: uuidv4(),
            socialId: targetRefSocial?.id,
            value: addInputValue,
            linkUrl: `${targetRefSocial?.linkPrefix}${addInputValue}`,
            linkPrefix: targetRefSocial?.linkPrefix,
            orderNumber: newSocialList?.length
        })

        setTargetSocialConfig(prev => {
            return {
                ...prev,
                socialList: newSocialList
            }
        });

        handleChangeViewMode('DEFAULT');
    }

    const handleGotoEditSocial = (editSocial) => {
        const refSocial = GlobalSocialVariables?.list?.find(r => r.id === editSocial.socialId);
        setTargetRefSocial(refSocial);
        setEditInputValue(editSocial?.value);
        handleChangeViewMode('EDIT');
    }

    const handleChangeEditInputValue = (e) => {
        let value = e.target.value;

        setEditInputValue(value);
    }

    const handleEditSubmit = (e) => {
        e.preventDefault();

        setTargetSocialConfig(prev => {
            return {
                ...prev,
                socialList: prev?.socialList?.map(r => {
                    if (r.socialId === targetRefSocial?.id) {
                        return {
                            ...r,
                            value: editInputValue,
                            linkUrl: `${targetRefSocial?.linkPrefix}${editInputValue}`,
                            linkPrefix: targetRefSocial?.linkPrefix,
                        }
                    }
                    return {
                        ...r,
                    }
                })
            }
        });

        handleChangeViewMode('DEFAULT');
    }

    const toggleDeleteModeOpen = (bool) => {
        setDeleteModeOpen(bool);
    }

    const handleDeleteSubmit = () => {
        let newSocialList = targetSocialConfig?.socialList?.filter(r => r.socialId !== targetRefSocial?.id)?.map((r, index) => {
            return {
                ...r,
                orderNumber: index
            }
        });

        setTargetSocialConfig(prev => {
            return {
                ...prev,
                socialList: newSocialList
            }
        });

        handleChangeViewMode('DEFAULT');
    }

    const handleReqChangeSocialConfig = async (e) => {
        e.preventDefault();

        let body = {
            id: userPageContextValueHook?.userPage?.id,
            socialConfig: {
                ...socialConfig,
                socialList: targetSocialConfig?.socialList || []
            }
        }

        CustomBackdropController().showBackdrop();
        const changeResult = await apiHook.reqChangeUserPage_socialConfig({ body });

        if (changeResult?.content) {
            userPageContextActionsHook.onSetUserPage({ ...changeResult?.content });
            toggleOpenClose(false);
            onRefreshPreviewViewer();
        }
        CustomBackdropController().hideBackdrop();
    }

    const handleLinkToDesignSetting = () => {
        const newQueryParams = { ...queryParamsHook?.params };

        queryParamsHook.setSearchParams({ ...newQueryParams, tabType: 'DESIGN_SETTING' }, { replace: true });
    }

    return (
        <>
            <CustomDialog open={open} onClose={() => toggleOpenClose(false)}>
                {(!viewMode || viewMode === 'DEFAULT') &&
                    <St.MdSocialSetting.BodyContainer>
                        <CustomDialog.Header style={{ padding: '20px' }}>
                            <CustomDialog.Header.IconButton onClick={() => handleLinkToDesignSetting()}>
                                <CustomRatioImage>
                                    <CustomRatioImage.Image src={'/assets/icons/color_setting.svg'} alt={'close x'} />
                                </CustomRatioImage>
                            </CustomDialog.Header.IconButton>
                            <CustomDialog.Header.Title>소셜설정</CustomDialog.Header.Title>
                            <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                        </CustomDialog.Header>
                        <div className='header'>
                            <CustomBlockButton
                                type='button'
                                onClick={() => handleChangeViewMode('ADD_LIST')}
                            >
                                추가
                            </CustomBlockButton>
                        </div>
                        <form onSubmit={(e) => handleReqChangeSocialConfig(e)}>
                            <div className='body'>
                                <DragDropContext
                                    onDragEnd={(e) => handleDragEnd(e)}
                                >
                                    <Droppable
                                        droppableId={'sortDroppableId'}
                                        direction="vertical"
                                        isCombineEnabled
                                    >
                                        {(droppableProvided) => (
                                            <div
                                                ref={droppableProvided.innerRef}
                                                {...droppableProvided.droppableProps}
                                            >
                                                {targetSocialConfig?.socialList?.map((social, index) => {
                                                    const RefSocial = GlobalSocialVariables.list.find(r => r.id === social.socialId);

                                                    return (
                                                        <Draggable key={social.uuid} draggableId={`Social-${social.uuid}`} index={index}>
                                                            {(draggableProvided) => (
                                                                <div
                                                                    className='card'
                                                                    ref={draggableProvided.innerRef}
                                                                    {...draggableProvided.draggableProps}
                                                                >
                                                                    <section className='type'>
                                                                        <figure className='icon dnd' {...draggableProvided.dragHandleProps}>
                                                                            <CustomRatioImage>
                                                                                <CustomRatioImage.Image src={'/assets/icons/dragIndicator_808080.svg'} alt={'dnd handle'} />
                                                                            </CustomRatioImage>
                                                                        </figure>
                                                                        <figure className='icon'>
                                                                            <RefSocial.SvgIcon />
                                                                        </figure>
                                                                        <div className='name'>
                                                                            {RefSocial.name}
                                                                        </div>
                                                                    </section>
                                                                    <section className='edit'>
                                                                        <CustomBlockButton
                                                                            type='button'
                                                                            onClick={() => handleGotoEditSocial(social)}
                                                                        >
                                                                            <figure className='icon'>
                                                                                <CustomRatioImage>
                                                                                    <CustomRatioImage.Image src={'/assets/icons/edit2_000000.svg'} alt={'edit icon'} />
                                                                                </CustomRatioImage>
                                                                            </figure>
                                                                        </CustomBlockButton>
                                                                    </section>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {droppableProvided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>
                            <div className='footerWrapper'>
                                <CustomBlockButton
                                    type='button'
                                    className='cancel'
                                    onClick={() => toggleOpenClose(false)}
                                >
                                    취소
                                </CustomBlockButton>
                                <CustomBlockButton
                                    type='submit'
                                    className='confirm'
                                >
                                    적용
                                </CustomBlockButton>
                            </div>
                        </form>
                    </St.MdSocialSetting.BodyContainer>
                }
                {viewMode === 'ADD_LIST' &&
                    <St.MdSocialSetting.AddListContainer>
                        <CustomDialog.Header>
                            <CustomDialog.Header.Back onClick={() => handleChangeViewMode('DEFAULT')} />
                            <CustomDialog.Header.Title>소셜추가</CustomDialog.Header.Title>
                            <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                        </CustomDialog.Header>
                        <div className='body'>
                            {GlobalSocialVariables.list?.map(Social => {
                                const AlreadyAddedSocial = (targetSocialConfig?.socialList ? targetSocialConfig?.socialList : []).find(r => r.socialId === Social.id);

                                return (
                                    <div
                                        className='card'
                                        onClick={() => AlreadyAddedSocial ? {} : handleGotoAddNewSocial(Social.id)}
                                        key={Social.id}
                                    >
                                        <section className='type'>
                                            <figure className='icon'>
                                                <Social.SvgIcon />
                                            </figure>
                                            <div className='name'>
                                                {Social.name}
                                            </div>
                                            {AlreadyAddedSocial &&
                                                <div className='alreadyAddedBadge'>이미 추가됨</div>
                                            }
                                        </section>
                                        <section className='go'>
                                            <figure className='icon'>
                                                <CustomRatioImage>
                                                    <CustomRatioImage.Image src={'/assets/icons/arrowForward_default_000000.svg'} alt={'arrow forward'} />
                                                </CustomRatioImage>
                                            </figure>
                                        </section>
                                    </div>
                                );
                            })}
                        </div>
                    </St.MdSocialSetting.AddListContainer>
                }
                {viewMode === 'ADD' &&
                    <St.MdSocialSetting.AddContainer>
                        <CustomDialog.Header>
                            <CustomDialog.Header.Back onClick={() => handleChangeViewMode('ADD_LIST')} />
                            <CustomDialog.Header.Title>소셜추가</CustomDialog.Header.Title>
                            <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                        </CustomDialog.Header>
                        <form onSubmit={(e) => handleAddSubmit(e)}>
                            <div className='body'>
                                <div className='targetSocial'>
                                    <section className='icon'>
                                        <targetRefSocial.SvgIcon />
                                    </section>
                                    <section className='name'>
                                        {targetRefSocial?.name}
                                    </section>
                                </div>
                                <CustomLabelInput className='inputBox'>
                                    <CustomLabelInput.Input
                                        type='text'
                                        value={addInputValue || ''}
                                        onChange={(e) => handleChangeAddInputValue(e)}
                                        autoFocus
                                    />
                                    <CustomLabelInput.Label>{targetRefSocial?.placeholder}</CustomLabelInput.Label>
                                </CustomLabelInput>
                            </div>
                            <div className='footer'>
                                <CustomBlockButton
                                    type='submit'
                                    disabled={!addInputValue}
                                >
                                    추가
                                </CustomBlockButton>
                            </div>
                        </form>
                    </St.MdSocialSetting.AddContainer>
                }

                {viewMode === 'EDIT' &&
                    <St.MdSocialSetting.EditContainer>
                        <CustomDialog.Header>
                            <CustomDialog.Header.Back onClick={() => handleChangeViewMode('DEFAULT')} />
                            <CustomDialog.Header.Title>소셜수정</CustomDialog.Header.Title>
                            <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                        </CustomDialog.Header>
                        {deleteModeOpen ?
                            <div className='footer'>
                                <div className='deleteConfirmBox'>
                                    <div className='notice'>소셜을 정말로 삭제 하시겠습니까?</div>
                                    <div className='buttonGroup'>
                                        <CustomBlockButton
                                            type='button'
                                            className='cancel'
                                            onClick={() => toggleDeleteModeOpen(false)}
                                        >
                                            취소
                                        </CustomBlockButton>
                                        <CustomBlockButton
                                            type='button'
                                            className='delete'
                                            onClick={() => handleDeleteSubmit()}
                                        >
                                            삭제
                                        </CustomBlockButton>
                                    </div>
                                </div>
                            </div>
                            :
                            <form onSubmit={(e) => handleEditSubmit(e)}>
                                <div className='body'>
                                    <div className='targetSocial'>
                                        <section className='icon'>
                                            <targetRefSocial.SvgIcon />
                                        </section>
                                        <section className='name'>
                                            {targetRefSocial?.name}
                                        </section>
                                    </div>
                                    <CustomLabelInput className='inputBox'>
                                        <CustomLabelInput.Input
                                            type='text'
                                            value={editInputValue || ''}
                                            onChange={(e) => handleChangeEditInputValue(e)}
                                            autoFocus
                                        />
                                        <CustomLabelInput.Label>{targetRefSocial?.placeholder}</CustomLabelInput.Label>
                                    </CustomLabelInput>
                                </div>
                                <div className='footer'>
                                    <CustomBlockButton
                                        type='submit'
                                        className='edit'
                                        disabled={!editInputValue}
                                    >
                                        수정
                                    </CustomBlockButton>
                                    <CustomBlockButton
                                        type='button'
                                        className='delete'
                                        onClick={() => toggleDeleteModeOpen(true)}
                                    >
                                        소셜 삭제
                                    </CustomBlockButton>
                                </div>
                            </form>
                        }
                    </St.MdSocialSetting.EditContainer>
                }
            </CustomDialog>
        </>
    );
}