import { createContext, useContext, useMemo, useState } from "react";

const LinkCategoryValueContext = createContext();
const LinkCategoryActionsContext = createContext();

export function LinkCategoryContextProvider({ children }) {
    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const contentActions = useMemo(
        () => {
            return {
                onSet(value) {
                    setContent(value);
                }
            }
        },
        []
    )

    const isLoadingActions = useMemo(
        () => {
            return {
                onSet(value) {
                    setIsLoading(value);
                }
            }
        },
        []
    )

    return (
        <>
            <LinkCategoryActionsContext.Provider value={{ content: contentActions, isLoading: isLoadingActions }}>
                <LinkCategoryValueContext.Provider value={{ content: content, isLoading: isLoading }}>
                    {children}
                </LinkCategoryValueContext.Provider>
            </LinkCategoryActionsContext.Provider>
        </>
    );
}

export function useLinkCategoryContextValueHook(props) {
    const value = useContext(LinkCategoryValueContext);

    if (value === undefined) {
        throw new Error('useLinkCategoryContextValueHook should be used within LinkCategoryValueContext');
    }

    return {
        content: value?.content,
        isLoading: value?.isLoading
    };
}

export function useLinkCategoryContextActionsHook(props) {
    const value = useContext(LinkCategoryActionsContext);

    if (value === undefined) {
        throw new Error('useLinkCategoryContextActionsHook should be used within LinkCategoryActionsContext');
    }

    return {
        content: {
            onSet: value.content.onSet
        },
        isLoading: {
            onSet: value.isLoading.onSet
        }
    };
}