import axios from "axios";

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function DesignThemesApi(props) {
    return {
        searchList: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/design-themes`, {
                headers: headers
            });
        },
    }
}