import { useState } from "react";

const INIT_OFFSET = 0;
const INIT_LIMIT = 50;
const DEFAULT_LIMIT = 50;

export function useLinkItemListHook(props) {
    const [content, setContent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [currentSearchQuery, setCurrentSearchQuery] = useState(null);
    const [offset, setOffset] = useState(INIT_OFFSET);
    const [limit, setLimit] = useState(INIT_LIMIT);
    const [hasMore, setHasMore] = useState(true);

    return {
        content,
        isLoading,
        currentSearchQuery,
        offset,
        limit,
        hasMore,

        INIT_OFFSET,
        INIT_LIMIT,
        DEFAULT_LIMIT,

        setContent,
        setIsLoading,
        setCurrentSearchQuery,
        setOffset,
        setLimit,
        setHasMore,
    }
}