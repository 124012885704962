import React from 'react';
import * as St from './FdViewer.styled';
import { useWindowResizeHook } from '../../../../../hooks/useWindowResizeHook';
import { useUserPageContextValueHook } from '../../../../../contexts/UserPageContextProvider';
import { useQueryParamsHook } from '../../../../../hooks/useQueryParamsHook';

export const FdViewer = React.forwardRef(function FdViewer(props, ref) {
    const origin = window.location.origin;
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook?.params?.lcId;
    const userPageContextValueHook = useUserPageContextValueHook();
    const windowResizeHook = useWindowResizeHook();

    const frameScale = Math.min(windowResizeHook[1] ? windowResizeHook[1] * 0.001 : 0.7, 0.7);

    let url = `${origin}/preview/${userPageContextValueHook.userPage?.domainName}`;

    if (lcId) {
        url = `${origin}/preview/${userPageContextValueHook.userPage?.domainName}?lcId=${lcId}`;
    }

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <St.DeviceFrameContainer
                        style={{ scale: `${frameScale}` }}
                    >
                        <iframe
                            ref={ref}
                            src={url}
                            title='page_preview'
                            httpEquiv="Content-Security-Policy"
                            content="frame-src 'none';"
                        ></iframe>
                    </St.DeviceFrameContainer>
                </St.Wrapper>
            </St.Container>
        </>
    );
})