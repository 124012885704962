import styled from 'styled-components';

const Container = styled.div`
    height: 100vh;
    width: 100vw;
    background-color: #f0f0f0;
`;

const Wrapper = styled.div`
    img{
        width: 100px;
        position: fixed;
        left: 50%;
        top: 50%;
        translate: -50% -50%;
    }
`;
export function PageLoading(props) {
    return (
        <>
            <Container>
                <Wrapper>
                    <img src='/assets/gifs/loading_bars.gif' alt='페이지_로딩_gif'></img>
                </Wrapper>
            </Container>
        </>
    );
}