import { useRef, useState } from 'react';
import { CustomRatioImage } from '../../../../../components/image/CustomRatioImage';
import * as St from './FdSearch.styled';
import CutstomRefInput from '../../../../../components/input/CustomInput';
import CustomBlockButton from '../../../../../components/button/CustomBlockButton';

export function FdSearch({
    placeholder,
    onReqFetchLinkItemList
}) {
    const inputRef = useRef();
    const [focused, setFocused] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const handleChangeSearchQuery = (e) => {
        const value = e.target.value;

        setSearchQuery(value);
    }

    const handleSearch = (e) => {
        e.preventDefault();

        onReqFetchLinkItemList({ mode: 'searchQuery', searchQuery: searchQuery })
    }

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <form onSubmit={(e) => handleSearch(e)}>
                        <St.SearchWrapper style={focused ? { scale: '1.04', border: '1.5px solid #000', borderRadius:'30px' } : {}}>
                            <div className='iconBox'>
                                <CustomRatioImage>
                                    <CustomRatioImage.Image src='/assets/icons/search_000000.svg' />
                                </CustomRatioImage>
                            </div>
                            <CutstomRefInput
                                type='text'
                                ref={inputRef}
                                placeholder={placeholder ?? '검색어를 입력해 주세요.'}
                                onFocus={() => setFocused(true)}
                                onBlur={() => setFocused(false)}
                                onChange={(e) => handleChangeSearchQuery(e)}
                                value={searchQuery || ''}
                            />
                            <CustomBlockButton
                                type='submit'
                            >
                                검색
                            </CustomBlockButton>
                        </St.SearchWrapper>
                    </form>
                </St.Wrapper>
            </St.Container>
        </>
    );
}