import * as St from './FdAlert.styled';
import Marquee from "react-fast-marquee";

export function FdAlert({
    noticeConfig
}) {
    return (
        <>
            <St.Container>
                <St.Wrapper style={{ backgroundColor: noticeConfig?.backgroundColorHex || '#e5f6fd' }}>
                    <div className='flexWrapper'>
                        <section className='first'>
                            <BrandAwarenessIcon fill={noticeConfig?.iconColorHex || '#ef476f'} />
                        </section>
                        <section className='second'>
                            <Marquee
                                speed={50}
                                delay={1}
                                autoFill={false}
                            >
                                <div className='marqueeText' style={{ color: noticeConfig?.textColorHex || '#014361' }}>
                                    {noticeConfig?.text}
                                </div>
                            </Marquee>
                        </section>
                    </div>
                </St.Wrapper>
            </St.Container>
        </>
    );
}

function BrandAwarenessIcon({
    fill = '#ef476f',
    ...props
}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48" fill={fill} {...props}><path d="M776.935-449.37h-87.87q-13.956 0-23.293-9.336-9.337-9.337-9.337-23.294t9.337-23.294q9.337-9.336 23.293-9.336h87.87q14.196 0 23.533 9.336 9.337 9.337 9.337 23.294t-9.337 23.294q-9.337 9.336-23.533 9.336ZM587.913-272.674q7.717-10.717 20.913-13.337 13.196-2.619 23.913 5.859l74.392 55.674q10.956 7.478 12.576 20.174 1.619 12.695-6.098 23.413-7.718 10.956-20.913 13.695-13.196 2.74-24.152-5.978l-73.914-55.674q-10.956-7.717-12.576-20.293-1.619-12.576 5.859-23.533Zm118.978-462.848-75.152 55.674q-10.717 8.478-23.413 5.978t-20.413-13.456q-7.478-10.717-5.859-23.413 1.62-12.696 12.576-20.413l75.153-55.913q10.717-8.479 23.413-5.739 12.695 2.739 20.174 13.456 7.717 10.957 6.098 23.652-1.62 12.696-12.577 20.174Zm-432.869 381.5H144.5q-14.674 0-24.37-9.695-9.695-9.696-9.695-24.37v-183.826q0-14.674 9.695-24.489 9.696-9.816 24.37-9.816h129.522l150.195-150.195q16.153-16.152 37.163-7.576 21.011 8.576 21.011 31.206v505.327q0 22.869-21.011 31.445-21.01 8.576-37.163-7.576L274.022-354.022Zm140-288.717L304.13-537.848H178.565v115.696H304.13l109.892 105.891v-326.478ZM294.174-480Z" /></svg>
    );
}