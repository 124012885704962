import styled from 'styled-components';

const LineBreaker = styled.div`
    display: flex;
    justify-content: ${props => props.$align};
    align-items: center;
`;

export function VerticalDottedBreaker({
    fill,
    width,
    height,
    lineStyle,
    align // center, left, right
}) {
    return (
        <LineBreaker
            $align={align || 'center'}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width={width || "32px"} height={height || "32px"} viewBox="0 -960 960 960" fill={fill || '#000000'}><path d="M479.91-160q-24.24 0-41.41-17.26-17.17-17.26-17.17-41.5t17.26-41.41q17.27-17.16 41.5-17.16 24.24 0 41.41 17.26 17.17 17.26 17.17 41.5t-17.26 41.4Q504.14-160 479.91-160Zm0-261.33q-24.24 0-41.41-17.26-17.17-17.27-17.17-41.5 0-24.24 17.26-41.41 17.27-17.17 41.5-17.17 24.24 0 41.41 17.26 17.17 17.27 17.17 41.5 0 24.24-17.26 41.41-17.27 17.17-41.5 17.17Zm0-261.34q-24.24 0-41.41-17.26-17.17-17.26-17.17-41.5t17.26-41.4Q455.86-800 480.09-800q24.24 0 41.41 17.26 17.17 17.26 17.17 41.5t-17.26 41.41q-17.27 17.16-41.5 17.16Z" /></svg>
        </LineBreaker>
    );
}