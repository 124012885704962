import { colors } from '@mui/material';
import { CustomChart } from '../../../../../../components/chart/CustomChart';
import * as St from './FgReferrerInflowDetailChart.styled';
import { DefinedVariable } from '../DefinedVariable';

export function FgReferrerInflowDetailChart({
    targetVisitLog
}) {
    if (!targetVisitLog) {
        return null;
    }

    const newHeight = Math.max(300, 200 + targetVisitLog?.dataList?.length * 50);

    const chartOptions = {
        indexAxis: 'y', // Y축을 카테고리 축으로 설정
        responsive: true,
        maintainAspectRatio: false, // 차트의 비율을 유지하지 않도록 설정
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        size: 11
                    }
                },
            },
            title: {
                display: true,
                text: `${targetVisitLog?.visitDate} 의 유입 경로`,
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: '방문 수',
                    font: {
                        size: 11,
                        weight: 600,
                    },
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 7,
                    // y축 눈금을 정수로 설정
                    callback: function (value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    },
                },
                grid: {
                    display: true,
                    offset: false,
                },
                stacked: false,
            },
            y: {
                title: {
                    display: false,
                    text: '유입 경로',
                    font: {
                        size: 11,
                        weight: 600,
                    },
                    className: 'y-axis-label', // 추가된 CSS 클래스
                },
                grid: {
                    display: false,
                    offset: false,
                },
                ticks: {
                    callback: function (value, index, values) {
                        // 라벨을 두줄로 쓰려면 배열로 리턴하면 됨.
                        const label = this.getLabelForValue(value);
                        if (label.length < 10) {
                            return label;
                        }

                        if (label.length >= 10 && label.length < 20) {
                            return [label.substring(0, 10), label.substring(10, 20)];
                        }

                        if (label.length >= 20) {
                            return [label.substring(0, 10), label.substring(10, 20) + '...'];
                        }
                    },
                },
            },
        },
        // y축 기준으로 차트를 가로화 시킬때는 axis를 y 로 해야 빈공간에 호버해도 툴팁이 반응함.
        interaction: {
            mode: 'index',
            intersect: false,
            axis: 'y'
        },
    };

    const data = {
        labels: targetVisitLog?.dataList ? [
            ...targetVisitLog?.dataList?.map(targetVisitLogData => {
                const definedReferrer = DefinedVariable.DEFINED_REFERRER_LIST?.find(r => r.referrer === targetVisitLogData.referrer)
                if (definedReferrer) {
                    return definedReferrer?.convertedName;
                } else {
                    return targetVisitLogData.referrer;
                }
            })
        ] : [],
        // labels: [['hello','world']],
        datasets: [
            {
                type: 'bar',
                label: '유효 방문 수',
                data: targetVisitLog?.dataList ? [...targetVisitLog?.dataList?.map(r => r.firstVisitCount)] : [],
                borderColor: '#90EE90',
                backgroundColor: '#90EE90',
                borderWidth: 2,
                fill: false,
                barPercentage: 0.6,
                // categoryPercentage: 0.8,
                order: 0,
                borderRadius: 5,
                tension: 0.3,
                stack: 'Stack 0',
            },
            {
                type: 'bar',
                label: '총 방문 수',
                data: targetVisitLog?.dataList ? [...targetVisitLog?.dataList?.map(r => r.totalVisitCount)] : [],
                borderColor: '#a0a0a0',
                backgroundColor: '#a0a0a0',
                borderWidth: 2,
                fill: false,
                barPercentage: 0.3,
                // categoryPercentage: 0.8,
                order: 0,
                borderRadius: 5,
                tension: 0.3,
                stack: 'Stack 0',
            },
        ],
    }

    return (
        <>
            <St.Container>
                <St.ChartWrapper style={{ height: newHeight }}>
                    <CustomChart.ReactChart
                        options={chartOptions}
                        data={data}
                        plugins={[]}
                    />
                </St.ChartWrapper>
            </St.Container>
        </>
    );
}