export function CustomRegexUtils(props) {
    return {
        hasHttpPrefix: hasHttpPrefix,
        isValidUrlFormat: isValidUrlFormat,
    }
}

function hasHttpPrefix(url) {
    const urlRegex = /^(https?:\/\/)/;

    if (urlRegex.test(url)) {
        return true;
    } else {
        return false;
    }
}

function isValidUrlFormat(url) {
    const regex = /^(https?:\/\/)?([\da-zㄱ-힣.-]+)\.([a-z.ㄱ-힣]{2,6})/;

    if (regex.test(url)) {
        return true;
    } else {
        return false;
    }
}