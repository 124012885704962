import { useState } from "react";
import CustomBlockButton from "../../../../../components/button/CustomBlockButton";
import * as St from './FdMobilePreview.styled';
import { useQueryParamsHook } from "../../../../../hooks/useQueryParamsHook";
import { useUserPageContextValueHook } from "../../../../../contexts/UserPageContextProvider";
import { useWindowResizeHook } from "../../../../../hooks/useWindowResizeHook";
import { CustomDialog } from "../../../../../components/dialog/v1/CustomDialog";
import { CustomRatioImage } from "../../../../../components/image/CustomRatioImage";

export function FdMobilePreview(props) {
    const [previewModalOpen, setPreviewModalOpen] = useState(false);

    return (
        <>
            <St.Container>
                <CustomBlockButton
                    type='button'
                    onClick={() => setPreviewModalOpen(true)}
                >
                    미리보기
                </CustomBlockButton>
            </St.Container>

            {previewModalOpen &&
                <MdPreview
                    toggleOpenClose={setPreviewModalOpen}
                />
            }
        </>
    );
}

function MdPreview({
    toggleOpenClose
}) {
    const origin = window.location.origin;
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook?.params?.lcId;
    const userPageContextValueHook = useUserPageContextValueHook();

    let url = `${origin}/preview/${userPageContextValueHook.userPage?.domainName}`;

    if (lcId) {
        url = `${origin}/preview/${userPageContextValueHook.userPage?.domainName}?lcId=${lcId}`;
    }

    return (
        <St.MdPreviewDeviceFrameContainer>
            <div className='floatingClose'>
                <CustomBlockButton
                    type='button'
                    onClick={() => toggleOpenClose(false)}
                >
                    <div className='iconFigure'>
                        <CustomRatioImage>
                            <CustomRatioImage.Image src={'/assets/icons/close_default_ffffff.svg'} />
                        </CustomRatioImage>
                    </div>
                </CustomBlockButton>
            </div>
            <iframe
                src={url}
                title='page_preview'
                httpEquiv="Content-Security-Policy"
                content="frame-src 'none';"
            ></iframe>
        </St.MdPreviewDeviceFrameContainer>
    );
}