import * as St from './FdSocialLink.styled';
import { GlobalSocialVariables } from '../../../../../variables/GlobalSocialVariables';

export function FdSocialLink({
    userPage,
    ...props
}) {
    const socialConfig = userPage?.socialConfig;

    return (
        <>
            <St.Container {...props}>
                <St.LinksWrapper>
                    {socialConfig?.socialList?.map(social => {
                        const RefSocial = GlobalSocialVariables.list.find(r => r.id === social?.socialId);
                        return (
                            <a href={social?.linkUrl} target='_blank' rel="noreferrer" key={social?.uuid}>
                                <div className='iconLink'>
                                    {RefSocial.SvgIcon({ fill: socialConfig?.iconColorHex })}
                                </div>
                            </a>
                        );
                    })}
                </St.LinksWrapper>
            </St.Container>
        </>
    );
}