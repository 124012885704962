import { useState } from 'react';
import { CustomPopover, useCustomPopoverHook } from '../../../../../../../../../components/popover/CustomPopover';
import { useLinkCategoryContextValueHook } from '../../../../../../contexts/LinkCategoryContextProvider';
import * as St from './WegetSeparatorItem.styled';
import { Draggable } from 'react-beautiful-dnd';
import { Switch, Tooltip } from '@mui/material';
import CustomBlockButton from '../../../../../../../../../components/button/CustomBlockButton';
import { DeleteIcon } from '../../../../../../../../../components/svg-icons/DeleteIcon';
import { CustomRatioImage } from '../../../../../../../../../components/image/CustomRatioImage';
import { useUserPageContextValueHook } from '../../../../../../../../../contexts/UserPageContextProvider';
import { customToast } from '../../../../../../../../../components/toast/custom-react-toastify/v1';
import { CustomDialog } from '../../../../../../../../../components/dialog/v1/CustomDialog';
import { CustomLabelTextarea } from '../../../../../../../../../components/textarea/CustomLabelTextarea';
import CustomSelect from '../../../../../../../../../components/select/default/v1/CustomSelect';
import { GlobalSeparatorVariables } from '../../../../../../../../../variables/GlobalSeparatorVariables';

export function WegetSeparatorItem({
    moduleItemIndex,
    moduleItem,
    onReqDelete,
    onReqChangeIsHide,
    onReqUpdateInfo
}) {
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();
    const descriptionPopoverHook = useCustomPopoverHook();
    const thumbnailPopoverHook = useCustomPopoverHook();
    const deletePopoverHook = useCustomPopoverHook();

    const [updateLinkItemModalOpen, setUpdateLinkItemModalOpen] = useState(false);

    const separatorConfig = moduleItem?.separatorConfig;
    const Separator = GlobalSeparatorVariables.list.find(r => r.separator === separatorConfig?.separator) || GlobalSeparatorVariables.list[0];

    const toggleUpdateLinkItemModalOpen = (bool) => {
        setUpdateLinkItemModalOpen(bool);
    }

    const handleDelete = async () => {
        deletePopoverHook.closePopover();
        await onReqDelete(moduleItemIndex);
    }

    const handleSubmitUpdateLinkItem = (updateForm) => {
        updateForm = {
            id: moduleItem?.id,
            ...updateForm
        }

        onReqUpdateInfo(updateForm);
        toggleUpdateLinkItemModalOpen(false);
    }
    return (
        <>
            <Draggable key={moduleItem.id} draggableId={`SubFdElements-${moduleItem.id}`} index={moduleItemIndex}>
                {(draggableProvided) => (
                    <St.ModuleContainer
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                    >
                        <div className='deleteButtonField'>
                            <Tooltip title='삭제'>
                                <CustomBlockButton
                                    type='button'
                                    onClick={(e) => deletePopoverHook.openPopover(e, moduleItemIndex)}
                                >
                                    <DeleteIcon />
                                </CustomBlockButton>
                            </Tooltip>
                        </div>
                        <section className='first'>
                            <div className='icon' {...draggableProvided.dragHandleProps}>
                                <CustomRatioImage width={1} height={1}>
                                    <CustomRatioImage.Image src='/assets/icons/dragIndicator_808080.svg' />
                                </CustomRatioImage>
                            </div>
                        </section>
                        <section className='second'>
                            <section className='second_1'>
                                <section className='second_1_1'>
                                    <div className='badgeBox'>
                                        <div className='iconFigure'>
                                            <CustomRatioImage>
                                                <CustomRatioImage.Image src='/assets/icons/separator_default_mainColor.svg' />
                                            </CustomRatioImage>
                                        </div>
                                        <div className='badge'>구분선</div>
                                    </div>
                                    <div>
                                        <div className='separatorName'>{Separator?.name}</div>
                                        <div>
                                            <Separator.Component

                                            />
                                        </div>
                                    </div>
                                </section>
                                <section className='second_1_2'>
                                    <Tooltip title='수정'>
                                        <CustomBlockButton
                                            type='button'
                                            onClick={() => toggleUpdateLinkItemModalOpen(true)}
                                        >
                                            <div className='icon'>
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src='/assets/icons/edit2_000000.svg' />
                                                </CustomRatioImage>
                                            </div>
                                        </CustomBlockButton>
                                    </Tooltip>
                                    <div className='switchBox'>
                                        <Tooltip title='보이기 or 숨기기'>
                                            <Switch size='small' color='success' onChange={e => onReqChangeIsHide(moduleItemIndex)} checked={!moduleItem?.isHide} />
                                        </Tooltip>
                                    </div>
                                </section>
                            </section>
                            <section className='second_2'>
                                <section className='second_2_1'>
                                </section>
                                {moduleItem?.linkCategoryId &&
                                    <section className='second_2_2'>
                                        <div className='category'>
                                            {linkCategoryContextValueHook?.content?.find(r => r.id === moduleItem?.linkCategoryId)?.name}
                                        </div>
                                    </section>
                                }
                            </section>
                        </section>
                        {draggableProvided.placeholder}
                    </St.ModuleContainer>
                )}
            </Draggable>
            {descriptionPopoverHook?.anchorEl &&
                <CustomPopover
                    anchorEl={descriptionPopoverHook?.anchorEl}
                    onClose={() => descriptionPopoverHook?.closePopover()}
                    layoutStyle={!descriptionPopoverHook?.element ? { color: '#808080' } : {}}
                >
                    {descriptionPopoverHook?.element ? descriptionPopoverHook?.element : '등록된 설명이 없습니다.'}
                </CustomPopover>
            }

            {thumbnailPopoverHook?.anchorEl &&
                <CustomPopover
                    anchorEl={thumbnailPopoverHook?.anchorEl}
                    onClose={() => thumbnailPopoverHook?.closePopover()}
                    layoutStyle={!thumbnailPopoverHook?.element ? { color: '#808080' } : {}}
                >
                    {thumbnailPopoverHook?.element ?
                        (
                            <St.ThumbnailPopoverElement>
                                <CustomRatioImage>
                                    <CustomRatioImage.Image src={thumbnailPopoverHook?.element} />
                                </CustomRatioImage>
                            </St.ThumbnailPopoverElement>
                        )
                        :
                        '등록된 썸네일이 없습니다.'
                    }
                </CustomPopover>
            }
            <CustomPopover
                anchorEl={deletePopoverHook?.anchorEl}
                onClose={() => deletePopoverHook?.closePopover()}
                customLayout
            >
                <St.DeletePopoverElement>
                    <div className='notice'>삭제 버튼을 누르면 영구적으로 삭제됩니다.</div>
                    <CustomBlockButton
                        type='button'
                        onClick={() => handleDelete()}
                    >
                        삭제
                    </CustomBlockButton>
                </St.DeletePopoverElement>
            </CustomPopover>

            {(updateLinkItemModalOpen && moduleItem) &&
                <UpdateModuleModal
                    open={updateLinkItemModalOpen}
                    moduleItem={moduleItem}
                    toggleOpenClose={toggleUpdateLinkItemModalOpen}
                    onConfirm={(body) => handleSubmitUpdateLinkItem(body)}
                />
            }
        </>
    );
}

function UpdateModuleModal({
    open,
    moduleItem,
    toggleOpenClose,
    onConfirm,
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();

    const separatorConfig = moduleItem?.separatorConfig;

    const [separatorViewMode, setSeparatorViewMode] = useState('DEFAULT'); // DEFAULT, EDIT
    const [separatorType, setSeparatorType] = useState(separatorConfig?.separator);

    const [linkCategoryId, setLinkCategoryId] = useState(linkCategoryContextValueHook?.content?.find(r => r.id === moduleItem?.linkCategoryId)?.id || '');

    const CurrentSeparator = GlobalSeparatorVariables.list.find(r => r.separator === separatorType);

    const handleChangeSeparatorViewMode = (mode) => {
        setSeparatorViewMode(mode);
    }

    const handleChangeLinkCategoryId = (e) => {
        const value = e.target.value;

        setLinkCategoryId(value || '');
    }

    const handleChangeSeparatorType = (type) => {
        setSeparatorType(type);
        handleChangeSeparatorViewMode('DEFAULT');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let body = {
            ...moduleItem,
            type: 'SEPARATOR',
            linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r?.id === linkCategoryId)?.id || '',
            userPageId: userPageContextValueHook?.userPage?.id,
            separatorConfig: {
                separator: separatorType,
                iconColorHex: '#808080'
            }
        }
        onConfirm(body);
    }

    return (
        <>
            <CustomDialog open={open} onClose={() => toggleOpenClose(false)} maxWidth='sm'>
                {(!separatorViewMode || separatorViewMode === 'DEFAULT') &&
                    <St.UpdateModuleContainer>
                        <CustomDialog.Header style={{ marginBottom: '20px' }}>
                            <CustomDialog.Header.Fake />
                            <CustomDialog.Header.Title>구분선 모듈</CustomDialog.Header.Title>
                            <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                        </CustomDialog.Header>
                        <div className='wrapper default'>
                            <div className='itemWrapper'>
                                <label><span style={{ color: 'var(--redColor1)' }}>*</span> 구분선</label>
                                <div className='itemBox' onClick={() => handleChangeSeparatorViewMode('EDIT')}>
                                    <section className='content'>
                                        <div className='name'>{CurrentSeparator.name}</div>
                                        <CurrentSeparator.Component />
                                    </section>
                                    <section className='go'>
                                        <figure className='icon'>
                                            <CustomRatioImage>
                                                <CustomRatioImage.Image src={'/assets/icons/arrowForward_default_000000.svg'} alt={'arrow forward'} />
                                            </CustomRatioImage>
                                        </figure>
                                    </section>
                                </div>
                            </div>
                            <div className='wrapper__selectBox'>
                                <CustomSelect
                                    value={linkCategoryId}
                                    onChange={(e) => handleChangeLinkCategoryId(e)}
                                >
                                    <option value=''>전체</option>
                                    {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                        return (
                                            <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                        );
                                    })}
                                </CustomSelect>
                                <label>카테고리{linkCategoryId && <span style={{ color: 'var(--redColor1)', paddingLeft: '10px' }}>* 카테고리가 선택 되었어요!</span>}</label>
                            </div>
                            <div className='wrapper__footerButtonGroup'>
                                <CustomBlockButton
                                    type='button'
                                    onClick={(e) => handleSubmit(e)}
                                >
                                    수정
                                </CustomBlockButton>
                            </div>
                        </div>
                    </St.UpdateModuleContainer>
                }

                {(separatorViewMode === 'EDIT') &&
                    <St.UpdateModuleContainer>
                        <CustomDialog.Header style={{ marginBottom: '20px' }}>
                            <CustomDialog.Header.Back onClick={() => handleChangeSeparatorViewMode('DEFAULT')} />
                            <CustomDialog.Header.Title>구분선 선택</CustomDialog.Header.Title>
                            <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                        </CustomDialog.Header>
                        <div className='wrapper edit'>
                            <div className='body'>
                                <div className='itemList'>
                                    {GlobalSeparatorVariables.list.map(Separator => {
                                        return (
                                            <div
                                                key={Separator.separator}
                                                className='itemBox'
                                                onClick={() => handleChangeSeparatorType(Separator.separator)}
                                            >
                                                <section className='content'>
                                                    <div className='name'>{Separator.name}</div>
                                                    <Separator.Component />
                                                </section>
                                                <section className='go'>
                                                    <figure className='icon'>
                                                        <CustomRatioImage>
                                                            <CustomRatioImage.Image src={'/assets/icons/arrowForward_default_000000.svg'} alt={'arrow forward'} />
                                                        </CustomRatioImage>
                                                    </figure>
                                                </section>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </St.UpdateModuleContainer>
                }
            </CustomDialog>
        </>
    )
}