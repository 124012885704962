import axios from "axios"

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function VisitLogsApi(props) {
    return {
        count: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/visit-logs/count/${params?.userPageId}`, {
                params: {
                    startDateTime: params?.startDateTime,
                    endDateTime: params?.endDateTime,
                    localeDiffHours: params?.localeDiffHours,
                    localeDiffMinutes: params?.localeDiffMinutes,
                },
                headers: headers
            });
        },
        log: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/visit-logs/log`, body, {
                headers: headers
            });
        },
    }
}