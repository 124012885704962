import styled from 'styled-components';
import { CustomInput } from '../../components/input/CustomInput';

export const Container = styled.div`
    padding-top: 40px;
    max-width: 568px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
`;

export const Wrapper = styled.div`
    padding: 20px;
    border-radius: 15px;
    display: flex;
    gap: 40px;
    flex-direction: column;
`;

export const LogoWrapper = styled.div`
    width: 100px;
    margin-left: auto;
    margin-right: auto;
`;

export const IntroduceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap:10px;

    .mainDescription{
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        color: #000;
        word-break: keep-all;

        &.flex{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
        }

        section.icon{
            width: 30px;
            height: 30px;
        }
    }

    .subDescription{
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        color: #404040;
    }
`;

export const InputGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    input{
        background-color: var(--defaultElementBC);
        border: 2px solid #00000000;
    }

    .forgotWrapper{
        display: flex;
        gap: 5px;
        font-size: 13px;

        > a{
            text-decoration: underline;
            color: #666;

            &:hover{
                color: #444;
            }
        }
    }
`;

export const LineBreaker = styled.div`
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    color: #aaa;
    &::before{
        display: inline-block;
        content: "";
        width: 30%;
        height: 1px;
        margin-right: 30px;
        background: #ddd;
        vertical-align: middle;
    }

    &::after{
        display: inline-block;
        content: "";
        width: 30%;
        height: 1px;
        margin-left: 30px;
        background: #ddd;
        vertical-align: middle;
    }
`;

export const LoginButtonGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    button{
        width: 100%;
        height: 48px;
        border-radius: 48px;
        border: none;
        background: #000;
        color: white;
        font-size: 14px;
        font-weight: 700;

        &.login{

        }

        &.reSendEmailVerification{
            background-color: #dfdfdf;
            color: #000;
        }
    }
`;

export const SnsLoginButtonGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    button{
        margin:0;
        padding:0;
        border: 1px solid #000;
        border-radius: 10px;


        > .content{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 16px;
            font-size: 14px;
            font-weight: 700;

            > .logoIcon{
                width: 24px;
            }
        }
    }
`;

export const FooterFieldWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .text{
        font-weight: 600;
        word-break: keep-all;
        @media all and (max-width:768px){
            font-size: 14px;
        }
    }

    .button{
        width: 120px;
        border-radius: 30px;
        height: 40px;
        color:#000;
        font-weight: 700;
        background-color: #fff;
        border: 1px solid #000;

        @media all and (max-width:768px){
            width: 100px;
            font-size: 12px;
        }
    }
`;