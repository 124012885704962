import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserContextProvider } from './contexts/UserContextProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserPageContextProvider } from './contexts/UserPageContextProvider';
import { CustomToastContainer } from './components/toast/custom-react-toastify/v1';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
const theme = createTheme();

// TODO : SEO 관련해서 연구하기

// root.render(
//   // <React.StrictMode>
//   <UserContextProvider>
//     <UserPageContextProvider>
//       <ThemeProvider theme={theme}>
//         <CustomToastContainer />
//         <App />
//       </ThemeProvider>
//     </UserPageContextProvider>
//   </UserContextProvider>
//   // {/* </React.StrictMode> */ }
// );
if (container.hasChildNodes()) {
  ReactDOM.hydrateRoot(
    container,
    <UserContextProvider>
      <UserPageContextProvider>
        <ThemeProvider theme={theme}>
          <CustomToastContainer />
          <App />
        </ThemeProvider>
      </UserPageContextProvider>
    </UserContextProvider>
  );
} else {
  root.render(
    <UserContextProvider>
      <UserPageContextProvider>
        <ThemeProvider theme={theme}>
          <CustomToastContainer />
          <App />
        </ThemeProvider>
      </UserPageContextProvider>
    </UserContextProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
