import axios from "axios"

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function ClickLogsApi(props) {
    return {
        count: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/click-logs/count`, body, {
                headers: headers
            });
        },
        log: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/click-logs/log`, body, {
                headers: headers
            });
        },
    }
}