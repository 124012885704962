import styled from 'styled-components';

const LineBreaker = styled.div`
    display: flex;
    justify-content: ${props => props.$align};
    align-items: center;
`;

export function HorizontalDottedBreaker({
    fill,
    width,
    height,
    lineStyle,
    align // center, left, right
}) {
    return (
        <LineBreaker
            $align={align || 'center'}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width={width || "32px"} height={height || "32px"} viewBox="0 -960 960 960" fill={fill || '#000000'}><path d="M218.57-421.33q-24.24 0-41.4-17.26Q160-455.86 160-480.09q0-24.24 17.26-41.41 17.26-17.17 41.5-17.17t41.41 17.26q17.16 17.27 17.16 41.5 0 24.24-17.26 41.41-17.26 17.17-41.5 17.17Zm261.34 0q-24.24 0-41.41-17.26-17.17-17.27-17.17-41.5 0-24.24 17.26-41.41 17.27-17.17 41.5-17.17 24.24 0 41.41 17.26 17.17 17.27 17.17 41.5 0 24.24-17.26 41.41-17.27 17.17-41.5 17.17Zm261.33 0q-24.24 0-41.41-17.26-17.16-17.27-17.16-41.5 0-24.24 17.26-41.41 17.26-17.17 41.5-17.17t41.4 17.26Q800-504.14 800-479.91q0 24.24-17.26 41.41-17.26 17.17-41.5 17.17Z" /></svg>
        </LineBreaker>
    );
}