import styled from 'styled-components';

export const Container = styled.div`
`;

export const HeaderWrapper = styled.div`
    padding: 20px;
    .headerBox{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title{
            font-size: 18px;
            font-weight: 600;
        }

        .icon{
            width: 30px;
        }
    }
`;

export const TypeSelectorWrapper = styled.div`
    display: flex;
    padding: 0 20px;
    button{
        border: none;
        font-weight: 700;
        color: #606060;
        border: 1px solid #e0e0e0;

        &:first-child{
            border-top-left-radius: 30px;
            border-bottom-left-radius: 30px;
        }

        &:last-child{
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            margin-left: -1px;
        }

        &.active{
            background: #000;
            color: #fff;
            border-color: #000;
        }
    }
`;

export const DesignViewWrapper = styled.div`
    margin-top: 40px;
    padding: 0 20px;
    .classicType{
        .notice{
            display: flex;
            align-items: center;
            width: 53%;
            background: #e5f6fd;
            margin-left: auto;
            margin-right: auto;
            font-size: 10px;
            font-weight: 600;
            margin-bottom: 10px;
            padding: 5px 10px;
            box-sizing: border-box;
            border:1px solid #e5f6fd;

            @media all and (max-width:576px){
                width: 83%;
            }

            .logo{
                width: 15px;
                height: 15px;
            }
        }

        .profileBox{
            width: 47%;
            margin-left: auto;
            margin-right: auto;
            padding: 20px;
            background: #fff;
            box-sizing: border-box;
            @media all and (max-width:576px){
                width: 83%;
            }
        }

        .profileLogo{
            width: 40px;
            height: 40px;
            background: #e0e0e0;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
        }

        .domainName{
            margin-left: auto;
            margin-right: auto;
            font-size: 10px;
            text-align: center;
            margin-top: 5px;
            font-weight: 600;
        }

        .description{
            margin-top: 5px;
            width: 50%;
            height: 10px;
            background: #e0e0e0;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .bannerType{
        .notice{
            display: flex;
            align-items: center;
            width: 60%;
            background: #e5f6fd;
            margin-left: auto;
            margin-right: auto;
            font-size: 10px;
            font-weight: 600;
            padding: 5px 10px;
            box-sizing: border-box;
            border:1px solid #e5f6fd;

            @media all and (max-width:576px){
                width: 90%;
            }

            .logo{
                width: 15px;
                height: 15px;
            }
        }

        .bannerBox{
            border:1px solid #e0e0e0;
            height: 150px;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            background: #e0e0e0;
            font-size: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            box-sizing: border-box;

            @media all and (max-width:576px){
                width: 100%;
            }
        }

        .profileBox{
            margin-top: -10px;
            border: 1px solid #f0f0f0;
            width: 47%;
            margin-left: auto;
            margin-right: auto;
            padding: 20px;
            border-radius: 10px;
            background: #fff;
            box-sizing: border-box;
            @media all and (max-width:576px){
                width: 93%;
            }
        }

        .profileLogo{
            width: 40px;
            height: 40px;
            background: #e0e0e0;
            margin-left: auto;
            margin-right: auto;
            border-radius: 50%;
        }

        .domainName{
            margin-left: auto;
            margin-right: auto;
            font-size: 10px;
            text-align: center;
            margin-top: 5px;
            font-weight: 600;
        }

        .description{
            margin-top: 5px;
            width: 50%;
            height: 10px;
            background: #e0e0e0;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

export const ColorSettingWrapper = styled.div`
    padding: 0 20px;
    margin-top: 20px;
    .title{
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        button{
            width: fit-content;
            height: fit-content;
            padding: 8px 16px;
            border: none;
            background-color: #efefef;
            border-radius: 10px;
            font-weight: 700;
        }
    }

    .body{
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        .colorItemBox{
            .name{
                font-size: 14px;
                font-weight: 600;
            }
            
            .representedColor{
                cursor: pointer;
                display: flex;
                gap: 5px;
                justify-content: flex-start;
                width: fit-content;
                padding: 5px;
                align-items: center;
                box-sizing: border-box;

                .colorBox{
                    width: 30px;
                    height: 30px;
                    border: 1px solid #f0f0f0;
                    border-radius: 30px;
                }

                .colorHex{
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }
`;

export const BannerSettingWrapper = styled.div`
    padding: 0 20px;
    margin-top: 20px;

    .title{
        margin: 0;
        font-size: 16px;
    }

    .body{
        margin-top: 10px;
        width: 40%;
        @media all and (max-width:576px){
            width: 100%;
        }
        .bannerImageBox{    
            width: 100%;
            .bannerImage{
                border: 1px solid #f0f0f0;
                border-radius: 10px;
                overflow: hidden;
            }
        }

        .handlerBox{
            flex:1;
            margin-top: 10px;
            .buttonGroupWrapper{
                display: flex;
                flex-direction: column;
                gap: 10px;

                button{
                    font-size: 12px;
                    color: #000;
                    border-radius: 30px;
                    height: 40px;
                    font-weight: 700;
                    border: none;

                    &.first{
                        background-color: #000;
                        color: #fff;
                    }

                    &.second{
                        background-color: var(--defaultElementBC);
                    }
                }
            }
        }

        .bannerImageNoticeText{
            margin-top: 5px;
            font-size: 10px;
            word-break: keep-all;
            font-weight: 600;
            text-align: center;
            color: #707070;
        }
    }

    .footer{
        margin-top: 10px;
        .buttonGroupWrapper{
            display: flex;
            flex-direction: column;
            gap: 10px;

            button{
                font-size: 12px;
                color: #000;
                border-radius: 30px;
                height: 40px;
                font-weight: 700;
                border: none;

                &.first{
                    background-color: #000;
                    color: #fff;
                }

                &.second{
                    background-color: var(--defaultElementBC);
                }
            }
        }
    }
`;

export const FooterWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    padding: 0 20px;
    justify-content: flex-end;

    button{
        width: 80px;
        height: 40px;
        border-radius: 10px;
        background: var(--mainColor);
        color: #fff;
        border: none;
        font-weight: 700;
    }

`;