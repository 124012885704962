import { useRef, useState } from 'react';
import CustomBlockButton from '../../../../../../../components/button/CustomBlockButton';
import * as St from './SgProfileDesign.styled';
import { CustomRatioImage } from '../../../../../../../components/image/CustomRatioImage';
import { useUserPageContextActionsHook, useUserPageContextValueHook } from '../../../../../../../contexts/UserPageContextProvider';
import { useApiHook } from '../../../../hooks/useApiHook';
import { customToast } from '../../../../../../../components/toast/custom-react-toastify/v1';
import { CustomBackdropController } from '../../../../../../../components/backdrop/default/v1';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import firebaseStorage from '../../../../../../../firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { CustomColorPalette } from '../../../../../../../components/color-palette/CustomColorPalette';

export function SgProfileDesign({
    onRefreshPreviewViewer
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const imageUploaderRef = useRef();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [domainDesignType, setDomainDesignType] = useState((!userPageContextValueHook?.userPage?.domainDesignType || userPageContextValueHook?.userPage?.domainDesignType === 'CLASSIC') ? 'CLASSIC' : 'BANNER') // CLASSIC, BANNER
    const [domainNameColor, setDomainNameColor] = useState(userPageContextValueHook?.userPage?.domainNameColor || '#000000');
    const [descriptionColor, setDescriptionColor] = useState(userPageContextValueHook?.userPage?.descriptionColor || '#000000');
    const [profileBackgroundColor, setProfileBackgroundColor] = useState(userPageContextValueHook?.userPage?.profileBackgroundColor || '#000000');
    const [topBannerImageUrl, setTopBannerImageUrl] = useState(userPageContextValueHook?.userPage?.topBannerImageUrl || '');

    const [domainNameColorChangeModeOpen, setDomainNameColorChangeModeOpen] = useState(false);
    const [descriptionColorChangeModeOpen, setDescriptionColorChangeModeOpen] = useState(false);
    const [profileBackgroundColorChangeModeOpen, setProfileBackgroundColorChangeModeOpen] = useState(false);

    const toggleDomainNameColorChangeModeOpen = (bool) => {
        setDomainNameColorChangeModeOpen(bool);
    }

    const toggleDescriptionColorChangeModeOpen = (bool) => {
        setDescriptionColorChangeModeOpen(bool);
    }

    const toggleProfileBackgroundColorChangeModeOpen = (bool) => {
        setProfileBackgroundColorChangeModeOpen(bool);
    }

    const handleChangeDomainDesignType = (type) => {
        setDomainDesignType(type);
    }

    const handleChangeDomainNameColor = (value) => {
        setDomainNameColor(value);
    }

    const handleChangeDescriptionColor = (value) => {
        setDescriptionColor(value);
    }

    const handleChangeProfileBackgroundColor = (value) => {
        setProfileBackgroundColor(value);
    }

    const handleImageUploaderOpen = () => {
        imageUploaderRef.current.click();
    }

    const handleSelectBanner = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            customToast('올바른 이미지 파일을 선택해 주세요.');
            return;
        }

        let fileSize = file.size;
        let extension = file.name.split('.').pop();

        if (fileSize > 5 * 1024 * 1024) {
            customToast.error('이미지 크기는 최대 5MB까지만 허용됩니다.');
            return;
        }

        CustomBackdropController().showBackdrop();

        const fileRef = ref(firebaseStorage, `/assets/user_pages/${userPageContextValueHook?.userPage?.id}/profile_banners/${uuidv4()}.${extension}`);

        const uploadedUrl = await uploadBytes(fileRef, file).then(async snapshot => {
            return await getDownloadURL(snapshot.ref)
                .then(url => {
                    return url
                })
        });
        CustomBackdropController().hideBackdrop();

        if (uploadedUrl) {
            setTopBannerImageUrl(uploadedUrl);
        }
    }

    const handleSelectBannerDefault = () => {
        setTopBannerImageUrl('');
    }

    const handleSetDefaultColors = () => {
        setDomainNameColor('#000000');
        setDescriptionColor('#606060');
        setProfileBackgroundColor('#ffffff');
    }

    const handleReqChangeUserPageDesigns = async () => {

        let body = {
            id: userPageContextValueHook.userPage?.id,
            domainDesignType: domainDesignType,
            domainNameColor: domainNameColor,
            descriptionColor: descriptionColor,
            profileBackgroundColor: profileBackgroundColor,
            topBannerImageUrl: topBannerImageUrl,
        };

        let headers = {};

        CustomBackdropController().showBackdrop();
        const result = await apiHook.reqChangeUserPage_designs({ body, headers });
        CustomBackdropController().hideBackdrop();
        if (result?.content) {
            userPageContextActionsHook.onSetUserPage(result?.content);
            onRefreshPreviewViewer();
        }

    }

    return (
        <>
            <St.Container>
                <St.HeaderWrapper>
                    <div className='headerBox' onClick={() => setDropdownOpen(!dropdownOpen)}>
                        <div className='title'>프로필 디자인</div>
                        <div className='icon'>
                            <CustomRatioImage>
                                {dropdownOpen ?
                                    <CustomRatioImage.Image src='/assets/icons/expandLess_000000.svg' />
                                    :
                                    <CustomRatioImage.Image src='/assets/icons/expandMore_000000.svg' />
                                }
                            </CustomRatioImage>
                        </div>
                    </div>
                </St.HeaderWrapper>
                {dropdownOpen &&
                    <>
                        <St.TypeSelectorWrapper>
                            <CustomBlockButton
                                type='button'
                                className={`${!domainDesignType || domainDesignType === 'CLASSIC' ? 'active' : ''}`}
                                onClick={() => handleChangeDomainDesignType('CLASSIC')}
                            >
                                기본형
                            </CustomBlockButton>
                            <CustomBlockButton
                                type='button'
                                className={`${domainDesignType === 'BANNER' ? 'active' : ''}`}
                                onClick={() => handleChangeDomainDesignType('BANNER')}
                            >
                                배너형
                            </CustomBlockButton>
                        </St.TypeSelectorWrapper>
                        <St.DesignViewWrapper>
                            {(!domainDesignType || domainDesignType === 'CLASSIC') &&
                                <div className='classicType'>
                                    <div className='profileBox'>
                                        <div className='profileLogo'></div>
                                        <div className='domainName'>@도메인</div>
                                        <div className='description'></div>
                                        <div className='description'></div>
                                        <div className='description'></div>
                                    </div>
                                </div>
                            }
                            {(domainDesignType === 'BANNER') &&
                                <div className='bannerType'>
                                    <div className='bannerBox'>
                                        배너
                                    </div>
                                    <div className='profileBox'>
                                        <div className='profileLogo'></div>
                                        <div className='domainName'>@도메인</div>
                                        <div className='description'></div>
                                        <div className='description'></div>
                                        <div className='description'></div>
                                    </div>
                                </div>
                            }
                        </St.DesignViewWrapper>
                        <St.ColorSettingWrapper>
                            <h3 className='title'>
                                <span>색상 설정</span>
                                <CustomBlockButton
                                    type='button'
                                    onClick={() => handleSetDefaultColors()}
                                >색상 초기화</CustomBlockButton>
                            </h3>
                            <div className='body'>
                                <div className='colorItemBox'>
                                    <div className='name'>도메인명</div>
                                    <div className='representedColor' onClick={() => toggleDomainNameColorChangeModeOpen(true)}>
                                        <div className='colorBox' style={{ backgroundColor: domainNameColor }}></div>
                                        <div className='colorHex'>{domainNameColor || '#000000'}</div>
                                    </div>
                                    {domainNameColorChangeModeOpen &&
                                        <CustomColorPalette
                                            open={domainNameColorChangeModeOpen}
                                            fromColor={domainNameColor}
                                            toggleOpenClose={toggleDomainNameColorChangeModeOpen}
                                            onConfirm={handleChangeDomainNameColor}
                                        />
                                    }
                                </div>
                                <div className='colorItemBox'>
                                    <div className='name'>페이지 설명</div>
                                    <div className='representedColor' onClick={() => toggleDescriptionColorChangeModeOpen(true)}>
                                        <div className='colorBox' style={{ backgroundColor: descriptionColor }}></div>
                                        <div className='colorHex'>{descriptionColor || '#000000'}</div>
                                    </div>
                                    {descriptionColorChangeModeOpen &&
                                        <CustomColorPalette
                                            open={descriptionColorChangeModeOpen}
                                            fromColor={descriptionColor}
                                            toggleOpenClose={toggleDescriptionColorChangeModeOpen}
                                            onConfirm={handleChangeDescriptionColor}
                                        />
                                    }
                                </div>
                                {(domainDesignType === 'BANNER') &&
                                    <div className='colorItemBox'>
                                        <div className='name'>프로필 배경</div>
                                        <div className='representedColor' onClick={() => toggleProfileBackgroundColorChangeModeOpen(true)}>
                                            <div className='colorBox' style={{ backgroundColor: profileBackgroundColor }}></div>
                                            <div className='colorHex'>{profileBackgroundColor || '#000000'}</div>
                                        </div>
                                        {profileBackgroundColorChangeModeOpen &&
                                            <CustomColorPalette
                                                open={profileBackgroundColorChangeModeOpen}
                                                fromColor={descriptionColor}
                                                toggleOpenClose={toggleProfileBackgroundColorChangeModeOpen}
                                                onConfirm={handleChangeProfileBackgroundColor}
                                            />
                                        }
                                    </div>
                                }
                            </div>
                        </St.ColorSettingWrapper>
                        {(domainDesignType === 'BANNER') &&
                            <St.BannerSettingWrapper>
                                <h3 className='title'>배너 설정</h3>
                                <div className='body'>
                                    <div className='bannerImageBox'>
                                        <div className='bannerImage'>
                                            <CustomRatioImage width={375} height={240}>
                                                <CustomRatioImage.Image src={topBannerImageUrl || '/assets/images/profileBannerImage_default.jpg'} />
                                            </CustomRatioImage>
                                        </div>
                                    </div>
                                    <div className='bannerImageNoticeText'>권장 이미지 사이즈 750x480</div>
                                    <div className='handlerBox'>
                                        <div className='buttonGroupWrapper'>
                                            <CustomBlockButton
                                                type='button'
                                                className='first'
                                                onClick={() => handleImageUploaderOpen()}
                                            >배너 선택</CustomBlockButton>
                                            <CustomBlockButton
                                                type='button'
                                                className='second'
                                                onClick={() => handleSelectBannerDefault()}
                                            >기본 배너로 변경</CustomBlockButton>
                                        </div>
                                    </div>
                                </div>
                            </St.BannerSettingWrapper>
                        }
                        <St.FooterWrapper>
                            <CustomBlockButton
                                type='button'
                                onClick={() => handleReqChangeUserPageDesigns()}
                            >
                                적용
                            </CustomBlockButton>
                        </St.FooterWrapper>
                    </>
                }
            </St.Container>

            <input
                ref={imageUploaderRef}
                type="file"
                accept="image/*"
                onClick={(e) => e.target.value = ''}
                onChange={(e) => handleSelectBanner(e)}
                hidden
            />
        </>
    );
}