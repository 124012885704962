import { UserPagesApi } from "../../../../apis/UserPagesApi";
import { useErrorHandler } from "../../../../apis/useErrorHandler";
import { tokenRefreshBeforeRequest } from "../../../../firebase/auth";

const userPagesApi = UserPagesApi();

export function useApiHook(props) {
    const errorHandler = useErrorHandler();
    const reqIsExistedUserPageName = async ({ body, headers }) => {
        return await userPagesApi.isExistedSameDomain({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqCreateUserPage = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await userPagesApi.create({ body: body, headers: headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    return {
        reqIsExistedUserPageName,
        reqCreateUserPage
    };
}