import { useState } from 'react';
import { CustomRatioImage } from '../../../../../../../components/image/CustomRatioImage';
import * as St from './SgNoticeDesign.styled';
import CustomBlockButton from '../../../../../../../components/button/CustomBlockButton';
import { CustomColorPalette } from '../../../../../../../components/color-palette/CustomColorPalette';
import { useApiHook } from '../../../../hooks/useApiHook';
import { useUserPageContextActionsHook, useUserPageContextValueHook } from '../../../../../../../contexts/UserPageContextProvider';
import { BrandAwarenessIcon } from '../../../../../../../components/svg-icons/BrandAwarenessIcon';
import { CustomBackdropController } from '../../../../../../../components/backdrop/default/v1';

export function SgNoticeDesign({
    onRefreshPreviewViewer
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const noticeConfig = userPageContextValueHook?.userPage?.noticeConfig;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [backgroundColorHex, setBackgroundColorHex] = useState(noticeConfig?.backgroundColorHex || '#e5f6fd');
    const [textColorHex, setTextColorHex] = useState(noticeConfig?.textColorHex || '#014361')
    const [iconColorHex, setIconColorHex] = useState(noticeConfig?.iconColorHex || '#ef476f')

    const [backgroundColorHexPaletteOpen, setBackgroundColorHexPaletteOpen] = useState(false);
    const [textColorHexPaletteOpen, setTextColorHexPaletteOpen] = useState(false);
    const [iconColorHexPaletteOpen, setIconColorHexPaletteOpen] = useState(false);

    const handleReqChangeNoticeDesign = async (e) => {
        e.preventDefault();

        let body = {
            id: userPageContextValueHook?.userPage?.id,
            noticeConfig: {
                ...noticeConfig,
                backgroundColorHex: backgroundColorHex,
                textColorHex: textColorHex,
                iconColorHex: iconColorHex,
            }
        }

        CustomBackdropController().showBackdrop();
        const changeResult = await apiHook.reqChangeUserPage_noticeConfig({ body });

        if (changeResult?.content) {
            userPageContextActionsHook.onSetUserPage({ ...changeResult?.content });
            onRefreshPreviewViewer();
        }
        CustomBackdropController().hideBackdrop();
    }

    const toggleBackgroundColorHexPaletteOpen = (bool) => {
        setBackgroundColorHexPaletteOpen(bool);
    }

    const toggleTextColorHexPaletteOpen = (bool) => {
        setTextColorHexPaletteOpen(bool);
    }

    const toggleIconColorHexPaletteOpen = (bool) => {
        setIconColorHexPaletteOpen(bool);
    }

    const handleChangeBackgroundColorHex = (hex) => {
        setBackgroundColorHex(hex);
    }

    const handleChangeTextColorHex = (hex) => {
        setTextColorHex(hex);
    }

    const handleChangeIconColorHex = (hex) => {
        setIconColorHex(hex)
    }

    return (
        <>
            <St.Container>
                <St.HeaderWrapper>
                    <div className='headerBox' onClick={() => setDropdownOpen(!dropdownOpen)}>
                        <div className='title'>공지 디자인</div>
                        <div className='icon'>
                            <CustomRatioImage>
                                {dropdownOpen ?
                                    <CustomRatioImage.Image src='/assets/icons/expandLess_000000.svg' />
                                    :
                                    <CustomRatioImage.Image src='/assets/icons/expandMore_000000.svg' />
                                }
                            </CustomRatioImage>
                        </div>
                    </div>
                </St.HeaderWrapper>
                {dropdownOpen &&
                    <>
                        <St.ColorSelectorWrapper>
                            <div className='currentColorTitle'>배경 색상</div>
                            <div className='currentColor' onClick={() => toggleBackgroundColorHexPaletteOpen(true)}>
                                <div className='colorBox' style={{ backgroundColor: backgroundColorHex }}></div>
                                <div className='colorHex'>{backgroundColorHex}</div>
                            </div>
                            {backgroundColorHexPaletteOpen &&
                                <CustomColorPalette
                                    open={backgroundColorHexPaletteOpen}
                                    fromColor={backgroundColorHex}
                                    toggleOpenClose={toggleBackgroundColorHexPaletteOpen}
                                    onConfirm={handleChangeBackgroundColorHex}
                                />
                            }
                            <div className='sampleColorList'>
                                {CUSTOM_BACKGROUND_HEX_LIST?.map(customColor => {
                                    return (
                                        <div
                                            key={customColor?.hex}
                                            className='colorBox'
                                            style={{ backgroundColor: customColor?.hex }}
                                            onClick={() => handleChangeBackgroundColorHex(customColor?.hex)}
                                        ></div>
                                    );
                                })}
                            </div>
                        </St.ColorSelectorWrapper>
                        <St.ColorSelectorWrapper>
                            <div className='currentColorTitle'>텍스트 색상</div>
                            <div className='currentColor' onClick={() => toggleTextColorHexPaletteOpen(true)}>
                                <div className='colorBox' style={{ backgroundColor: textColorHex }}></div>
                                <div className='colorHex'>{textColorHex}</div>
                            </div>
                            {textColorHexPaletteOpen &&
                                <CustomColorPalette
                                    open={textColorHexPaletteOpen}
                                    fromColor={textColorHex}
                                    toggleOpenClose={toggleTextColorHexPaletteOpen}
                                    onConfirm={handleChangeTextColorHex}
                                />
                            }
                            <div className='sampleColorList'>
                                {CUSTOM_TEXTCOLOR_HEX_LIST?.map(customColor => {
                                    return (
                                        <div
                                            key={customColor?.hex}
                                            className='colorBox'
                                            style={{ backgroundColor: customColor?.hex }}
                                            onClick={() => handleChangeTextColorHex(customColor?.hex)}
                                        ></div>
                                    );
                                })}
                            </div>
                        </St.ColorSelectorWrapper>
                        <St.ColorSelectorWrapper>
                            <div className='currentColorTitle'>아이콘 색상</div>
                            <div className='currentColor' onClick={() => toggleIconColorHexPaletteOpen(true)}>
                                <div className='colorBox' style={{ backgroundColor: iconColorHex }}></div>
                                <div className='colorHex'>{iconColorHex}</div>
                            </div>
                            {iconColorHexPaletteOpen &&
                                <CustomColorPalette
                                    open={iconColorHexPaletteOpen}
                                    fromColor={iconColorHex}
                                    toggleOpenClose={toggleIconColorHexPaletteOpen}
                                    onConfirm={handleChangeIconColorHex}
                                />
                            }
                            <div className='sampleColorList'>
                                {CUSTOM_ICONCOLOR_HEX_LIST?.map(customColor => {
                                    return (
                                        <div
                                            key={customColor?.hex}
                                            className='colorBox'
                                            style={{ backgroundColor: customColor?.hex }}
                                            onClick={() => handleChangeIconColorHex(customColor?.hex)}
                                        ></div>
                                    );
                                })}
                            </div>
                        </St.ColorSelectorWrapper>
                        <St.ResultWrapper>
                            <div className='title'>결과 예시</div>
                            <div className='background' style={{ background: backgroundColorHex }}>
                                <BrandAwarenessIcon width={24} height={24} fill={iconColorHex} />
                                <div className='text' style={{ color: textColorHex }}>
                                    공지사항에 대한 예시 글귀이며, 공지사항은 이렇게 표시됩니다.
                                </div>
                            </div>
                        </St.ResultWrapper>
                        <St.FooterWrapper>
                            <CustomBlockButton
                                type='button'
                                onClick={(e) => handleReqChangeNoticeDesign(e)}
                            >
                                적용
                            </CustomBlockButton>
                        </St.FooterWrapper>
                    </>
                }
            </St.Container>
        </>
    );
}

const CUSTOM_BACKGROUND_HEX_LIST = [
    {
        hex: '#e5f6fd',
    },
    {
        hex: '#fdf1e5',
    },
    {
        hex: '#f6fde5',
    },
    {
        hex: '#fde5f6',
    },
    {
        hex: '#e5fdf6',
    },
    {
        hex: '#f6e5fd',
    },
]

const CUSTOM_TEXTCOLOR_HEX_LIST = [
    {
        hex: '#014361',
    },
    {
        hex: '#613701',
    },
    {
        hex: '#3a6101',
    },
    {
        hex: '#610134',
    },
    {
        hex: '#015f61',
    },
    {
        hex: '#61015b',
    },
]

const CUSTOM_ICONCOLOR_HEX_LIST = [
    {
        hex: '#ef476f',
    },
    {
        hex: '#f78c6b',
    },
    {
        hex: '#f4a261',
    },
    {
        hex: '#06d6a0',
    },
    {
        hex: '#ffd166',
    },
    {
        hex: '#118ab2',
    },
]