import styled from 'styled-components';

export const Container = styled.div`
    height: 100vh;
    /* background-image: ${props => props?.$backgroundImageUrl ? `url(${props?.$backgroundImageUrl})` : ''};
    background-repeat: no-repeat;    
    background-size:cover; */
    overflow-y:scroll;
    position:relative;
    
    &::-webkit-scrollbar {
        width: 0px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #505050;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
    }
`;

export const Wrapper = styled.div`
    padding: 0 0 10vh 0;
    padding-bottom: 100px;
`;

export const HeaderWrapper = styled.div`
    margin-top: 60px;
    width: 50vw;
    /* height: auto; */
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .profileImageWrapper{
        width: 60%;
        /* height: auto; */
        margin-left: auto;
        margin-right: auto;
        border-radius: 100%;
        overflow: hidden;

        .imageLoading{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }
    }

    .domainNameWrapper{
        text-align: center;
        font-weight: 800;
        font-size: 16px;
        word-wrap: break-word;

        .fakeBox{
            height: 24px;
            border-radius: 10px;
        }
    }

    .descriptionWrapper{
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #606060;

        word-wrap: break-word;
        word-break: keep-all;
        white-space: pre-line;

        .fakeBox{
            height: 100px;
            border-radius: 10px;
        }
    }
`;

export const LinkItemsContainer = styled.div`
    margin-top: 40px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    box-sizing: border-box;
`;

export const LinkItemsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;

    .linkItemWrapper{
        border-radius: 15px;
        display: flex;
        align-items: center;
        gap: 10px;
        background-color: #fff;
        box-sizing: border-box;
        width: 100%;
        overflow: hidden;
        min-height: 84px;
        justify-content: space-between;
        transition: all .3s;
        
        @media all and (max-width:576px){
            min-height: 72px;
        }

        .fakeBox{
            height: 84px;
            transition: all .3s;

            @media all and (max-width:576px){
                height: 72px;
            }
        }

    }
`;