import styled from 'styled-components';

export const Container = styled.div`
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    box-sizing: border-box;
`;

export const Wrapper = styled.div`
    select{
        border-radius: 15px;
        font-weight: 600;
        padding: 0 20px;
        height: 50px;
        border: none;
        box-shadow: var(--defaultBoxShadow);
    }
`;