import styled from 'styled-components';

const LineBreaker = styled.div`
    display: flex;
    justify-content: ${props => props.$align};
    align-items: center;
    height: 32px;
`;

export function SolidLineBreaker({
    fill,
    width,
    height,
    lineStyle,
    align // center, left, right
}) {
    return (
        <LineBreaker
            $align={align || 'center'}
        >
            <div
                style={{
                    width: width || '100%',
                    borderTop: `${height ?? '1px'} ${lineStyle || 'solid'} ${fill || '#000000'}`
                }}
            ></div>
        </LineBreaker>
    );
}