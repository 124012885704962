import { HorizontalDottedBreaker } from "../components/line-breaker/HorizontalDottedBreaker";
import { SolidLineBreaker } from "../components/line-breaker/SolidLineBreaker";
import { VerticalDottedBreaker } from "../components/line-breaker/VerticalDottedBreaker";
import { ZigzagBreaker } from "../components/line-breaker/ZigzagBreaker";

export const GlobalSeparatorVariables = {
    list: [
        {
            separator: 'SPACE',
            name: '공백',
            Component: ({ fill }) => <SolidLineBreaker fill={fill || '#808080'} height={0} lineStyle={'solid'} />
        },
        {
            separator: 'HORIZONTAL_DOTTED',
            name: '포인트1',
            Component: ({ fill }) => <HorizontalDottedBreaker fill={fill || '#808080'} />
        },
        {
            separator: 'VERTICAL_DOTTED',
            name: '포인트2',
            Component: ({ fill }) => <VerticalDottedBreaker fill={fill || '#808080'} />
        },
        {
            separator: 'ZIGZAG',
            name: '지그재그',
            Component: ({ fill }) => <ZigzagBreaker fill={fill || '#808080'} />
        },
        {
            separator: 'SHORT_SOLID_LINE',
            name: '짧은 실선',
            Component: ({ fill }) => <SolidLineBreaker fill={fill || '#808080'} lineStyle={'solid'} height={'2.5px'} width={'100px'} />
        },
        {
            separator: 'SHORT_DASHED_LINE',
            name: '짧은 점선',
            Component: ({ fill }) => <SolidLineBreaker fill={fill || '#808080'} lineStyle={'dashed'} height={'2.5px'} width={'100px'} />
        },
        {
            separator: 'SOLID_LINE',
            name: '실선',
            Component: ({ fill }) => <SolidLineBreaker fill={fill || '#808080'} lineStyle={'solid'} />
        },
        {
            separator: 'DASHED_LINE',
            name: '점선',
            Component: ({ fill }) => <SolidLineBreaker fill={fill || '#808080'} lineStyle={'dashed'} />
        },
    ]
}