import axios from "axios"

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function LinkItemsApi(props) {
    return {
        searchListByOwner: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/link-items/user-pages/${params?.userPageId}/owner`, {
                params: {
                    searchQuery: params?.searchQuery,
                    lcId: params?.lcId,
                    offset: params?.offset,
                    limit: params?.limit
                },
                headers: headers
            })
        },
        searchListByGuest: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/link-items/user-pages/${params?.userPageId}/guest`, {
                params: {
                    searchQuery: params?.searchQuery,
                    lcId: params?.lcId,
                    offset: params?.offset,
                    limit: params?.limit
                },
                headers: headers
            })
        },
        create: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/link-items/create`, body, {
                headers: headers
            })
        },
        delete: async ({ body, headers }) => {
            return await axios.delete(`${mainApiHost}/link-items`, {
                data: body,
                headers: headers
            })
        },
        updateInfo: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/link-items/update/info`, body, {
                headers: headers,
            })
        },
        updateIsHide: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/link-items/update/isHide`, body, {
                headers: headers,
            })
        },
        changeList_orderNumber: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/link-items/list/orderNumber`, body, {
                headers: headers,
            })
        },
    }
}