import { createContext, useContext, useMemo, useState } from "react";

const UserPageValueContext = createContext();
const UserPageActionsContext = createContext();

export function UserPageContextProvider({ children }) {
    const [value, setValue] = useState({
        userPage: null,
        isLoading: true
    });

    const actions = useMemo(
        () => {
            return {
                onSetUserPage(userPage) {
                    setValue({
                        userPage: userPage,
                        isLoading: false
                    });
                },
                onSetIsLoading(bool) {
                    setValue({
                        ...value,
                        isLoading: bool
                    });
                },
                onClear() {
                    setValue({
                        userPage: null,
                        isLoading: false
                    })
                }
            }
        },
        []
    )
    
    return (
        <>
            <UserPageActionsContext.Provider value={actions}>
                <UserPageValueContext.Provider value={value}>
                    {children}
                </UserPageValueContext.Provider>
            </UserPageActionsContext.Provider>
        </>
    );
}

export function useUserPageContextValueHook(props) {
    const value = useContext(UserPageValueContext);

    if (value === undefined) {
        throw new Error('useUserPageContextValueHook should be used within UserPageValueContext');
    }

    return {
        userPage: value?.userPage,
        isLoading: value?.isLoading
    };
}

export function useUserPageContextActionsHook(props) {
    const value = useContext(UserPageActionsContext);

    if (value === undefined) {
        throw new Error('useUserPageContextActionsHook should be used within UserPageActionsContext');
    }

    return {
        onSetUserPage: value.onSetUserPage,
        onSetIsLoading: value.onSetIsLoading,
        onClear: value.onClear
    };
}