import { useEffect } from "react";
import { useUserContextActionsHook } from "../contexts/UserContextProvider";
import { onAuthStateChanged } from "firebase/auth";
import firebaseAuth from '../firebase/auth';

export function UserContextFetcher(props) {
    const userContextActionsHook = useUserContextActionsHook();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(firebaseAuth, user => {
            if (user && user?.emailVerified) {
                userContextActionsHook.onSetUser(user.toJSON());
            } else {
                userContextActionsHook.onClear();
                console.log('로그아웃 상태')
            }
        })

        return () => {
            unsubscribe();
        };
    }, [firebaseAuth]);

    return (<></>);
}