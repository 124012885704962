import { CustomDialog } from "../dialog/v1/CustomDialog";
import * as St from './NoticeModal.styled';
import { CustomRatioImage } from "../image/CustomRatioImage";
import CustomBlockButton from "../button/CustomBlockButton";
import { useState } from "react";

export function NoticeModal({
    open,
    noticeItemList,
    onClose,
    onCloseToday
}) {
    const [selectedNoticeItemIdx, setSelectedNoticeItemIdx] = useState(0);

    const handleChangeSelectedNoticeItemIdx = (index) => {
        setSelectedNoticeItemIdx(index);
    }

    return (
        <>
            <CustomDialog open={open} onClose={() => onClose()}>
                <St.TabWrapper>
                    <div className='tabList'>
                        {noticeItemList?.map((noticeItem, index) => {
                            return (
                                <CustomBlockButton
                                    key={noticeItem?.id}
                                    type='button'
                                    className={`${selectedNoticeItemIdx === index ? 'active' : ''}`}
                                    onClick={() => handleChangeSelectedNoticeItemIdx(index)}
                                >
                                    {index + 1}
                                </CustomBlockButton>
                            );
                        })}
                    </div>
                </St.TabWrapper>
                <St.BodyWrapper>
                    <St.ThumbnailImage>
                        <div className='imageBox'>
                            <CustomRatioImage width={3} height={4}>
                                <CustomRatioImage.Image src={noticeItemList[selectedNoticeItemIdx]?.imageUrl} alt={noticeItemList[selectedNoticeItemIdx]?.alt} />
                            </CustomRatioImage>
                        </div>
                    </St.ThumbnailImage>
                </St.BodyWrapper>
                <St.FooterWrapper>
                    <div className='closeToday'>
                        <input
                            type='checkbox'
                            onClick={() => onCloseToday()}
                        ></input>
                        <label
                            onClick={() => onCloseToday()}
                        >
                            오늘 그만보기
                        </label>
                    </div>
                    <CustomBlockButton
                        type='button'
                        className='closeButton'
                        onClick={() => onClose()}
                    >
                        닫기
                    </CustomBlockButton>
                </St.FooterWrapper>
            </CustomDialog>
        </>
    );
}