import * as St from './FdDesignSetting.styled';
import { SgBackgroundDesign } from './segments/SgBackgroundDesign/SgBackgroundDesign';
import { SgNoticeDesign } from './segments/SgNoticeDesign/SgNoticeDesign';
import { SgProfileDesign } from './segments/SgProfileDesign/SgProfileDesign';
import { SgSocialDesign } from './segments/SgSocialDesign/SgSocialDesign';

export function FdDesignSetting({
    onRefreshPreviewViewer
}) {
    return (
        <>
            <St.Container>
                <St.FragmentListWrapper>
                    <St.DropdownFieldWrapper>
                        <SgProfileDesign
                            onRefreshPreviewViewer={onRefreshPreviewViewer}
                        />
                    </St.DropdownFieldWrapper>
                    <St.DropdownFieldWrapper>
                        <SgBackgroundDesign
                            onRefreshPreviewViewer={onRefreshPreviewViewer}
                        />
                    </St.DropdownFieldWrapper>
                    <St.DropdownFieldWrapper>
                        <SgNoticeDesign
                            onRefreshPreviewViewer={onRefreshPreviewViewer}
                        />
                    </St.DropdownFieldWrapper>
                    <St.DropdownFieldWrapper>
                        <SgSocialDesign
                            onRefreshPreviewViewer={onRefreshPreviewViewer}
                        />
                    </St.DropdownFieldWrapper>
                </St.FragmentListWrapper>
            </St.Container>
        </>
    );
}