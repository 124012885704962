import { useEffect } from "react";
import { useUserContextValueHook } from "../contexts/UserContextProvider";
import { useUserPageContextActionsHook } from "../contexts/UserPageContextProvider";
import { UserPagesApi } from "../apis/UserPagesApi";
import { tokenRefreshBeforeRequest } from "../firebase/auth";
import { useErrorHandler } from "../apis/useErrorHandler";

const userPagesApi = UserPagesApi();

export function UserPageContextFetcher(props) {
    const userContextValueHook = useUserContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();
    const errorHandler = useErrorHandler();

    const userUid = userContextValueHook?.user?.uid;

    useEffect(() => {
        handleFetchUserPage();
    }, [userUid, userContextValueHook?.isLoading]);

    const handleFetchUserPage = async () => {
        if (userContextValueHook?.isLoading) {
            return;
        }

        if (!userUid) {
            userPageContextActionsHook.onSetIsLoading(false);
            return;
        }

        const accessToken = await tokenRefreshBeforeRequest();

        let headers = {
            Authorization: `Bearer ${accessToken}`
        }

        await userPagesApi.searchByAuthUser({ headers })
            .then(res => {
                userPageContextActionsHook.onSetUserPage(res?.data?.content);
            })
            .catch(err => {
                errorHandler.run(err);
            });


    }

    return (<></>);
}