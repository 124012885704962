import styled, { css } from 'styled-components';

// export const Container = styled.div`
//     height: 100vh;
//     ${props => props.$backgroundType === 'IMAGE' ? css`
//             background-image: ${props?.$backgroundImageUrl ? `url(${props?.$backgroundImageUrl})` : ''};
//             background-repeat: no-repeat;    
//             background-size:cover;
//         `
//         :
//         css`
//             background: ${props.$backgroundColorHex};
//         `
//     }
//     overflow-y:auto;
//     position:relative;

//     &::-webkit-scrollbar {
//         width: 5px;
//     }

//     &::-webkit-scrollbar-thumb {
//         background-color: #b0b0b0;
//         border-radius: 10px;
//     }
//     &::-webkit-scrollbar-track {
//         background-color: #f0f0f0;
//     }

//     @media all and (max-width:768px){
//         &::-webkit-scrollbar {
//             width: 0px;
//         }
//     }
// `;

export const Container = styled.div`
    height: auto;
    min-height: 100vh;
    overflow: hidden;
    
    ${props => props.$backgroundType === 'IMAGE' ? css`
            background-image: ${props?.$backgroundImageUrl ? `url(${props?.$backgroundImageUrl})` : ''};
            background-repeat: no-repeat;    
            background-size:cover;
        `
        :
        css`
            background: ${props.$backgroundColorHex};
        `
    }

    
    position:relative;
    /* overflow-y:auto;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b0b0b0;
        border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
        background-color: #f0f0f0;
    }

    @media all and (max-width:768px){
        &::-webkit-scrollbar {
            width: 0px;
        }
    } */
`;

export const Wrapper = styled.div`
    padding: 0 0 10vh 0;
    padding-bottom: 100px;
`;

export const BannerWrapper = styled.div`
    height: 240px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    img{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`;

export const HeaderWrapper = styled.div`
    background-color: #ffffff;
    /* background-image: ${props => props?.$backgroundImageUrl ? `url(${props?.$backgroundImageUrl})` : ''};
    background-repeat: no-repeat;    
    background-size:cover; */
    margin-top: -20px;
    /* margin-top: 40px; */
    /* margin-top: 60px; */
    /* border:1px solid #f0f0f0; */
    box-shadow:var(--defaultBoxShadow2);
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 25px;
    border-top-right-radius:20px;
    border-top-left-radius:20px;
    z-index: 20;
    position: relative;
    padding: 20px 10px;
    box-sizing: border-box;

    .profileImageWrapper{
        width: 80px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 100%;
        overflow: hidden;
    }

    .domainNameWrapper{
        text-align: center;
        font-weight: 800;
        font-size: 16px;
        word-wrap: break-word;
    }

    .descriptionWrapper{
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #606060;

        word-wrap: break-word;
        word-break: keep-all;
        white-space: pre-line;
    }
`;