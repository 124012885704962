export const DefinedVariable = {
    DEFINED_REFERRER_LIST: [
        {
            referrer: 'direct',
            convertedName: 'direct',
            color: '#a0a0a0'
        },
        {
            referrer: 'https://l.instagram.com/',
            convertedName: 'Instagram',
            // color: '#dd2a7b'
            color: '#f58ab6'
        },
        {
            referrer: 'https://l.facebook.com/',
            convertedName: 'Facebook',
            color: '#1877e2'
            // color: '#add8e6'
        },
        {
            referrer: 'https://www.youtube.com/',
            convertedName: 'Youtube',
            color: '#ff0000'
            // color: '#ffb6c1'
        },
        {
            referrer: 'https://www.tiktok.com/',
            convertedName: 'Tiktok',
            // color: '#69C9D0'
            color: '#a1e5e8'
        },
        {
            referrer: 'https://www.google.com/',
            convertedName: 'Google',
            color: '#edede9'
        }
    ]
}