import { useNavigate } from 'react-router-dom';
import CustomBlockButton from '../../../../components/button/CustomBlockButton';
import { CustomInput } from '../../../../components/input/CustomInput';
import { useUserContextValueHook } from '../../../../contexts/UserContextProvider';
import { useUserPageContextActionsHook, useUserPageContextValueHook } from '../../../../contexts/UserPageContextProvider';
import * as St from './FdIntroduction.styled';
import { customToast } from '../../../../components/toast/custom-react-toastify/v1';
import { useState } from 'react';
import { CustomBackdropController } from '../../../../components/backdrop/default/v1';
import { useApiHook } from '../../hooks/useApiHook';

const customBackdropController = CustomBackdropController();

export function FdIntroduction(props) {
    const navigate = useNavigate();

    const userContextValueHook = useUserContextValueHook();
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const [domainName, setDomainName] = useState('');

    const handleChangeDomainName = (e) => {
        const value = e.target.value;

        setDomainName(value);
    }

    const handleReqCreate = async (e) => {
        e.preventDefault();

        if (!userContextValueHook?.user) {
            navigate({
                pathname: '/login'
            });
            return;
        }

        if (userPageContextValueHook?.userPage) {
            customToast.warning('이미 생성된 마이 페이지가 있습니다.');
            return;
        }

        let regex = /^[a-z_0-9]{3,20}$/
        if (!regex.test(domainName)) {
            alert('3~20자 내외 영문 소문자[a-z], 숫자[0-9], underscore[_] 특수문자만 허용 됩니다.');
            return;
        }

        let body = {
            domainName: domainName
        }

        customBackdropController.showBackdrop()
        const createResult = await apiHook.reqCreateUserPage({ body: body })
        customBackdropController.hideBackdrop()

        if (createResult?.content) {
            userPageContextActionsHook.onSetUserPage({
                ...createResult?.content
            })
            customToast.success('마이 페이지가 생성되었습니다!!')
            navigate(`/${createResult?.content?.id}/mypage`, { replace: true });
        }
    }

    return (
        <>
            <St.Container>
                <St.CreateBannerWrapper>
                    <div className='title'>
                        마이 페이지를 생성하고<br />여러 링크를 한번에 공유해 보세요.
                    </div>
                    <form className='formField' onSubmit={(e) => handleReqCreate(e)}>
                        <CustomInput
                            type='text'
                            placeholder='도메인명'
                            value={domainName || ''}
                            onChange={(e) => handleChangeDomainName(e)}
                        />
                        <CustomBlockButton
                            type='submit'
                        >
                            Create
                        </CustomBlockButton>
                    </form>
                </St.CreateBannerWrapper>
                <St.Section2>
                    <div className='title'>
                        페이지가 아직 준비중 입니다.<br />🥲🥲🥲
                    </div>
                </St.Section2>
            </St.Container>
        </>
    );
}