import styled from 'styled-components';

export const Container = styled.div`
`;

export const CreateBannerWrapper = styled.div`
    background: linear-gradient(to right bottom,#EE9CA7, #FFCCFF);
    padding: 100px 80px;
    min-height: 500px;
    @media all and (max-width:768px){
        padding: 80px 20px;
    }

    .title{
        font-size: 32px;
        font-weight: 700;
        text-align: right;
        word-break: keep-all;

        @media all and (max-width:768px){
            font-size: 24px;
        }
    }

    .formField{
        margin-top: 60px;
        display: flex;
        flex-direction: row;
        gap: 20px;

        @media all and (max-width:768px){
            margin-top: 40px;
            flex-direction: column;
        }
        
        input{
            height: 80px;
            border-radius: 100vw;
            font-size: 20px;
            padding: 0 20px;
            border: 2px solid #00000000;
            box-shadow: var(--defaultBoxShadow2);
            transition: all .3s;
            font-weight: 700;
            background-color: #ffffffd0;

            &::placeholder{
                color: #a0a0a0;
            }

            &:focus{
                border-color: #000000;
                background-color: #ffffff;
            }

            @media all and (max-width:768px){
                height: 60px;
                font-size: 18px;
                width: 100%;
            }
        }

        button{
            height: 80px;
            width: 200px;
            border-radius: 100vw;
            background: #000000d0;
            border: 2px solid #00000000;
            font-size: 24px;
            font-weight: 700;
            color: #fff;

            &:hover{
                background-color: #000;
            }

            @media all and (max-width:768px){
                height: 60px;
                font-size: 18px;
                width: 100%;
            }
        }
    }
`;

export const Section2 = styled.div`
    background: linear-gradient(to right bottom,#0096BC, #DFF8D6);
    padding: 100px 80px;
    min-height: 500px;

    @media all and (max-width:768px){
        padding: 80px 20px;
    }

    .title{
        margin-top: 100px;
        font-size: 22px;
        font-weight: 700;
        text-align: center;
        word-break: keep-all;
        color: #fff;

        @media all and (max-width:768px){
            font-size: 24px;
        }
    }
`;

export const Wrapper = styled.div`

`;