import { Link } from 'react-router-dom';
import { CustomRatioImage } from '../../../../../components/image/CustomRatioImage';
import * as St from './FdLinkItems.styled';
import CustomBlockButton from '../../../../../components/button/CustomBlockButton';
import { useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import { GlobalSeparatorVariables } from '../../../../../variables/GlobalSeparatorVariables';
import { ZigzagBreaker } from '../../../../../components/line-breaker/ZigzagBreaker';

export function FdLinkItems({
    linkItemList,
    hasMore,
    onReqFetchLinkItemList
}) {
    const [moreDisabled, setMoreDisabled] = useState(true);

    useEffect(() => {
        setMoreDisabled(false);
    }, [linkItemList]);

    const handleMoreItems = async () => {
        setMoreDisabled(true);
        await onReqFetchLinkItemList();
    }

    return (
        <>
            <St.Container>
                <St.ModuleItems.Container>
                    {linkItemList?.map(module => {
                        const moduleType = module?.type || 'LINK';

                        if (moduleType === 'LINK') {
                            return (
                                <St.ModuleItems.LinkItem key={module?.id}>
                                    <Link
                                        to={module?.linkUrl}
                                        target='_blank'
                                    >
                                        <div className='linkItemWrapper'>
                                            {module?.thumbnailUrl &&
                                                <section className='first'>
                                                    <div className='thumbnailBox'>
                                                        <CustomRatioImage>
                                                            <CustomRatioImage.Image src={module?.thumbnailUrl} />
                                                        </CustomRatioImage>
                                                    </div>
                                                </section>
                                            }
                                            <section className='second' style={!module?.thumbnailUrl ? { textAlign: 'center' } : {}}>
                                                <div className='title'>
                                                    {module?.title}
                                                </div>
                                                {module?.description &&
                                                    <div className='description'>
                                                        {module?.description}
                                                    </div>
                                                }
                                            </section>
                                            {/* <section className='third'>
                                                <div className='iconBox'>
                                                    <CustomRatioImage>
                                                        <CustomRatioImage.Image src='/assets/images/externalLink.png' />
                                                    </CustomRatioImage>
                                                </div>
                                            </section> */}
                                        </div>
                                    </Link>
                                </St.ModuleItems.LinkItem>
                            );
                        }

                        if (moduleType === 'TEXT') {
                            return (
                                <St.ModuleItems.TextItem key={module?.id}>
                                    {module.textConfig?.text}
                                </St.ModuleItems.TextItem>
                            );
                        }

                        if (moduleType === 'SEPARATOR') {
                            const separatorConfig = module?.separatorConfig;
                            const Separator = GlobalSeparatorVariables.list.find(r => r.separator === separatorConfig?.separator);
                            return (
                                <St.ModuleItems.SeparatorItem key={module?.id}>
                                    <Separator.Component />
                                </St.ModuleItems.SeparatorItem>
                            );
                        }
                    })}
                    <St.ModuleItems.Footer>
                        {hasMore ?
                            <>
                                {moreDisabled ?
                                    <div className='moreLoadingBox'>
                                        <CircularProgress className='progress' size={30} />
                                    </div>
                                    :
                                    <CustomBlockButton
                                        type='button'
                                        className='moreButton'
                                        onClick={() => handleMoreItems()}
                                        disabled={moreDisabled}
                                    >
                                        <div className='icon'>
                                            <CustomRatioImage>
                                                <CustomRatioImage.Image src='/assets/icons/add_default_000000.svg' />
                                            </CustomRatioImage>
                                        </div>
                                    </CustomBlockButton>
                                }
                            </>
                            :
                            <div className='lastContent'>
                                마지막 콘텐츠 입니다.
                            </div>
                        }
                    </St.ModuleItems.Footer>
                </St.ModuleItems.Container>
            </St.Container>
        </>
    );
}