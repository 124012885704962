import styled from 'styled-components';

export const Container = styled.div`
    
`;

export const PageProfileWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: 1px solid #e0e0e0;
    padding: 20px;
    border-radius: 15px;

    .designButtonBox{
        width: auto;
        height: auto;

        button{
            width: auto;
            height: 30px;
            background: #000;
            color:#fff;
            border-radius: 10px;
            padding: 0 16px;
            font-weight: 700;
            border: none;
            font-size: 14px;
        }
    }

    .layout{
        display: flex;
        flex-direction: row;
        gap: 20px;
        align-items: center;

        @media all and (max-width:992px){
            flex-direction: column;
        }

        .layout__profileImageBox{
            width: 150px;
            border-radius: 50%;
            overflow: hidden;
            border: 1px solid #e0e0e0;
            cursor: pointer;
        }

        .layout__informationWrapper{
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex:1;

            @media all and (max-width:992px){
                width: 100%;
            }

            .layout__informationWrapper__domainNameBox{
                display: flex;
                flex-direction: column;
                gap: 5px;

                .layout__informationWrapper__domainNameBox__label{
                    font-size: 12px;
                    font-weight: 700;
                }

                .layout__informationWrapper__domainNameBox__text{
                    font-size: 16px;
                    font-weight: 700;
                    border: 2px solid #00000000;
                    background-color: var(--defaultElementBC);

                    &:focus{
                        border-color: #000;
                    }
                }
            }

            .layout__informationWrapper__descriptionBox{
                display: flex;
                flex-direction: column;
                gap: 5px;
                .layout__informationWrapper__descriptionBox__label{
                    font-size: 12px;
                    font-weight: 700;
                }
                .layout__informationWrapper__descriptionBox__text{
                    font-size: 14px;
                    font-weight: 700;
                    height: 100px;
                    resize: none;
                    padding: 10px;
                    box-sizing: border-box;
                    background-color: var(--defaultElementBC);
                    border-radius: 10px;
                    border: 2px solid #00000000;

                    &:focus{
                        outline: none;
                        border-color: #000;
                    }
                }
            }
        }
    }

    .buttonGroup{
        display: flex;
        justify-content: flex-end;
        gap: 10px;

        button{
            width: auto;
            padding: 0 16px;
            border-radius: 10px;
            font-weight: 700;
            &:disabled{
                opacity: 0.6;
            }
        }

        button.save{
            background: #000;
            color: #fff;
            border: 1px solid #00000000;

        }

        button.initialize{
            background: var(--defaultElementBC);
            color: #000;
            border: 1px solid #00000000;
        }
    }
`;

export const ImageModalContainer = styled.div`
    padding: 40px 20px;

    .wrapper{
        display: flex;
        flex-direction: column;
        gap: 20px;

        button{
            border-radius: 10px;
            border: none;
        }

        button.selectCustom{
            background: #000;
            color:#fff;
            font-weight: 700;

            &:hover{
                background: #303030;
            }
        }

        button.selectDefault{
            background: var(--defaultElementBC);
            color:#444;
            font-weight: 700;

            &:hover{
                background: #d0d0d0;
            }
        }
    }
`;

export const MdEditDesignModal = {
    BodyContainer: styled.div`
        padding: 20px;

        .itemList{
            display: flex;
            flex-direction: column;
            gap: 10px;
        }

        .itemTypeBox{
            border: 1px solid #e0e0e0;
            border-radius: 15px;
            padding: 10px;
            cursor: pointer;

            &.active{
                border-color: #000;
            }

            .header{
                margin-bottom: 10px;
                display: flex;
                align-items: center;
                gap: 10px;

                .title{
                    font-size: 18px;
                    font-weight: 600;
                }

                .checked{
                    width: 20px;
                    height: 20px;
                }
            }

            .classicType{
                .notice{
                    display: flex;
                    align-items: center;
                    width: 53%;
                    background: #e5f6fd;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 10px;
                    font-weight: 600;
                    margin-bottom: 10px;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    border:1px solid #e5f6fd;

                    @media all and (max-width:576px){
                        width: 83%;
                    }

                    .logo{
                        width: 15px;
                        height: 15px;
                    }
                }

                .profileLogo{
                    width: 30px;
                    height: 30px;
                    background: #e0e0e0;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 50%;
                }

                .domainName{
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 10px;
                    text-align: center;
                    margin-top: 5px;
                    font-weight: 600;
                }

                .description{
                    margin-top: 5px;
                    width: 30%;
                    height: 10px;
                    background: #e0e0e0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }

            .bannerType{
                .notice{
                    display: flex;
                    align-items: center;
                    width: 60%;
                    background: #e5f6fd;
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 10px;
                    font-weight: 600;
                    padding: 5px 10px;
                    box-sizing: border-box;
                    border:1px solid #e5f6fd;

                    @media all and (max-width:576px){
                        width: 90%;
                    }

                    .logo{
                        width: 15px;
                        height: 15px;
                    }
                }

                .bannerBox{
                    border:1px solid #e0e0e0;
                    height: 80px;
                    width: 60%;
                    margin-left: auto;
                    margin-right: auto;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    background: #e0e0e0;
                    font-size: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;
                    box-sizing: border-box;

                    @media all and (max-width:576px){
                        width: 90%;
                    }
                }

                .profileBox{
                    margin-top: -10px;
                    border: 1px solid #f0f0f0;
                    width: 53%;
                    margin-left: auto;
                    margin-right: auto;
                    padding: 5px;
                    border-radius: 10px;
                    background: #fff;
                    box-sizing: border-box;
                    @media all and (max-width:576px){
                        width: 83%;
                    }
                }

                .profileLogo{
                    width: 30px;
                    height: 30px;
                    background: #e0e0e0;
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 50%;
                }

                .domainName{
                    margin-left: auto;
                    margin-right: auto;
                    font-size: 10px;
                    text-align: center;
                    margin-top: 5px;
                    font-weight: 600;
                }

                .description{
                    margin-top: 5px;
                    width: 50%;
                    height: 10px;
                    background: #e0e0e0;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        .colorSetting{
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .defaultButton{
                font-size: 12px;
                font-weight: 700;
                background: #efefef;
                padding: 8px 16px;
                border-radius: 10px;
                color: #444;
                height: auto;
                width: fit-content;
                border: none;
            }

            .domainName{
                display: flex;
                align-items: center;
                gap: 10px;
                .title{
                    font-size: 12px;
                    font-weight: 600;
                    width: 60px;
                }

                .representedColor{
                    cursor: pointer;
                    display: flex;
                    gap: 5px;
                    justify-content: flex-start;
                    width: fit-content;
                    padding: 5px;
                    align-items: center;
                    box-sizing: border-box;

                    .colorBox{
                        width: 20px;
                        height: 20px;
                        border: 1px solid #f0f0f0;
                    }

                    .colorHex{
                        font-size: 12px;
                        font-weight: 700;
                    }
                }
            }
        }

        .bannerSettingWrapper{
            margin-top: 10px;
            border: 1px solid #f0f0f0;
            border-radius: 10px;
            overflow: hidden;
            .header{
                background-color: var(--defaultElementBC);
                padding: 0 10px;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                /* border-top-left-radius: 10px;
                border-top-right-radius: 10px; */

                .title{
                    font-size: 12px;
                    font-weight: 600;
                }

                .icon{
                    width: 24px;
                    height: 24px;
                }
            }

            .body{
                padding: 10px;
                .flexBox{
                    display:flex;
                    align-items: center;
                    gap: 10px;
                }

                .bannerImage{
                    width: 60%;
                    border: 1px solid #f0f0f0;
                    border-radius: 10px;
                    overflow: hidden;
                }

                .bannerImageNoticeText{
                    flex: 1;
                    font-size: 10px;
                    text-align: center;
                    word-break: keep-all;
                    font-weight: 600;
                }
            }

            .footer{
                padding: 10px;
                
                .buttonGroupWrapper{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    button{
                        font-size: 12px;
                        color: #000;
                        border-radius: 30px;
                        height: 40px;
                        font-weight: 700;
                        border: none;

                        &.first{
                            background-color: #000;
                            color: #fff;
                        }

                        &.second{
                            background-color: var(--defaultElementBC);
                        }
                    }
                }
            }
        }
    `,
    FooterContainer: styled.div`
        .buttonGroupWrapper{
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            button{
                width: 100px;
                border-radius: 10px;
                border: none;
                font-weight: 700;

                &.cancel{
                    background-color: var(--defaultElementBC);
                }

                &.confirm{
                    background-color: var(--mainColor);
                    color: #fff;
                }
            }
        }
    `
}