import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 20px;
`;

export const HeaderWrapper = styled.div`
    margin-top: 60px;
    width: 50vw;
    /* height: auto; */
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .profileImageWrapper{
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        border-radius: 100%;
        overflow: hidden;
    }

    .domainNameWrapper{
        text-align: center;
        font-weight: 800;
        font-size: 16px;
        word-wrap: break-word;
    }

    .descriptionWrapper{
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #606060;

        word-wrap: break-word;
        word-break: keep-all;
        white-space: pre-line;
    }
`;