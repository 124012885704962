import { useEffect, useState } from 'react';
import { CustomRatioImage } from '../../../../../../../components/image/CustomRatioImage';
import * as St from './SgBackgroundDesign.styled';
import { useApiHook } from '../../../../hooks/useApiHook';
import CustomBlockButton from '../../../../../../../components/button/CustomBlockButton';
import { useUserPageContextActionsHook, useUserPageContextValueHook } from '../../../../../../../contexts/UserPageContextProvider';
import { CustomColorPalette } from '../../../../../../../components/color-palette/CustomColorPalette';
import { CustomBackdropController } from '../../../../../../../components/backdrop/default/v1';

export function SgBackgroundDesign({
    onRefreshPreviewViewer
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [designThemeList, setDesignThemeList] = useState(null);
    const [backgroundType, setBackgroundType] = useState(
        (!userPageContextValueHook?.userPage?.backgroundType || userPageContextValueHook?.userPage?.backgroundType === 'IMAGE') ? 'IMAGE' : 'HEX'
    ) // IMAGE, HEX
    const [backgroundImageUrl, setBackgroundImageUrl] = useState(userPageContextValueHook?.userPage?.backgroundImageUrl || '');
    const [backgroundColorHexChangeModeOpen, setBackgroundColorHexChangeModeOpen] = useState(false);
    const [backgroundColorHex, setBackgroundColorHex] = useState(userPageContextValueHook?.userPage?.backgroundColorHex || '#ffffff');

    const handleReqFetchDesignThemeList = async () => {
        const result = await apiHook.reqFetchDesignThemeList({ params: null, headers: null });

        if (result?.content) {
            setDesignThemeList(result?.content);
        }
    }

    const handleReqChangeBackgroundDesign = async () => {
        let body = {
            id: userPageContextValueHook?.userPage?.id,
            backgroundType: backgroundType,
            backgroundImageUrl: backgroundImageUrl,
            backgroundColorHex: backgroundColorHex,
        }

        if (!backgroundType || !['IMAGE', 'HEX'].includes(backgroundType) || backgroundType === 'HEX') {
            body.backgroundType = 'HEX';
            body.backgroundImageUrl = '';
            body.backgroundColorHex = backgroundColorHex || '#FFFFFF';
        } else if (backgroundType === 'IMAGE') {
            body.backgroundType = 'IMAGE';
            body.backgroundImageUrl = backgroundImageUrl || '';
            body.backgroundColorHex = '#FFFFFF';
        }

        CustomBackdropController().showBackdrop();
        const result = await apiHook.reqChangeUserPage_backgroundDesign({ body: body });

        if (result?.content) {
            userPageContextActionsHook.onSetUserPage({ ...result?.content });
            onRefreshPreviewViewer();
        }
        CustomBackdropController().hideBackdrop();
    }

    const toggleBackgroundColorHexChangeModeOpen = (bool) => {
        setBackgroundColorHexChangeModeOpen(bool)
    }


    const handleChangeBackgroundType = (type) => {
        setBackgroundType(type);
    }

    const handleChangeBackgroundImageUrl = (backgroundImageUrl) => {
        setBackgroundImageUrl(backgroundImageUrl);
    }

    const handleChangeBackgroundColorHex = (hex) => {
        setBackgroundColorHex(hex);
    }

    useEffect(() => {
        handleReqFetchDesignThemeList();
    }, []);

    return (
        <>
            <St.Container>
                <St.HeaderWrapper>
                    <div className='headerBox' onClick={() => setDropdownOpen(!dropdownOpen)}>
                        <div className='title'>배경 디자인</div>
                        <div className='icon'>
                            <CustomRatioImage>
                                {dropdownOpen ?
                                    <CustomRatioImage.Image src='/assets/icons/expandLess_000000.svg' />
                                    :
                                    <CustomRatioImage.Image src='/assets/icons/expandMore_000000.svg' />
                                }
                            </CustomRatioImage>
                        </div>
                    </div>
                </St.HeaderWrapper>
                {dropdownOpen &&
                    <>
                        <St.TypeSelectorWrapper>
                            <CustomBlockButton
                                type='button'
                                className={`${!backgroundType || backgroundType === 'HEX' ? 'active' : ''}`}
                                onClick={() => handleChangeBackgroundType('HEX')}
                            >
                                커스텀 색상
                            </CustomBlockButton>
                            <CustomBlockButton
                                type='button'
                                className={`${backgroundType === 'IMAGE' ? 'active' : ''}`}
                                onClick={() => handleChangeBackgroundType('IMAGE')}
                            >
                                기본 이미지
                            </CustomBlockButton>
                        </St.TypeSelectorWrapper>
                        {(!backgroundType || backgroundType === 'HEX') &&
                            <St.ColorSelectorWrapper>
                                <div className='currentColorTitle'>현재 색상</div>
                                <div className='currentColor' onClick={() => toggleBackgroundColorHexChangeModeOpen(true)}>
                                    <div className='colorBox' style={{ backgroundColor: backgroundColorHex }}></div>
                                    <div className='colorHex'>{backgroundColorHex}</div>
                                </div>
                                {backgroundColorHexChangeModeOpen &&
                                    <CustomColorPalette
                                        open={backgroundColorHexChangeModeOpen}
                                        fromColor={backgroundColorHex}
                                        toggleOpenClose={toggleBackgroundColorHexChangeModeOpen}
                                        onConfirm={handleChangeBackgroundColorHex}
                                    />
                                }
                                <div className='sampleColorList'>
                                    {CUSTOM_COLOR_LIST?.map(customColor => {
                                        return (
                                            <div
                                                key={customColor?.hex}
                                                className='colorBox'
                                                style={{ backgroundColor: customColor?.hex }}
                                                onClick={() => handleChangeBackgroundColorHex(customColor?.hex)}
                                            ></div>
                                        );
                                    })}
                                </div>
                            </St.ColorSelectorWrapper>
                        }
                        {(backgroundType === 'IMAGE') &&
                            <St.ImageSelectorWrapper>
                                <div className='cardList'>
                                    {designThemeList?.map(designTheme => {
                                        return (
                                            <div
                                                className={`card ${designTheme?.backgroundImageUrl === backgroundImageUrl ? 'active' : ''}`}
                                                key={designTheme?.id}
                                                onClick={() => handleChangeBackgroundImageUrl(designTheme?.backgroundImageUrl)}
                                            >
                                                <CustomRatioImage>
                                                    <CustomRatioImage.Image src={designTheme?.backgroundImageUrl} />
                                                </CustomRatioImage>
                                            </div>
                                        );
                                    })}
                                </div>
                            </St.ImageSelectorWrapper>
                        }
                        <St.FooterWrapper>
                            <CustomBlockButton
                                type='button'
                                onClick={() => handleReqChangeBackgroundDesign()}
                            >
                                적용
                            </CustomBlockButton>
                        </St.FooterWrapper>
                    </>
                }
            </St.Container>
        </>
    );
}

const CUSTOM_COLOR_LIST = [
    {
        hex: '#FFFFFF',
    },
    {
        hex: '#F8EDE3',
    },
    {
        hex: '#DFD3C3',
    },
    {
        hex: '#D0B8A8',
    },
    {
        hex: '#EECAD5',
    },
    {
        hex: '#F1D3CE',
    },
    {
        hex: '#F6EACB',
    },
    {
        hex: '#D1E9F6',
    },
    {
        hex: '#E7E8D8',
    },
    {
        hex: '#CADABF',
    },
    {
        hex: '#31363F',
    },
    {
        hex: '#35374B',
    }
]