import styled from 'styled-components';

export const Container = styled.div`
    /* max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 10px;
    box-sizing: border-box;
    position: sticky;
    top: 10px;
    z-index: 101;
    margin-top: 10px; */
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    z-index: 101;
`;

export const Wrapper = styled.div`
    /* background-color: #e5f6fd;
    border-radius: 15px;
    padding: 10px; */
    background-color: #e5f6fd;
    padding: 10px;
    
    .flexWrapper{
        display: flex;
        gap: 10px;
        align-items: center;

        section.first{
            width: 24px;
            height: 24px;

            svg{
                width: 100%;
                height: 100%;
            }
        }

        section.second{
            flex:1;

            .marqueeText{
                margin-right: 10px;
                font-size: 14px;
                font-weight: 600;
                color:#014361;
                letter-spacing: 1px;
            }
        }

    }
`;