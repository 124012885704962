import styled from 'styled-components';
import CustomBlockButton from '../../../../../components/button/CustomBlockButton';

export const Container = styled.div`
    background: #fff;
    /* padding: 20px 20px 20px 20px; */
    margin-right: 435px;
    margin-top: 20px;
    
    @media all and (max-width:992px){
        margin-right: 0;
        
        /* padding: 20px 10px 10px 10px; */
    }
    `;

export const Wrapper = styled.div`
    overflow-x: auto; /* 가로 스크롤바 표시 */
    white-space: nowrap; /* 항목이 한 줄로 표시되도록 설정 */
    display: flex;
    flex-direction: row;
    padding: 0 20px 20px 20px;
    gap: 10px;

    @media all and (max-width:992px){
        padding: 0 10px;
        padding-bottom: 5px;
    }
`;

export const TabButton = styled(CustomBlockButton)`
    min-width: 100px;
    width: auto;
    padding: 0 20px;
    border-radius: 30px;
    font-size: 16px;
    color: #606060;
    border: 1px solid #e0e0e0;
    transition: all .3s;
    &.active{
        color: #000;
        font-weight: 600;
        border: 1px solid #000;
    }
`;