import { Popover } from "@mui/material";
import { useState } from "react";
import styled from 'styled-components';

const Layout = styled.div`
    font-size: 14px;
    padding: 10px;
    color: #000;
    font-weight: 600;
    max-width: 300px;

    word-wrap: break-word;
    word-break: keep-all;
`;

export function CustomPopover({
    anchorEl = null,
    onClose = () => { },
    customLayout = false,
    layoutStyle = {},
    children
}) {
    return (
        <>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => onClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {customLayout ?
                    (
                        <>{children}</>
                    )
                    :
                    (
                        <Layout style={{ ...layoutStyle }}>
                            {children}
                        </Layout>
                    )
                }
            </Popover>
        </>
    );
}

export function useCustomPopoverHook() {
    const [anchorEl, setAnchorEl] = useState(null);
    const [element, setElement] = useState(null);

    const openPopover = (e, element) => {
        setAnchorEl(e.currentTarget);
        setElement(element);
    }

    const closePopover = () => {
        setElement(null);
        setAnchorEl(null);
    }

    return {
        anchorEl,
        element,
        openPopover,
        closePopover
    }
}