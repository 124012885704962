import React from 'react';
import styled from 'styled-components';

const InputEl = styled.input`
    width:100%;
    height: 48px;
    border-radius: 10px;
    border-width: 1px;
    border-style: solid;
    border-color: #aaa;
    padding: 0 10px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 500;

    &:focus{
        outline: none;
        border-color: #444444;
    }
`;

export function CustomInput({
    className = '',
    placeholder = '',
    ...props
}) {
    return (
        <InputEl
            placeholder={placeholder}
            className={className}
            {...props}
        ></InputEl>
    );
}

const CutstomRefInput = React.forwardRef(function CutstomRefInput({
    type = 'text',
    className = '',
    placeholder = '',
    ...props
}, ref) {
    return (
        <InputEl
            ref={ref}
            type={type}
            placeholder={placeholder}
            className={className}
            {...props}
        ></InputEl>
    );
})

export default CutstomRefInput;