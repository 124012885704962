import styled from 'styled-components';

export const Container = styled.div`
    
`;

export const Wrapper = styled.div`
    border: 1px solid #e0e0e0;
    border-radius: 15px;
    padding: 20px;

    .titleWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        h3{
            margin: 0;
            /* margin-bottom: 10px; */
        }

    }

    .flexWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        margin-top: 10px;

        @media all and (max-width:992px){
            flex-direction: column;
        }

        section.first{
            display: flex;
            gap: 10px;

            @media all and (max-width:992px){
                width: 100%;
            }

            select{
                width: 200px;
                border-radius: 10px;

                @media all and (max-width:992px){
                    flex:1;
                }
            }

            button{
                width: auto;
                padding: 0 16px;
                background-color: #000;
                color: #fff;
                font-weight: 700;
                border-radius: 10px;
                border: none;
            }
        }

        section.second{
            @media all and (max-width:992px){
                width: 100%;
            }

            button{
                width: 150px;
                border-radius: 30px;
                font-weight: 900;
                color: #000;
                background-color: var(--defaultElementBC);
                border:none;

                @media all and (max-width:992px){
                    flex:1;
                    width: 100%;
                }
            }
        }
    }
`;

export const MdSettingBodyContainer = styled.div`
    padding: 20px;

    .addWrapper{
        button.addMode{
            border-radius: 30px;
            background-color: #000;
            font-weight: 700;
            color:#fff;
            height: 50px;
        }

        .addFormBox{
            display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: center;

            section.first{
                flex:1;
                .inputBox{
                    position: relative;
                    label {
                        position: absolute;
                        top: 15px;
                        left: 20px;
                        transition: top 0.3s, left 0.3s;
                        pointer-events: none;
                        font-size: 14px;
                        color: #606060;
                        font-weight: 500;
                    }

                    input{
                        border-radius: 30px;
                        height: 50px;
                        border: 2px solid #e0e0e0;
                        background: #fff;
                        padding: 0 20px;
                        font-weight: 700;
                        &:focus{
                            border-color: #000000;
                        }
                    }

                    input:focus ~ label,
                    input:not(:placeholder-shown) ~ label {
                        top: -20px;
                        left: 20px;
                        font-size: 12px; /* 라벨 크기를 조정할 수 있습니다. */
                    }
                }
            }

            section.second{
                display: flex;
                gap: 10px;

                button{
                    width: 40px;
                    height: 40px;
                    border: none;
                    border-radius: 50%;
                    
                    .icon{
                        width: 60%;
                    }
                }
            }
        }
    }

    .listWrapper{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;

        .listWrapper__itemBox{
            position: relative;
            border: none;
            border-radius: 10px;
            /* box-shadow: var(--defaultBoxShadow3); */
            padding: 20px 10px;
            background-color: #fff;
            overflow: hidden;
            
            .listWrapper__itemBox__flexWrapper{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 20px;

                section.first{
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;

                    section.first_1{
                        width: 20px;
                        .icon{
                            width: 100%;
                        }
                    }

                    section.first_2{
                        flex:1;
                        font-weight: 600;
                    }

                    section.first_3{
                        button{
                            width: 24px;
                            height: 24px;
                            border: none;
                            .icon{
                                width: 80%;
                            }
                        }
                    }
                }

                section.second{
                    button{
                        width: 24px;
                        height: 24px;
                        border: none;
                        .icon{
                            width: 80%;
                        }
                    }
                }
            }

            .actionBlockBox{
                position:absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #ffffffa0;
            }

            .deleteModeBox{
                .notice{
                    text-align: center;
                    font-weight: 600;
                }

                .buttonGroup{
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    margin-top: 20px;
                    button{
                        border-radius: 30px;
                    }

                    button.confirm{
                        background-color: var(--redColor1);
                        color: #fff;
                        font-weight: 700;
                        border: 1px solid #00000000;
                    }

                    button.cancel{
                        background-color: var(--defaultElementBC);
                        border: 1px solid #00000000;
                        font-weight: 700;
                        color:#000;
                    }
                }
            }

            .editModeBox{
                display: flex;
            gap: 10px;
            justify-content: space-between;
            align-items: center;

            section.first{
                flex:1;
                .inputBox{
                    position: relative;
                    label {
                        position: absolute;
                        top: 15px;
                        left: 20px;
                        transition: top 0.3s, left 0.3s;
                        pointer-events: none;
                        font-size: 14px;
                        color: #606060;
                        font-weight: 500;
                    }

                    input{
                        border-radius: 30px;
                        height: 50px;
                        border: 2px solid #e0e0e0;
                        background: #fff;
                        padding: 0 20px;
                        font-weight: 700;
                        &:focus{
                            border-color: #000000;
                        }
                    }

                    input:focus ~ label,
                    input:not(:placeholder-shown) ~ label {
                        top: -20px;
                        left: 20px;
                        font-size: 12px; /* 라벨 크기를 조정할 수 있습니다. */
                    }
                }
            }

            section.second{
                display: flex;
                gap: 10px;

                button{
                    width: 40px;
                    height: 40px;
                    border: none;
                    border-radius: 50%;
                    
                    .icon{
                        width: 60%;
                    }
                }
            }
            }
        }
    }
`;