import { createContext, useContext, useMemo, useState } from "react";

const UserValueContext = createContext();
const UserActionsContext = createContext();

export function UserContextProvider({ children }) {
    const [value, setValue] = useState({
        user: null,
        isLoading: true
    });

    const actions = useMemo(
        () => {
            return {
                onSetUser(user) {
                    setValue({
                        user: user,
                        isLoading: false
                    });
                },
                onSetIsLoading(bool) {
                    setValue({
                        ...value,
                        isLoading: bool
                    });
                },
                onClear() {
                    setValue({
                        user: null,
                        isLoading: false
                    })
                }
            }
        },
        []
    )
    
    return (
        <>
            <UserActionsContext.Provider value={actions}>
                <UserValueContext.Provider value={value}>
                    {children}
                </UserValueContext.Provider>
            </UserActionsContext.Provider>
        </>
    );
}

export function useUserContextValueHook(props) {
    const value = useContext(UserValueContext);

    if (value === undefined) {
        throw new Error('UserContextValueHook should be used within UserValueContext');
    }

    return {
        user: value?.user,
        isLoading: value?.isLoading
    };
}

export function useUserContextActionsHook(props) {
    const value = useContext(UserActionsContext);

    if (value === undefined) {
        throw new Error('UserContextActionsHook should be used within UserActionsContext');
    }

    return {
        onSetUser: value.onSetUser,
        onSetIsLoading: value.onSetIsLoading,
        onClear: value.onClear
    };
}