export function NaverBlogIcon({
    fill='#000000',
    ...props
}) {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" {...props}>
                <g fill="none" fillRule="evenodd">
                    <path d="M23.142 7.625c.897 0 1.709.363 2.296.951.588.588.951 1.4.951 2.296v8.906c0 .896-.363 1.708-.95 2.296-.588.587-1.4.95-2.297.95H17.59l-1.435 2.615-2.15-2.614H8.622a3.237 3.237 0 0 1-2.296-.951 3.237 3.237 0 0 1-.951-2.296v-8.906c0-.897.363-1.708.951-2.296a3.237 3.237 0 0 1 2.296-.951z" stroke={fill} strokeWidth="1" />
                    <path d="M21.303 13.583c.44 0 .845.17 1.162.456v-.263h1.161V17.6a1.79 1.79 0 0 1-1.758 1.79h-.275v-1.176a.726.726 0 0 0 .87-.688l.002-.023v-.311a1.728 1.728 0 0 1-1.162.456c-1.016 0-1.84-.91-1.84-2.033 0-1.122.824-2.032 1.84-2.032zm-4.404 0c1.149 0 2.08.953 2.08 2.13 0 1.175-.931 2.129-2.08 2.129-1.15 0-2.082-.954-2.082-2.13s.932-2.13 2.082-2.13zM9.203 12.42v1.715a1.728 1.728 0 0 1 1.161-.457c1.016 0 1.84.91 1.84 2.033 0 1.123-.824 2.033-1.84 2.033-.44 0-.844-.171-1.161-.457v.36H8.04v-5.227h1.162zm3.243-.097c.886 0 1.614.681 1.688 1.549h.006v3.775h-1.162v-.182a1.68 1.68 0 0 1-.078.024.529.529 0 0 0 .077-.25l.001-.027v-3.195a.532.532 0 0 0-.51-.532h-.119a.54.54 0 0 0-.048.002V12.33c.047-.004.096-.006.145-.006zm-2.275 2.323c-.535 0-.968.477-.968 1.065s.433 1.065.968 1.065c.535 0 .968-.477.968-1.065s-.433-1.065-.968-1.065zm6.728.097c-.508 0-.92.434-.92.968 0 .535.412.968.92.968.507 0 .92-.433.92-.968 0-.534-.413-.968-.92-.968zm4.598-.097a.968.968 0 1 0 .968.975v-.034a.968.968 0 0 0-.968-.94z" fill={fill} />
                </g>
            </svg>
        </>
    );
}