import styled from 'styled-components';

export const BodyContainer = styled.div`
    padding: 20px;
    .palette{
        .react-colorful{
            width: 100%;
        }
    }

    .inputBox{
        font-weight: 700;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        input{
            border: 1px solid #e0e0e0;
            border-radius: 10px;
            padding: 8px;
            font-weight: 700;
            outline: none;
            width: 100px;

            &:hover{

            }
        }
    }
`;

export const FooterContainer = styled.div`
        .buttonGroupWrapper{
            padding: 20px;
            display: flex;
            justify-content: flex-end;
            gap: 10px;

            button{
                width: 100px;
                border-radius: 10px;
                border: none;
                font-weight: 700;

                &.cancel{
                    background-color: var(--defaultElementBC);
                }

                &.confirm{
                    background-color: var(--mainColor);
                    color: #fff;
                }
            }
        }
    `