import { FacebookIcon } from "../components/svg-icons/FacebookIcon";
import { GithubIcon } from "../components/svg-icons/GithubIcon";
import { InstagramIcon } from "../components/svg-icons/InstagramIcon";
import { LinkedinIcon } from "../components/svg-icons/LinkedinIcon";
import { NaverBlogIcon } from "../components/svg-icons/NaverBlogIcon";
import { ThreadsIcon } from "../components/svg-icons/ThreadsIcon";
import { TiktokIcon } from "../components/svg-icons/TiktokIcon";
import { WebIcon } from "../components/svg-icons/WebIcon";
import { XtwitterIcon } from "../components/svg-icons/XtwitterIcon";
import { YoutubeIcon } from "../components/svg-icons/YoutubeIcon";

export const GlobalSocialVariables = {
    list: [
        {
            id: 'website',
            name: 'Website',
            placeholder: '전체 URL *',
            linkPrefix: '',
            SvgIcon: ({ fill, ...props }) => <WebIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'instagram',
            name: 'Instagram',
            placeholder: '@를 제외한 아이디 *',
            linkPrefix: 'https://www.instagram.com/',
            SvgIcon: ({ fill, ...props }) => <InstagramIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'youtube',
            name: 'Youtube',
            placeholder: '전체 URL *',
            linkPrefix: '',
            SvgIcon: ({ fill, ...props }) => <YoutubeIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'tiktok',
            name: 'Tiktok',
            placeholder: '@를 제외한 아이디 *',
            linkPrefix: 'https://www.tiktok.com/@',
            SvgIcon: ({ fill, ...props }) => <TiktokIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'naver_blog',
            name: 'NaverBlog',
            placeholder: '전체 URL *',
            linkPrefix: '',
            SvgIcon: ({ fill, ...props }) => <NaverBlogIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'facebook',
            name: 'Facebook',
            placeholder: '전체 URL *',
            linkPrefix: '',
            SvgIcon: ({ fill, ...props }) => <FacebookIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'threads',
            name: 'Threads',
            placeholder: '@를 제외한 아이디 *',
            linkPrefix: 'https://www.threads.net/@',
            SvgIcon: ({ fill, ...props }) => <ThreadsIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'x_twitter',
            name: 'X(Twitter)',
            placeholder: '@를 제외한 핸들 *',
            linkPrefix: 'https://x.com/',
            SvgIcon: ({ fill, ...props }) => <XtwitterIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'linked_in',
            name: 'Linkedin',
            placeholder: '전체 URL *',
            linkPrefix: '',
            SvgIcon: ({ fill, ...props }) => <LinkedinIcon fill={fill || '#000000'} {...props} />
        },
        {
            id: 'github',
            name: 'Github',
            placeholder: '전체 URL *',
            linkPrefix: '',
            SvgIcon: ({ fill, ...props }) => <GithubIcon fill={fill || '#000000'} {...props} />
        }
    ]
}