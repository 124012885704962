import styled from 'styled-components';

const LineBreaker = styled.div`
    display: flex;
    justify-content: ${props => props.$align};
    align-items: center;
`;

export function ZigzagBreaker({
    fill,
    width,
    height,
    lineStyle,
    align // center, left, right
}) {
    return (
        <LineBreaker
            $align={align || 'center'}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 6" width={100} height={32}>
                <polyline points=".35 .7 4.45 4.7 8.55 .7 12.66 4.7 16.76 .7 20.86 4.7 24.96 .7 29.07 4.7 33.17 .7 37.27 4.7 41.38 .7 45.48 4.7 49.58 .7 53.68 4.7 57.78 .7 61.89 4.7 65.99 .7 70.1 4.7 74.2 .7 78.31 4.7 82.41 .7 86.52 4.7 90.63 .7 94.74 4.7 98.85 .7" fill="none" stroke={fill || '#000000'} strokeMiterlimit="10" />
            </svg>
        </LineBreaker>
    );
}