import styled, { css } from 'styled-components';

const FigureEl = styled.div`
    position: relative;
    padding-bottom: ${(props) => props.$width && props.$height ? `calc(${props.$height} / ${props.$width} * 100%)` : '100%'}; // 1:1
    margin: 0;
`;

const ImageEl = styled.img`
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    translate: -50% -50%;
`;

function Figure({
    width = 1,
    height = 1,
    className,
    style,
    children
}) {
    return (
        <FigureEl
            $width={width}
            $height={height}
            className={className}
            style={style}
        >
            {children}
        </FigureEl>
    );
}

function Image({
    src,
    alt,
    loading = 'lazy',
}) {
    return (
        <ImageEl
            src={src}
            alt={alt}
            loading={loading}
        ></ImageEl>
    )
}

export const CustomRatioImage = Object.assign(Figure, {
    Image: Image
})