import * as St from './WegetLinkItem.styled';
import { useRef, useState } from "react";
import { CustomPopover, useCustomPopoverHook } from "../../../../../../../../../components/popover/CustomPopover";
import { useLinkCategoryContextValueHook } from "../../../../../../contexts/LinkCategoryContextProvider";
import { Draggable } from 'react-beautiful-dnd';
import { Switch, Tooltip } from '@mui/material';
import CustomBlockButton from '../../../../../../../../../components/button/CustomBlockButton';
import { DeleteIcon } from '../../../../../../../../../components/svg-icons/DeleteIcon';
import { CustomRatioImage } from '../../../../../../../../../components/image/CustomRatioImage';
import { CustomDialog } from '../../../../../../../../../components/dialog/v1/CustomDialog';
import { CustomInput } from '../../../../../../../../../components/input/CustomInput';
import CustomSelect from '../../../../../../../../../components/select/default/v1/CustomSelect';
import { useUserPageContextValueHook } from '../../../../../../../../../contexts/UserPageContextProvider';
import { customToast } from '../../../../../../../../../components/toast/custom-react-toastify/v1';
import { CustomBackdropController } from '../../../../../../../../../components/backdrop/default/v1';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import firebaseStorage from '../../../../../../../../../firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { CustomRegexUtils } from '../../../../../../../../../utils/CustomRegexUtils';
import { CustomLabelInput } from '../../../../../../../../../components/input/CustomLabelInput';

export function WegetLinkItem({
    linkItemIndex,
    linkItem,
    clickLog,
    onReqDelete,
    onReqChangeIsHide,
    onReqUpdateInfo
}) {
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();
    const descriptionPopoverHook = useCustomPopoverHook();
    const thumbnailPopoverHook = useCustomPopoverHook();
    const deletePopoverHook = useCustomPopoverHook();

    const [updateLinkItemModalOpen, setUpdateLinkItemModalOpen] = useState(false);

    const toggleUpdateLinkItemModalOpen = (bool) => {
        setUpdateLinkItemModalOpen(bool);
    }

    const handleDelete = async () => {
        deletePopoverHook.closePopover();
        await onReqDelete(linkItemIndex);
    }

    const handleSubmitUpdateLinkItem = (updateForm) => {
        updateForm = {
            id: linkItem?.id,
            ...updateForm
        }

        onReqUpdateInfo(updateForm);
        toggleUpdateLinkItemModalOpen(false);
    }
    return (
        <>
            <Draggable key={linkItem.id} draggableId={`SubFdElements-${linkItem.id}`} index={linkItemIndex}>
                {(draggableProvided) => (
                    <St.ModuleContainer
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                    >
                        <div className='deleteButtonField'>
                            <Tooltip title='삭제'>
                                <CustomBlockButton
                                    type='button'
                                    onClick={(e) => deletePopoverHook.openPopover(e, linkItemIndex)}
                                >
                                    <DeleteIcon />
                                </CustomBlockButton>
                            </Tooltip>
                        </div>
                        <section className='first'>
                            <div className='icon' {...draggableProvided.dragHandleProps}>
                                <CustomRatioImage width={1} height={1}>
                                    <CustomRatioImage.Image src='/assets/icons/dragIndicator_808080.svg' />
                                </CustomRatioImage>
                            </div>
                        </section>
                        <section className='second'>
                            <section className='second_1'>
                                <section className='second_1_1'>
                                    <div className='badgeBox'>
                                        <div className='iconFigure'>
                                            <CustomRatioImage>
                                                <CustomRatioImage.Image src='/assets/icons/link_default_mainColor.svg' />
                                            </CustomRatioImage>
                                        </div>
                                        <div className='badge'>링크</div>
                                    </div>
                                    <div className='title'>{linkItem?.title}</div>
                                    <div className='linkUrl'>
                                        {linkItem?.linkUrl}
                                    </div>
                                </section>
                                <section className='second_1_2'>
                                    <Tooltip title='수정'>
                                        <CustomBlockButton
                                            type='button'
                                            onClick={() => toggleUpdateLinkItemModalOpen(true)}
                                        >
                                            <div className='icon'>
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src='/assets/icons/edit2_000000.svg' />
                                                </CustomRatioImage>
                                            </div>
                                        </CustomBlockButton>
                                    </Tooltip>
                                    <div className='switchBox'>
                                        <Tooltip title='보이기 or 숨기기'>
                                            <Switch size='small' color='success' onChange={e => onReqChangeIsHide(linkItemIndex)} checked={!linkItem?.isHide} />
                                        </Tooltip>
                                    </div>
                                </section>
                            </section>
                            <section className='second_2'>
                                <section className='second_2_1'>
                                    <div>
                                        <Tooltip title='설명'>
                                            <CustomBlockButton
                                                type='button'
                                                onClick={(e) => descriptionPopoverHook.openPopover(e, linkItem?.description)}
                                            >
                                                <div className='icon'>
                                                    <CustomRatioImage width={1} height={1}>
                                                        <CustomRatioImage.Image src='/assets/icons/description_000000.svg' />
                                                    </CustomRatioImage>
                                                </div>
                                            </CustomBlockButton>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip title='썸네일'>
                                            <CustomBlockButton
                                                type='button'
                                                onClick={(e) => thumbnailPopoverHook.openPopover(e, linkItem?.thumbnailUrl)}
                                            >
                                                <div className='icon'>
                                                    <CustomRatioImage width={1} height={1}>
                                                        <CustomRatioImage.Image src='/assets/icons/image_000000.svg' />
                                                    </CustomRatioImage>
                                                </div>
                                            </CustomBlockButton>
                                        </Tooltip>
                                    </div>
                                    <div className='clickLogGroup'>
                                        <div>
                                            <Tooltip title='클릭 수'>
                                                <CustomBlockButton
                                                    type='button'
                                                    className='rectangle'
                                                // onClick={(e) => thumbnailPopoverHook.openPopover(e, linkItem?.thumbnailUrl)}
                                                >
                                                    <div className='flexBox'>
                                                        <div className='icon'>
                                                            <CustomRatioImage width={1} height={1}>
                                                                <CustomRatioImage.Image src='/assets/icons/click_totalClick.svg' />
                                                            </CustomRatioImage>
                                                        </div>
                                                        <div className='count'>{clickLog?.totalClickCount || 0}</div>
                                                    </div>
                                                </CustomBlockButton>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip title='유효 클릭 수'>
                                                <CustomBlockButton
                                                    type='button'
                                                    className='rectangle'
                                                // onClick={(e) => thumbnailPopoverHook.openPopover(e, linkItem?.thumbnailUrl)}
                                                >
                                                    <div className='flexBox'>
                                                        <div className='icon'>
                                                            <CustomRatioImage width={1} height={1}>
                                                                <CustomRatioImage.Image src='/assets/icons/click_firstClick.svg' />
                                                            </CustomRatioImage>
                                                        </div>
                                                        <div className='count'>{clickLog?.firstClickCount || 0}</div>
                                                    </div>
                                                </CustomBlockButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </section>
                                {linkItem?.linkCategoryId &&
                                    <section className='second_2_2'>
                                        <div className='category'>
                                            {linkCategoryContextValueHook?.content?.find(r => r.id === linkItem?.linkCategoryId)?.name}
                                        </div>
                                    </section>
                                }
                            </section>
                        </section>
                        {draggableProvided.placeholder}
                    </St.ModuleContainer>
                )}
            </Draggable>
            {descriptionPopoverHook?.anchorEl &&
                <CustomPopover
                    anchorEl={descriptionPopoverHook?.anchorEl}
                    onClose={() => descriptionPopoverHook?.closePopover()}
                    layoutStyle={!descriptionPopoverHook?.element ? { color: '#808080' } : {}}
                >
                    {descriptionPopoverHook?.element ? descriptionPopoverHook?.element : '등록된 설명이 없습니다.'}
                </CustomPopover>
            }

            {thumbnailPopoverHook?.anchorEl &&
                <CustomPopover
                    anchorEl={thumbnailPopoverHook?.anchorEl}
                    onClose={() => thumbnailPopoverHook?.closePopover()}
                    layoutStyle={!thumbnailPopoverHook?.element ? { color: '#808080' } : {}}
                >
                    {thumbnailPopoverHook?.element ?
                        (
                            <St.ThumbnailPopoverElement>
                                <CustomRatioImage>
                                    <CustomRatioImage.Image src={thumbnailPopoverHook?.element} />
                                </CustomRatioImage>
                            </St.ThumbnailPopoverElement>
                        )
                        :
                        '등록된 썸네일이 없습니다.'
                    }
                </CustomPopover>
            }
            <CustomPopover
                anchorEl={deletePopoverHook?.anchorEl}
                onClose={() => deletePopoverHook?.closePopover()}
                customLayout
            >
                <St.DeletePopoverElement>
                    <div className='notice'>삭제 버튼을 누르면 영구적으로 삭제됩니다.</div>
                    <CustomBlockButton
                        type='button'
                        onClick={() => handleDelete()}
                    >
                        삭제
                    </CustomBlockButton>
                </St.DeletePopoverElement>
            </CustomPopover>

            {(updateLinkItemModalOpen && linkItem) &&
                <UpdateLinkItemModal
                    open={updateLinkItemModalOpen}
                    linkItem={linkItem}
                    toggleOpenClose={toggleUpdateLinkItemModalOpen}
                    onConfirm={(body) => handleSubmitUpdateLinkItem(body)}
                />
            }
        </>
    );
}

function UpdateLinkItemModal({
    open = false,
    linkItem,
    toggleOpenClose,
    onConfirm
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();

    const imageUploaderRef = useRef();

    const [createForm, setCreateForm] = useState({ ...linkItem })

    const [thumbnailDropdownOpen, setThumbnailDropdownOpen] = useState(linkItem?.thumbnailUrl ? true : false);

    const toggleThumbnailDropdownOpen = (bool) => {
        setThumbnailDropdownOpen(bool);
    }


    const handleChangeTitle = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            title: value
        }))
    }

    const handleChangeLinkUrl = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            linkUrl: value
        }))
    }

    const handleChangeDescription = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            description: value
        }))
    }

    const handleChangeLinkCategoryId = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            linkCategoryId: value || ''
        }))
    }

    const handleImageUploaderOpen = () => {
        imageUploaderRef.current.click();
    }

    const handleSelectThumbnail = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            customToast('올바른 이미지 파일을 선택해 주세요.');
            return;
        }

        let fileSize = file.size;
        let extension = file.name.split('.').pop();

        if (fileSize > 5 * 1024 * 1024) {
            customToast.error('이미지 크기는 최대 5MB까지만 허용됩니다.');
            return;
        }

        CustomBackdropController().showBackdrop();

        const fileRef = ref(firebaseStorage, `/assets/user_pages/${userPageContextValueHook?.userPage?.id}/link_thumbnail_images/${uuidv4()}.${extension}`);

        const uploadedUrl = await uploadBytes(fileRef, file).then(async snapshot => {
            return await getDownloadURL(snapshot.ref)
                .then(url => {
                    return url
                })
        });
        CustomBackdropController().hideBackdrop();

        if (uploadedUrl) {
            setCreateForm(prev => ({
                ...prev,
                thumbnailUrl: uploadedUrl
            }))
        };
    }

    const handleRemoveThumbnail = async () => {
        setCreateForm(prev => ({
            ...prev,
            thumbnailUrl: ''
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let newUrl = createForm?.linkUrl;

        if (createForm?.title?.length < 1 || createForm?.title?.length > 50) {
            customToast.error('링크 주제는 1-50 자 내외 필수 입력 입니다.');
            return;
        }

        if (!CustomRegexUtils().hasHttpPrefix(newUrl)) {
            newUrl = `http://${newUrl}`;
        }

        if (!CustomRegexUtils().isValidUrlFormat(newUrl)) {
            customToast.error('올바른 URL을 입력해 주세요. \nex) https://www.example.com');
            return;
        }

        if (createForm?.description?.length > 100) {
            customToast.error('설명은 100자 이내로 입력 가능합니다.');
            return;
        }

        let body = {
            ...createForm,
            title: createForm?.title?.trim(),
            linkUrl: newUrl,
            description: createForm?.description.trim(),
            thumbnailUrl: createForm?.thumbnailUrl,
            linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r?.id === createForm?.linkCategoryId)?.id || '',
            userPageId: userPageContextValueHook?.userPage?.id
        }

        onConfirm(body);
    }

    return (
        <>
            <CustomDialog
                open={open}
                onClose={() => toggleOpenClose(false)}
                maxWidth='sm'
            >
                <St.UpdateModuleContainer>
                    <CustomDialog.Header style={{ marginBottom: '20px' }}>
                        <CustomDialog.Header.Fake />
                        <CustomDialog.Header.Title>링크 모듈</CustomDialog.Header.Title>
                        <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                    </CustomDialog.Header>
                    <div className='wrapper'>
                        <CustomLabelInput>
                            <CustomLabelInput.Input
                                type='text'
                                value={createForm?.title}
                                onChange={(e) => handleChangeTitle(e)}
                            />
                            <CustomLabelInput.Label><span style={{ color: '#ef476f' }}>*</span> 링크 주제</CustomLabelInput.Label>
                        </CustomLabelInput>
                        <CustomLabelInput>
                            <CustomLabelInput.Input
                                type='text'
                                value={createForm?.linkUrl}
                                onChange={(e) => handleChangeLinkUrl(e)}
                            />
                            <CustomLabelInput.Label><span style={{ color: '#ef476f' }}>*</span> 링크 URL</CustomLabelInput.Label>
                        </CustomLabelInput>
                        <CustomLabelInput>
                            <CustomLabelInput.Input
                                type='text'
                                value={createForm?.description}
                                onChange={(e) => handleChangeDescription(e)}
                            />
                            <CustomLabelInput.Label>설명</CustomLabelInput.Label>
                        </CustomLabelInput>
                        <div className={`wrapper__thumbnailWrapper ${thumbnailDropdownOpen ? 'open' : ''}`}>
                            <div className={`wrapper__thumbnailWrapper__header ${thumbnailDropdownOpen ? 'open' : ''}`} onClick={() => toggleThumbnailDropdownOpen(!thumbnailDropdownOpen)}>
                                <div className='wrapper__thumbnailWrapper__header__title'>썸네일</div>
                                <div className='wrapper__thumbnailWrapper__header__icon'>
                                    {thumbnailDropdownOpen ?
                                        (
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/expandLess_000000.svg' />
                                            </CustomRatioImage>
                                        )
                                        :
                                        (
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/expandMore_000000.svg' />
                                            </CustomRatioImage>
                                        )}
                                </div>
                            </div>
                            {thumbnailDropdownOpen &&
                                <div className='wrapper__thumbnailWrapper__contentLayout'>
                                    <div className='wrapper__thumbnailWrapper__contentLayout__thumbnailBox'>
                                        {createForm?.thumbnailUrl ?
                                            (
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src={createForm?.thumbnailUrl} />
                                                </CustomRatioImage>
                                            )
                                            :
                                            (
                                                <div className='wrapper__thumbnailWrapper__contentLayout__thumbnailBox__blankText'>
                                                    권장 이미지 사이즈<br />256 x 256
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='wrapper__thumbnailWrapper__contentLayout__buttonGroup'>
                                        <CustomBlockButton
                                            type='button'
                                            className='select'
                                            onClick={() => handleImageUploaderOpen()}
                                        >
                                            썸네일 선택
                                        </CustomBlockButton>
                                        <CustomBlockButton
                                            type='button'
                                            className='remove'
                                            onClick={() => handleRemoveThumbnail()}
                                        >
                                            썸네일 제거
                                        </CustomBlockButton>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='wrapper__selectBox'>
                            <CustomSelect
                                value={createForm?.linkCategoryId}
                                onChange={(e) => handleChangeLinkCategoryId(e)}
                            >
                                <option value=''>전체</option>
                                {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                    return (
                                        <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                    );
                                })}
                            </CustomSelect>
                            <label>카테고리{createForm?.linkCategoryId && <span style={{ color: 'var(--redColor1)', paddingLeft: '10px' }}>* 카테고리가 선택 되었어요!</span>}</label>
                        </div>
                        <div className='wrapper__footerButtonGroup'>
                            <CustomBlockButton
                                type='button'
                                onClick={(e) => handleSubmit(e)}
                            >
                                수정
                            </CustomBlockButton>
                        </div>
                    </div>
                </St.UpdateModuleContainer>
            </CustomDialog>

            <input
                ref={imageUploaderRef}
                type="file"
                accept="image/*"
                onClick={(e) => e.target.value = ''}
                onChange={(e) => handleSelectThumbnail(e)}
                hidden
            />
        </>
    );
}