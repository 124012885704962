import styled from 'styled-components';

export const Container = styled.div`
    background: #fff;
    padding: 20px 80px 200px 80px;
    margin-right: 435px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media all and (max-width:992px){
        margin-right: 0;

        padding: 20px 10px 200px 10px;
    }
`;

export const FragmentListWrapper = styled.div`
    box-shadow: var(--defaultBoxShadow);
    border-radius: 15px;

`;

export const DropdownFieldWrapper = styled.div`
    border-bottom: 1px solid #f0f0f0;

    &:last-child{
        border-bottom: none;
    }
`;