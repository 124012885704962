import { useState } from 'react';
import { CustomRatioImage } from '../../../../../../../components/image/CustomRatioImage';
import { useUserPageContextActionsHook, useUserPageContextValueHook } from '../../../../../../../contexts/UserPageContextProvider';
import * as St from './SgSocialDesign.styled';
import { useApiHook } from '../../../../hooks/useApiHook';
import { CustomColorPalette } from '../../../../../../../components/color-palette/CustomColorPalette';
import CustomBlockButton from '../../../../../../../components/button/CustomBlockButton';
import { CustomBackdropController } from '../../../../../../../components/backdrop/default/v1';

export function SgSocialDesign({
    onRefreshPreviewViewer
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const socialConfig = userPageContextValueHook?.userPage?.socialConfig;

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const [position, setPosition] = useState(socialConfig?.position || 'TOP');
    const [iconColorHex, setIconColorHex] = useState(socialConfig?.iconColorHex || '#000000');
    const [iconColorHexPaletteOpen, setIconColorHexPaletteOpen] = useState(false);

    const handleReqChangeSocialDesign = async (e) => {
        e.preventDefault();

        let body = {
            id: userPageContextValueHook?.userPage?.id,
            socialConfig: {
                ...socialConfig,
                position: position,
                iconColorHex: iconColorHex,
            }
        }

        CustomBackdropController().showBackdrop();
        const changeResult = await apiHook.reqChangeUserPage_socialConfig({ body });

        if (changeResult?.content) {
            userPageContextActionsHook.onSetUserPage({ ...changeResult?.content });
            onRefreshPreviewViewer();
        }
        CustomBackdropController().hideBackdrop();
    }

    const toggleIconColorHexPaletteOpen = (bool) => {
        setIconColorHexPaletteOpen(bool);
    }

    const handleChangePosition = (position) => {
        setPosition(position);
    }

    const handleChangeIconColorHex = (hex) => {
        setIconColorHex(hex)
    }

    return (
        <>
            <St.Container>
                <St.HeaderWrapper>
                    <div className='headerBox' onClick={() => setDropdownOpen(!dropdownOpen)}>
                        <div className='title'>소셜 디자인</div>
                        <div className='icon'>
                            <CustomRatioImage>
                                {dropdownOpen ?
                                    <CustomRatioImage.Image src='/assets/icons/expandLess_000000.svg' />
                                    :
                                    <CustomRatioImage.Image src='/assets/icons/expandMore_000000.svg' />
                                }
                            </CustomRatioImage>
                        </div>
                    </div>
                </St.HeaderWrapper>
                {dropdownOpen &&
                    <>
                        <St.PositionWrapper>
                            <div className='title'>소셜 배열 위치</div>
                            <div className='positionButtonGroup'>
                                <CustomBlockButton
                                    type='button'
                                    className={`${!position || position === 'TOP' ? 'active' : ''}`}
                                    onClick={() => handleChangePosition('TOP')}
                                >
                                    상단
                                </CustomBlockButton>
                                <CustomBlockButton
                                    type='button'
                                    className={`${position === 'BOTTOM' ? 'active' : ''}`}
                                    onClick={() => handleChangePosition('BOTTOM')}
                                >
                                    하단
                                </CustomBlockButton>
                            </div>
                        </St.PositionWrapper>
                        <St.ColorSelectorWrapper>
                            <div className='currentColorTitle'>아이콘 색상</div>
                            <div className='currentColor' onClick={() => toggleIconColorHexPaletteOpen(true)}>
                                <div className='colorBox' style={{ backgroundColor: iconColorHex }}></div>
                                <div className='colorHex'>{iconColorHex}</div>
                            </div>
                            {iconColorHexPaletteOpen &&
                                <CustomColorPalette
                                    open={iconColorHexPaletteOpen}
                                    fromColor={iconColorHex}
                                    toggleOpenClose={toggleIconColorHexPaletteOpen}
                                    onConfirm={handleChangeIconColorHex}
                                />
                            }
                            <div className='sampleColorList'>
                                {CUSTOM_ICONCOLOR_HEX_LIST?.map(customColor => {
                                    return (
                                        <div
                                            key={customColor?.hex}
                                            className='colorBox'
                                            style={{ backgroundColor: customColor?.hex }}
                                            onClick={() => handleChangeIconColorHex(customColor?.hex)}
                                        ></div>
                                    );
                                })}
                            </div>
                        </St.ColorSelectorWrapper>
                        <St.FooterWrapper>
                            <CustomBlockButton
                                type='button'
                                onClick={(e) => handleReqChangeSocialDesign(e)}
                            >
                                적용
                            </CustomBlockButton>
                        </St.FooterWrapper>
                    </>
                }
            </St.Container>
        </>
    );
}

const CUSTOM_ICONCOLOR_HEX_LIST = [
    {
        hex: '#000000',
    },
    {
        hex: '#808080',
    },
    {
        hex: '#ffffff',
    },
]