import axios from "axios"

const mainApiHost = process.env.REACT_APP_MAIN_API_HOST;

export function LinkCategoriesApi(props) {
    return {
        searchListByUserPageId: async ({ params, headers }) => {
            return await axios.get(`${mainApiHost}/link-categories/user-pages/${params?.userPageId}`, {
                headers: headers
            })
        },
        createOne: async ({ body, headers }) => {
            return await axios.post(`${mainApiHost}/link-categories`, body, {
                headers: headers
            })
        },
        deleteOne: async ({ body, headers }) => {
            return await axios.delete(`${mainApiHost}/link-categories`, {
                data: body,
                headers: headers
            })
        },
        changeOne_name: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/link-categories/one/name`, body, {
                headers: headers
            })
        },
        changeList_orderNumber: async ({ body, headers }) => {
            return await axios.patch(`${mainApiHost}/link-categories/list/orderNumber`, body, {
                headers: headers
            })
        }
    }
}