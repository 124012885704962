import { HexColorPicker } from "react-colorful";
import { CustomDialog } from "../dialog/v1/CustomDialog";
import { useState } from "react";
import * as St from './CustomColorPalette.styled';
import CustomBlockButton from "../button/CustomBlockButton";

export function CustomColorPalette({
    open,
    fromColor,
    toggleOpenClose,
    onConfirm
}) {
    const [toColor, setToColor] = useState(fromColor || '#000000');

    const handleChangeToColor = (value) => {
        setToColor(value)
    }

    const handleSubmitConfirm = async () => {
        await onConfirm(toColor);
        toggleOpenClose(false);
    }

    const toColorHex = toColor?.replaceAll('#', '');

    return (
        <>
            <CustomDialog
                open={open}
                onClose={() => toggleOpenClose(false)}
            >
                <St.BodyContainer>
                    <div className='palette'>
                        <HexColorPicker color={toColor} onChange={(e) => handleChangeToColor(e)} />
                    </div>
                    <div className='inputBox'>
                        <span>#</span>
                        <input
                            type='text'
                            onChange={(e) => {
                                const value = e.target.value;
                                const regex = /^[0-9a-fA-F]{0,6}$/;
                                if (regex.test(value)) {
                                    handleChangeToColor(`#${value}`)
                                }
                            }}
                            value={toColorHex}
                        ></input>
                    </div>
                </St.BodyContainer>
                <St.FooterContainer>
                    <div className='buttonGroupWrapper'>
                        <CustomBlockButton
                            type='button'
                            className='cancel'
                            onClick={() => toggleOpenClose(false)}
                        >취소</CustomBlockButton>
                        <CustomBlockButton
                            type='button'
                            className='confirm'
                            onClick={() => handleSubmitConfirm()}
                        >적용</CustomBlockButton>
                    </div>
                </St.FooterContainer>
            </CustomDialog>
        </>
    );
}