import styled from 'styled-components';
import { CustomRatioImage } from '../../image/CustomRatioImage';

const Container = styled.div`
    .header-close-button-box{
        display: flex;
        justify-content: flex-end;
        padding: ${props => props?.$gap ? `${props?.$gap}px ${props?.$gap}px 0 ${props?.$gap}px` : '20px 20px 0 20px'};

        .header-close-button-el{
            user-select: none;
            -webkit-tap-highlight-color: #00000000;
            width:${props => props?.$size ? `${props?.$size}px` : `40px`};
            height: ${props => props?.$size ? `${props?.$size}px` : `40px`};
            padding: 0;
            margin:0;
            border:none;
            background:none;
            cursor: pointer;
            /* display: flex;
            justify-content: center;
            align-items: center; */

            .header-close-button-icon{
                width:100%;
                height: 100%;
            }
        }
    
    }
`;

export default function CloseButton({
    onClose,
    size = 40,
    gap = 10
}) {
    return (
        <Container $size={size} $gap={gap}>
            <div className='header-close-button-box'>
                <button
                    type='button'
                    className='header-close-button-el'
                    onClick={() => onClose()}
                >
                    {/* hello */}
                    <CustomRatioImage width={1} height={1}>
                        <CustomRatioImage.Image src='/assets/icons/close_default_959eae.svg' />
                    </CustomRatioImage>
                </button>
            </div>
        </Container>
    );
}