import { useEffect, useState } from 'react';
import CustomSelect from '../../../../../components/select/default/v1/CustomSelect';
import * as St from './FdLinkCategory.styled';
import { useApiHook } from '../../hooks/useApiHook';
import { useQueryParamsHook } from '../../../../../hooks/useQueryParamsHook';

export function FdLinkCategory({
    userPage
}) {
    const queryParamsHook = useQueryParamsHook();
    const queryParams = queryParamsHook.params;
    const lcId = queryParams?.lcId; // linkCategoryId

    const apiHook = useApiHook();

    const [linkCategoryList, setLinkCategoryList] = useState(null);


    const handleReqFetchLinkCategoryList = async () => {
        let params = {
            userPageId: userPage?.id
        }
        const fetchResult = await apiHook.reqFetchLinkCategoryList({ params });

        if (fetchResult?.content) {
            setLinkCategoryList(fetchResult?.content);
        }
    }

    const handleChangeLinkCategory = (e) => {
        const value = e.target.value;
        let newQueryParams = { ...queryParams };

        if (!value) {
            delete newQueryParams.lcId
            queryParamsHook.setSearchParams({ ...newQueryParams }, { replace: true })
            return;
        }

        queryParamsHook.setSearchParams({ ...newQueryParams, lcId: value }, { replace: true })
    }

    useEffect(() => {
        if (!userPage?.viewCategoryFlag) {
            return;
        }

        handleReqFetchLinkCategoryList();
    }, [userPage?.viewCategoryFlag]);

    return (
        <>
            {userPage?.viewCategoryFlag &&
                <St.Container>
                    <St.Wrapper>
                        <CustomSelect value={lcId || ''} onChange={(e) => handleChangeLinkCategory(e)}>
                            <option value={''}>카테고리 전체</option>
                            {linkCategoryList?.map(r => {
                                return (
                                    <option key={r?.id} value={r?.id}>{r?.name}</option>
                                );
                            })}
                        </CustomSelect>
                    </St.Wrapper>
                </St.Container>
            }
        </>
    );
}