import { Link, useNavigate } from 'react-router-dom';
import * as St from './TopNavBar.styled';
import { useUserContextValueHook } from '../../contexts/UserContextProvider';
import { signOut } from 'firebase/auth';
import firebaseAuth from '../../firebase/auth';
import CustomBlockButton from '../../components/button/CustomBlockButton';
import { useUserPageContextValueHook } from '../../contexts/UserPageContextProvider';
import { CustomRatioImage } from '../../components/image/CustomRatioImage';

export function TopNavBar(props) {
    const navigate = useNavigate();
    const userContextValueHook = useUserContextValueHook();
    const userPageContextValueHook = useUserPageContextValueHook();

    const handleSignOut = async () => {
        const signoutResult = await signOut(firebaseAuth);
        navigate({
            pathname: '/'
        })
    }

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <St.ContentItemsWrapper>
                        <div className='layout'>
                            <Link to='/' className='layout__link logo'>
                                <CustomRatioImage>
                                    <CustomRatioImage.Image src='/assets/images/shooluk_logo.png' loading='eager'/>
                                </CustomRatioImage>
                            </Link>
                            {userPageContextValueHook?.isLoading ?
                                (<></>)
                                :
                                userPageContextValueHook?.userPage ?
                                    (<Link to={`/${userPageContextValueHook?.userPage?.id}/mypage`} className='layout__link mypage'>마이 페이지</Link>)
                                    :
                                    (<Link to='/create/mypage' className='layout__link default'>페이지 생성</Link>)
                            }
                        </div>
                    </St.ContentItemsWrapper>
                    <St.UserStatusWrapper>
                        <div className='layout'>
                            {
                                userContextValueHook?.isLoading ?
                                    (<Link to='#' className='layout__link signUp'>로딩중...</Link>)
                                    :
                                    userContextValueHook?.user ?
                                        (
                                            <>
                                                <CustomBlockButton onClick={() => handleSignOut()} className='layout__link logout'>로그아웃</CustomBlockButton>
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <Link to='/login'>
                                                    <CustomBlockButton className='layout__link signIn'>로그인</CustomBlockButton>
                                                </Link>
                                            </>
                                        )
                            }
                        </div>
                    </St.UserStatusWrapper>
                </St.Wrapper>
            </St.Container>
        </>
    );
}