import { useEffect, useRef, useState } from 'react';
import CustomBlockButton from '../../../../../../../components/button/CustomBlockButton';
import { CustomRatioImage } from '../../../../../../../components/image/CustomRatioImage';
import { CustomInput } from '../../../../../../../components/input/CustomInput';
import { useUserPageContextActionsHook, useUserPageContextValueHook } from '../../../../../../../contexts/UserPageContextProvider';
import * as St from './SubFdDomainInformation.styled';
import { useApiHook } from '../../../../hooks/useApiHook';
import { CustomDialog } from '../../../../../../../components/dialog/v1/CustomDialog';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import firebaseStorage from '../../../../../../../firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { CustomBackdropController } from '../../../../../../../components/backdrop/default/v1';
import { customToast } from '../../../../../../../components/toast/custom-react-toastify/v1';
import { CustomColorPalette } from '../../../../../../../components/color-palette/CustomColorPalette';

const customBackdropController = CustomBackdropController();

export function SubFdDomainInformation({
    onRefreshPreviewViewer
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const imageUploaderRef = useRef();

    const [editData, setEditData] = useState({
        profileImageUrl: '',
        domainName: '',
        description: '',
    })

    const [profileImageUrlModalOpen, setProfileImageUrlModalOpen] = useState(false);
    const [editDesignModalOpen, setEditDesignModalOpen] = useState(false);

    useEffect(() => {
        if (userPageContextValueHook?.isLoading || !userPageContextValueHook?.userPage) {
            return;
        }

        setEditData({
            profileImageUrl: userPageContextValueHook?.userPage?.profileImageUrl,
            domainName: userPageContextValueHook?.userPage?.domainName,
            description: userPageContextValueHook?.userPage?.description
        })
    }, [userPageContextValueHook?.isLoading, userPageContextValueHook?.userPage]);

    const toggleProfileImageUrlModalOpen = (bool) => {
        setProfileImageUrlModalOpen(bool);
    }

    const toggleEditDesignModalOpen = (bool) => {
        setEditDesignModalOpen(bool);
    }

    const handleChangeTextValueFromEvent = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setEditData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleClickInitialize = () => {
        setEditData({
            profileImageUrl: userPageContextValueHook?.userPage?.profileImageUrl,
            domainName: userPageContextValueHook?.userPage?.domainName,
            description: userPageContextValueHook?.userPage?.description
        })
    }

    const handleReqUpdateUserPages = async () => {
        let body = {
            id: userPageContextValueHook?.userPage?.id,
            domainName: editData?.domainName,
            description: editData?.description,
            profileImageUrl: editData?.profileImageUrl
        }

        const result = await apiHook?.reqUpdateUserPages({ body });

        if (result?.content) {
            userPageContextActionsHook.onSetUserPage({
                ...userPageContextValueHook?.userPage,
                domainName: result?.content?.domainName,
                description: result?.content?.description,
                profileImageUrl: result?.content?.profileImageUrl
            });
            onRefreshPreviewViewer();
        }
    }

    const handleImageUploaderOpen = () => {
        imageUploaderRef.current.click();
    }

    const handleSelectProfileImage = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            customToast('올바른 이미지 파일을 선택해 주세요.');
            return;
        }

        let fileSize = file.size;
        let extension = file.name.split('.').pop();

        if (fileSize > 5 * 1024 * 1024) {
            customToast.error('이미지 크기는 최대 5MB까지만 허용됩니다.');
            return;
        }

        customBackdropController.showBackdrop();

        const fileRef = ref(firebaseStorage, `/assets/user_pages/${userPageContextValueHook?.userPage?.id}/profile_images/${uuidv4()}.${extension}`);

        const uploadedUrl = await uploadBytes(fileRef, file).then(async snapshot => {
            return await getDownloadURL(snapshot.ref)
                .then(url => {
                    return url
                })
        });
        customBackdropController.hideBackdrop();

        if (uploadedUrl) {
            setEditData(prev => {
                return {
                    ...prev,
                    profileImageUrl: uploadedUrl
                }
            });
            toggleProfileImageUrlModalOpen(false);
        };
    }

    const handleSetDefaultProfileImage = async () => {
        setEditData(prev => ({
            ...prev,
            profileImageUrl: ''
        }));
        toggleProfileImageUrlModalOpen(false);
    }

    const isChangedProfileImageUrl = userPageContextValueHook?.userPage?.profileImageUrl !== editData?.profileImageUrl;
    const isChangedDomainName = userPageContextValueHook?.userPage?.domainName !== editData?.domainName;
    const isChangedDescription = userPageContextValueHook?.userPage?.description !== editData?.description;

    return (
        <>
            <St.Container>
                <St.PageProfileWrapper>
                    <div className='layout'>
                        <div className='layout__profileImageBox' onClick={() => toggleProfileImageUrlModalOpen(true)}>
                            <CustomRatioImage width={1} height={1}>
                                <CustomRatioImage.Image src={editData?.profileImageUrl || '/assets/images/space.png'} />
                            </CustomRatioImage>
                        </div>
                        <div className='layout__informationWrapper'>
                            <div className='layout__informationWrapper__domainNameBox'>
                                <div className='layout__informationWrapper__domainNameBox__label'>도메인명</div>
                                <CustomInput
                                    type='text'
                                    name={'domainName'}
                                    className='layout__informationWrapper__domainNameBox__text'
                                    value={editData?.domainName || ''}
                                    onChange={(e) => handleChangeTextValueFromEvent(e)}
                                />
                            </div>
                            <div className='layout__informationWrapper__descriptionBox'>
                                <div className='layout__informationWrapper__descriptionBox__label'>페이지 설명</div>
                                <textarea
                                    name={'description'}
                                    className='layout__informationWrapper__descriptionBox__text'
                                    value={editData?.description || ''}
                                    onChange={(e) => handleChangeTextValueFromEvent(e)}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className='buttonGroup'>
                        <CustomBlockButton
                            type='button'
                            className='initialize'
                            onClick={() => handleClickInitialize()}
                            disabled={
                                !isChangedProfileImageUrl &&
                                !isChangedDomainName &&
                                !isChangedDescription
                            }
                        >
                            초기화
                        </CustomBlockButton>
                        <CustomBlockButton
                            type='button'
                            className='save'
                            onClick={() => handleReqUpdateUserPages()}
                            disabled={
                                !isChangedProfileImageUrl &&
                                !isChangedDomainName &&
                                !isChangedDescription
                            }
                        >
                            저장
                        </CustomBlockButton>
                    </div>
                </St.PageProfileWrapper>
            </St.Container >

            <CustomDialog
                open={profileImageUrlModalOpen}
                onClose={() => toggleProfileImageUrlModalOpen(false)}
            >
                <CustomDialog.CloseButton onClose={() => toggleProfileImageUrlModalOpen(false)} />
                <St.ImageModalContainer>
                    <div className='wrapper'>
                        <CustomBlockButton
                            type='button'
                            className='selectCustom'
                            onClick={() => handleImageUploaderOpen()}
                        >
                            이미지 선택
                        </CustomBlockButton>
                        <CustomBlockButton
                            type='button'
                            className='selectDefault'
                            onClick={() => handleSetDefaultProfileImage()}
                        >
                            기본 이미지로 변경
                        </CustomBlockButton>
                    </div>
                </St.ImageModalContainer>
            </CustomDialog>

            {editDesignModalOpen &&
                <MdEditDesignModal
                    open={editDesignModalOpen}
                    toggleEditDesignModalOpen={toggleEditDesignModalOpen}
                    onRefreshPreviewViewer={onRefreshPreviewViewer}
                />
            }
            <input
                ref={imageUploaderRef}
                type="file"
                accept="image/*"
                onClick={(e) => e.target.value = ''}
                onChange={(e) => handleSelectProfileImage(e)}
                hidden
            />
        </>
    );
}

function MdEditDesignModal({
    open,
    toggleEditDesignModalOpen,
    onRefreshPreviewViewer
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const apiHook = useApiHook();

    const imageUploaderRef = useRef();

    const [domainDesignType, setDomainDesignType] = useState(userPageContextValueHook?.userPage?.domainDesignType || 'CLASSIC');
    const [domainNameColor, setDomainNameColor] = useState(userPageContextValueHook?.userPage?.domainNameColor || '#000000');
    const [descriptionColor, setDescriptionColor] = useState(userPageContextValueHook?.userPage?.descriptionColor || '#000000');
    const [profileBackgroundColor, setProfileBackgroundColor] = useState(userPageContextValueHook?.userPage?.profileBackgroundColor || '#000000');
    const [topBannerImageUrl, setTopBannerImageUrl] = useState(userPageContextValueHook?.userPage?.topBannerImageUrl || '');


    const [domainNameColorChangeModeOpen, setDomainNameColorChangeModeOpen] = useState(false);
    const [descriptionColorChangeModeOpen, setDescriptionColorChangeModeOpen] = useState(false);
    const [profileBackgroundColorChangeModeOpen, setProfileBackgroundColorChangeModeOpen] = useState(false);

    const toggleDomainNameColorChangeModeOpen = (bool) => {
        setDomainNameColorChangeModeOpen(bool);
    }

    const toggleDescriptionColorChangeModeOpen = (bool) => {
        setDescriptionColorChangeModeOpen(bool);
    }

    const toggleProfileBackgroundColorChangeModeOpen = (bool) => {
        setProfileBackgroundColorChangeModeOpen(bool);
    }

    const handleChangeSelectedDomainDesignType = (type) => {
        setDomainDesignType(type);
    }

    const handleChangeDomainNameColor = (value) => {
        setDomainNameColor(value);
    }

    const handleChangeDescriptionColor = (value) => {
        setDescriptionColor(value);
    }

    const handleChangeProfileBackgroundColor = (value) => {
        setProfileBackgroundColor(value);
    }

    const handleImageUploaderOpen = () => {
        imageUploaderRef.current.click();
    }

    const handleSelectBanner = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            customToast('올바른 이미지 파일을 선택해 주세요.');
            return;
        }

        let fileSize = file.size;
        let extension = file.name.split('.').pop();

        if (fileSize > 5 * 1024 * 1024) {
            customToast.error('이미지 크기는 최대 5MB까지만 허용됩니다.');
            return;
        }

        customBackdropController.showBackdrop();

        const fileRef = ref(firebaseStorage, `/assets/user_pages/${userPageContextValueHook?.userPage?.id}/profile_banners/${uuidv4()}.${extension}`);

        const uploadedUrl = await uploadBytes(fileRef, file).then(async snapshot => {
            return await getDownloadURL(snapshot.ref)
                .then(url => {
                    return url
                })
        });
        customBackdropController.hideBackdrop();

        if (uploadedUrl) {
            setTopBannerImageUrl(uploadedUrl);
        }
    }

    const handleSelectBannerDefault = () => {
        setTopBannerImageUrl('');
    }

    const handleSetDefaultColors = () => {
        setDomainNameColor('#000000');
        setDescriptionColor('#606060');
        setProfileBackgroundColor('#ffffff');
    }

    const handleReqChangeUserPageDesigns = async () => {

        let body = {
            id: userPageContextValueHook.userPage?.id,
            domainDesignType: domainDesignType,
            domainNameColor: domainNameColor,
            descriptionColor: descriptionColor,
            profileBackgroundColor: profileBackgroundColor,
            topBannerImageUrl: topBannerImageUrl,
        };

        let headers = {};

        customBackdropController.showBackdrop();
        const result = await apiHook.reqChangeUserPage_designs({ body, headers });
        customBackdropController.hideBackdrop();
        if (result?.content) {
            userPageContextActionsHook.onSetUserPage(result?.content);
            onRefreshPreviewViewer();
            toggleEditDesignModalOpen(false);
        }

    }

    return (
        <>
            <CustomDialog open={open} onClose={() => toggleEditDesignModalOpen(false)}>
                <St.MdEditDesignModal.BodyContainer>
                    <div className='itemList'>
                        <div
                            className={`itemTypeBox ${(!domainDesignType || domainDesignType === 'CLASSIC') ? 'active' : ''}`}
                            onClick={() => handleChangeSelectedDomainDesignType('CLASSIC')}
                        >
                            <div className='header'>
                                <div className='title'>기본형</div>
                                {(!domainDesignType || domainDesignType === 'CLASSIC') &&
                                    <div className='checked'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src={'/assets/icons/check_default_green1.svg'} />
                                        </CustomRatioImage>
                                    </div>
                                }
                            </div>
                            <div className='classicType'>
                                <div className='profileLogo'></div>
                                <div className='domainName'>@도메인</div>
                                <div className='description'></div>
                                <div className='description'></div>
                                <div className='description'></div>
                            </div>
                        </div>
                        <div
                            className={`itemTypeBox ${(domainDesignType === 'BANNER') ? 'active' : ''}`}
                            onClick={() => handleChangeSelectedDomainDesignType('BANNER')}
                        >
                            <div className='header'>
                                <div className='title'>배너형</div>
                                {(domainDesignType === 'BANNER') &&
                                    <div className='checked'>
                                        <CustomRatioImage>
                                            <CustomRatioImage.Image src={'/assets/icons/check_default_green1.svg'} />
                                        </CustomRatioImage>
                                    </div>
                                }
                            </div>
                            <div className='bannerType'>
                                <div className='bannerBox'>
                                    배너
                                </div>
                                <div className='profileBox'>
                                    <div className='profileLogo'></div>
                                    <div className='domainName'>@도메인</div>
                                    <div className='description'></div>
                                    <div className='description'></div>
                                    <div className='description'></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='colorSetting'>
                        <CustomBlockButton
                            type='button'
                            className='defaultButton'
                            onClick={() => handleSetDefaultColors()}
                        >색상 초기화</CustomBlockButton>
                        <div className='domainName'>
                            <div className='title'>
                                도메인명
                            </div>
                            <div className='representedColor' onClick={() => toggleDomainNameColorChangeModeOpen(true)}>
                                <div className='colorBox' style={{ backgroundColor: domainNameColor }}></div>
                                <div className='colorHex'>{domainNameColor || '#000000'}</div>
                            </div>
                            {domainNameColorChangeModeOpen &&
                                <CustomColorPalette
                                    open={domainNameColorChangeModeOpen}
                                    fromColor={domainNameColor}
                                    toggleOpenClose={toggleDomainNameColorChangeModeOpen}
                                    onConfirm={handleChangeDomainNameColor}
                                />
                            }
                        </div>
                        <div className='domainName'>
                            <div className='title'>
                                페이지 설명
                            </div>
                            <div className='representedColor' onClick={() => toggleDescriptionColorChangeModeOpen(true)}>
                                <div className='colorBox' style={{ backgroundColor: descriptionColor }}></div>
                                <div className='colorHex'>{descriptionColor || '#000000'}</div>
                            </div>
                            {descriptionColorChangeModeOpen &&
                                <CustomColorPalette
                                    open={descriptionColorChangeModeOpen}
                                    fromColor={descriptionColor}
                                    toggleOpenClose={toggleDescriptionColorChangeModeOpen}
                                    onConfirm={handleChangeDescriptionColor}
                                />
                            }
                        </div>
                        {domainDesignType === 'BANNER' &&
                            <div className='domainName'>
                                <div className='title'>
                                    배경색
                                </div>
                                <div className='representedColor' onClick={() => toggleProfileBackgroundColorChangeModeOpen(true)}>
                                    <div className='colorBox' style={{ backgroundColor: profileBackgroundColor }}></div>
                                    <div className='colorHex'>{profileBackgroundColor || '#000000'}</div>
                                </div>
                                {profileBackgroundColorChangeModeOpen &&
                                    <CustomColorPalette
                                        open={profileBackgroundColorChangeModeOpen}
                                        fromColor={descriptionColor}
                                        toggleOpenClose={toggleProfileBackgroundColorChangeModeOpen}
                                        onConfirm={handleChangeProfileBackgroundColor}
                                    />
                                }
                            </div>
                        }
                    </div>
                    {domainDesignType === 'BANNER' &&
                        <div className='bannerSettingWrapper'>
                            <div className='header'>
                                <div className='title'>배너</div>
                                <div className='icon'>
                                    <CustomRatioImage>
                                        <CustomRatioImage.Image src={'/assets/icons/expandLess_000000.svg'} />
                                    </CustomRatioImage>
                                </div>
                            </div>
                            <div className='body'>
                                <div className='flexBox'>
                                    <div className='bannerImage'>
                                        <CustomRatioImage width={375} height={240}>
                                            <CustomRatioImage.Image src={topBannerImageUrl || '/assets/images/profileBannerImage_default.jpg'} />
                                        </CustomRatioImage>
                                    </div>
                                    <div className='bannerImageNoticeText'>권장 이미지 사이즈 750x480</div>
                                </div>
                            </div>
                            <div className='footer'>
                                <div className='buttonGroupWrapper'>
                                    <CustomBlockButton
                                        type='button'
                                        className='first'
                                        onClick={() => handleImageUploaderOpen()}
                                    >배너 선택</CustomBlockButton>
                                    <CustomBlockButton
                                        type='button'
                                        className='second'
                                        onClick={() => handleSelectBannerDefault()}
                                    >기본 배너로 변경</CustomBlockButton>
                                </div>
                            </div>
                        </div>
                    }
                </St.MdEditDesignModal.BodyContainer>
                <St.MdEditDesignModal.FooterContainer>
                    <div className='buttonGroupWrapper'>
                        <CustomBlockButton
                            type='button'
                            className='cancel'
                            onClick={() => toggleEditDesignModalOpen(false)}
                        >취소</CustomBlockButton>
                        <CustomBlockButton
                            type='button'
                            className='confirm'
                            onClick={() => handleReqChangeUserPageDesigns()}
                        >적용</CustomBlockButton>
                    </div>
                </St.MdEditDesignModal.FooterContainer>
            </CustomDialog>

            <input
                ref={imageUploaderRef}
                type="file"
                accept="image/*"
                onClick={(e) => e.target.value = ''}
                onChange={(e) => handleSelectBanner(e)}
                hidden
            />
        </>
    );
}