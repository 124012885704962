import styled from 'styled-components';

export const Container = styled.div`
    margin-bottom: 20px;
`;

export const BannerWrapper = styled.div`
    height: 240px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    img{
        object-fit: cover;
        height: 100%;
        width: 100%;
    }
`;

export const HeaderWrapper = styled.div`
    background-color: #ffffff;
    margin-top: -20px;
    box-shadow:var(--defaultBoxShadow3);
    max-width: 580px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 20px;
    border-top-right-radius:20px;
    border-top-left-radius:20px;
    z-index: 20;
    position: relative;
    padding: 20px 10px;
    box-sizing: border-box;

    .profileImageWrapper{
        width: 80px;
        margin-left: auto;
        margin-right: auto;
        border-radius: 100%;
        overflow: hidden;
    }

    .domainNameWrapper{
        text-align: center;
        font-weight: 800;
        font-size: 16px;
        word-wrap: break-word;
    }

    .descriptionWrapper{
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        color: #606060;

        word-wrap: break-word;
        word-break: keep-all;
        white-space: pre-line;
    }
`;