import styled from 'styled-components';

export const Container = styled.div`
    padding-top: 40px;
    max-width: 568px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 100px;
`;

export const Wrapper = styled.div`
    padding: 20px;
    border-radius: 15px;
    display: flex;
    gap: 40px;
    flex-direction: column;
`;

export const IntroduceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap:10px;
    .mainDescription{
        font-size: 24px;
        text-align: center;
        font-weight: 700;
        color: #000;
    }

    .subDescription{
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        color: #404040;
    }
`;

export const InputGroupWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    > .inputBox{
        > input{
            background-color: var(--defaultElementBC);
            border-width: 2px;
            border-style: solid;
            border-color: #00000000;
            font-weight: 600;

            &:focus{
                border-color: #444444;
            }
        }
    
        > .footerLabel{
            display: inline-block;
            margin-top: 5px;
            font-size: 12px;
            font-weight: 500;
        }
    }
    
`;

export const FooterButtonGroup = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    button{
        width: 100%;
        height: 48px;
        border-radius: 48px;
        border: none;
        background: #000;
        color: white;
        font-size: 14px;
        font-weight: 700;
    }
`;