import { ClickLogsApi } from "../../../../apis/ClickLogsApi";
import { DesignThemesApi } from "../../../../apis/DesignThemesApi";
import { LinkCategoriesApi } from "../../../../apis/LinkCategoriesApi";
import { LinkItemsApi } from "../../../../apis/LinkItemsApi";
import { NoticeItemsApi } from "../../../../apis/NoticeItemsApi";
import { UserPagesApi } from "../../../../apis/UserPagesApi";
import { VisitLogsApi } from "../../../../apis/VisitLogsApi";
import { useErrorHandler } from "../../../../apis/useErrorHandler";
import { tokenRefreshBeforeRequest } from "../../../../firebase/auth";

const userPagesApi = UserPagesApi();
const designThemesApi = DesignThemesApi();
const linkItemsApi = LinkItemsApi();
const linkCategoriesApi = LinkCategoriesApi();
const visitLogsApi = VisitLogsApi();
const clickLogsApi = ClickLogsApi();
const noticeItemsApi = NoticeItemsApi();

export function useApiHook(props) {
    const errorHandler = useErrorHandler();

    const reqUpdateUserPages = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await userPagesApi.update({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeUserPage_designs = async (args = { body: {}, headers: {} }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        args.headers = {
            ...args.headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await userPagesApi.changeOne_designs({ body: args.body, headers: args.headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqFetchDesignThemeList = async ({ params, headers }) => {
        return await designThemesApi.searchList({ params, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeUserPage_backgroundDesign = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await userPagesApi.changeOne_backgroundDesign({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeUserPage_viewCategoryFlag = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await userPagesApi.changeOne_viewCategoryFlag({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeUserPage_noticeConfig = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await userPagesApi.changeOne_noticeConfig({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeUserPage_searchConfig = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await userPagesApi.changeOne_searchConfig({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeUserPage_socialConfig = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await userPagesApi.changeOne_socialConfig({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqFetchLinkItemListByOwner = async ({ params, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkItemsApi.searchListByOwner({ params, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqCreateLinkItem = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkItemsApi.create({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqDeleteLinkItem = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkItemsApi.delete({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqUpdateLinkItemInfo = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkItemsApi.updateInfo({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqUpdateLinkItemIsHide = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkItemsApi.updateIsHide({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeLinkItems_orderNumber = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkItemsApi.changeList_orderNumber({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqFetchLinkCategoryList = async ({ params, headers }) => {
        return await linkCategoriesApi.searchListByUserPageId({ params, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqCreateLinkCategory = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkCategoriesApi.createOne({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqDeleteLinkCategory = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkCategoriesApi.deleteOne({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeLinkCategory_name = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkCategoriesApi.changeOne_name({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqChangeLinkCategories_orderNumber = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await linkCategoriesApi.changeList_orderNumber({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqCountVisitLogs = async ({ params, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await visitLogsApi.count({ params, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqCountClickLogs = async ({ body, headers }) => {
        const accessToken = await tokenRefreshBeforeRequest();

        headers = {
            ...headers,
            Authorization: `Bearer ${accessToken}`
        }

        return await clickLogsApi.count({ body, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqFetchNoticeItemList = async ({ params, headers }) => {
        return await noticeItemsApi.searchList({ params, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    return {
        reqUpdateUserPages,
        reqChangeUserPage_designs,
        reqFetchDesignThemeList,
        reqChangeUserPage_backgroundDesign,
        reqChangeUserPage_viewCategoryFlag,
        reqChangeUserPage_noticeConfig,
        reqChangeUserPage_searchConfig,
        reqChangeUserPage_socialConfig,
        reqFetchLinkItemListByOwner,
        reqCreateLinkItem,
        reqDeleteLinkItem,
        reqUpdateLinkItemIsHide,
        reqUpdateLinkItemInfo,
        reqChangeLinkItems_orderNumber,
        reqFetchLinkCategoryList,
        reqCreateLinkCategory,
        reqDeleteLinkCategory,
        reqChangeLinkCategory_name,
        reqChangeLinkCategories_orderNumber,
        reqCountVisitLogs,
        reqCountClickLogs,
        reqFetchNoticeItemList
    }
}