import { useEffect, useState } from "react";
import { NoticeModal } from "../../../../../components/notice-modal/NoticeModal";
import { useApiHook } from "../../hooks/useApiHook";
import Cookies from 'js-cookie';
import { GlobalCookieVariables } from "../../../../../variables/GlobalCookieVariables";
import { CustomCookieUtils } from "../../../../../utils/CustomCookieUtils";

const NOTICE_PATH = '/mypage';

const getCookieExpiresMS = () => {
    const now = new Date();
    const midnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + 1, // 다음 날 0시
        0, 0, 0
    );

    return (midnight - now) / 1000;
}

const isShowNotice = () => {
    const cookieData = CustomCookieUtils.getCookieData(GlobalCookieVariables.SHOOLUK_NOTICE_CLOSE);
    const noticePaths = cookieData?.noticePaths

    if (!noticePaths || noticePaths?.length <= 0) {
        return true;
    }

    if (!noticePaths?.includes(NOTICE_PATH)) {
        return true;
    }

    return false;
}

export function WegetNotice(props) {
    const apiHook = useApiHook();

    const [noticeModalOpen, setNoticeModalOpen] = useState(false);
    const [noticeItemList, setNoticeItemList] = useState(null);


    useEffect(() => {
        if (isShowNotice()) {
            reqFetchNoticeItemList();
        }
    }, []);

    const reqFetchNoticeItemList = async () => {
        let params = {
            noticePath: NOTICE_PATH
        }
        const result = await apiHook.reqFetchNoticeItemList({ params, headers: null })

        if (result?.content) {
            setNoticeItemList(result?.content);
            if (result?.content?.length > 0) {
                handleOpenNoticeModal();
            }
        }
    }

    const handleOpenNoticeModal = () => {
        setNoticeModalOpen(true);
    }

    const handleCloseNoticeModal = () => {
        setNoticeModalOpen(false);
    }

    const handleCloseToday = () => {
        let cookieData = CustomCookieUtils.getCookieData(GlobalCookieVariables.SHOOLUK_NOTICE_CLOSE);
        let noticePaths = cookieData?.noticePaths;
        let newNoticePath;

        if (!noticePaths || noticePaths?.length <= 0) {
            newNoticePath = [NOTICE_PATH];
        } else {
            newNoticePath = noticePaths?.includes(NOTICE_PATH) ? noticePaths : [...noticePaths, NOTICE_PATH];
        }

        Cookies.set(GlobalCookieVariables.SHOOLUK_NOTICE_CLOSE, JSON.stringify({
            noticePaths: newNoticePath,
        }), { expires: getCookieExpiresMS() / 86400 });
        handleCloseNoticeModal();
    }

    if (noticeItemList === null) {
        return <></>;
    }

    return (
        <>
            <NoticeModal
                open={noticeModalOpen}
                noticeItemList={noticeItemList || []}
                onClose={handleCloseNoticeModal}
                onCloseToday={handleCloseToday}
            />
        </>
    );
}