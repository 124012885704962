import { TopNavBar } from "../../layouts/TopNavBar/TopNavBar";
import { FdIntroduction } from "./components/FdIntroduction/FdIntroduction";

export function MainView(props) {
    return (
        <>
            <TopNavBar />
            <FdIntroduction />
        </>
    );
}