import { customToast } from "../components/toast/custom-react-toastify/v1";
import { useUserContextActionsHook } from "../contexts/UserContextProvider";

export function useErrorHandler(err) {
    const userContextActionsHook = useUserContextActionsHook();

    return {
        run: (err) => {
            if (!err) {
                customToast.error('서버와의 연결이 원할하지 않습니다.');
                return;
            }

            let res = err.response;

            if (res?.status === 401) {
                userContextActionsHook.onClear();
            }

            customToast.error(res?.data?.message);
        }
    }

}