import { LinkCategoriesApi } from "../../../../apis/LinkCategoriesApi";
import { LinkItemsApi } from "../../../../apis/LinkItemsApi";
import { UserPagesApi } from "../../../../apis/UserPagesApi";
import { useErrorHandler } from "../../../../apis/useErrorHandler";

const userPagesApi = UserPagesApi();
const linkItemsApi = LinkItemsApi();
const linkCategoriesApi = LinkCategoriesApi();

export function useApiHook(props) {
    const errorHandler = useErrorHandler();

    const reqFetchUserPage = async ({ params, headers }) => {
        return await userPagesApi.searchByDomainName({ params, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqFetchLinkItemList = async ({ params, headers }) => {
        return await linkItemsApi.searchListByGuest({ params, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    const reqFetchLinkCategoryList = async ({ params, headers }) => {
        return await linkCategoriesApi.searchListByUserPageId({ params, headers })
            .then(res => {
                return {
                    res: res,
                    content: res?.data?.content
                }
            })
            .catch(err => {
                errorHandler.run(err);
            })
            ;
    }

    return {
        reqFetchUserPage,
        reqFetchLinkItemList,
        reqFetchLinkCategoryList
    }
}