import {
    Chart as ChartJS
} from "chart.js/auto";
import { Line } from 'react-chartjs-2';
import * as St from './FdInflowAnalytics.styled';
import { CustomChart } from "../../../../../components/chart/CustomChart";
import { useEffect, useState } from "react";
import { CustomDateTimeUtils } from "../../../../../utils/CustomDateTimeUtils";
import { useUserPageContextValueHook } from "../../../../../contexts/UserPageContextProvider";
import { useApiHook } from "../../hooks/useApiHook";
import moment from "moment";
import { FgDateRangeInflowChart } from "./fragments/FgDateRangeInflowChart";
import { FgTotalSummary } from "./fragments/FgTotalSummary";
import { FgReferrerInflowDetailChart } from "./fragments/FgReferrerInflowDetailChart";

const customDateTimeUtils = CustomDateTimeUtils();

export function FdInflowAnalytics(props) {
    const currentDate = new Date();
    const startDate = moment(new Date(currentDate)).add(-30, 'days').toDate();

    const localUtcOffset = customDateTimeUtils.getLocalUtcOffset();

    const apiHook = useApiHook();

    const userPageContextValueHook = useUserPageContextValueHook();

    const [startDateTime, setStartDateTime] = useState(customDateTimeUtils.getStartDate(startDate));
    const [endDateTime, setEndDateTime] = useState(currentDate);
    const [localeDiffHours, setLocaleDiffHours] = useState(localUtcOffset.hoursOffset);
    const [localeDiffMinutes, setLocaleDiffMinutes] = useState(localUtcOffset.minutesOffset);
    const [visitLogList, setVisitLogList] = useState(null);
    const [targetVisitLog, setTargetVisitLog] = useState(null);

    const handleChangeTargetVisitLog = (item) => {
        setTargetVisitLog(item);
    }

    useEffect(() => {
        if (userPageContextValueHook?.userPage?.isLoading || !userPageContextValueHook?.userPage?.id) {
            return;
        }

        async function init() {

            const fetchResult = await apiHook.reqCountVisitLogs({
                params: {
                    userPageId: userPageContextValueHook?.userPage?.id,
                    startDateTime: startDateTime,
                    endDateTime: endDateTime,
                    localeDiffHours: localeDiffHours,
                    localeDiffMinutes: localeDiffMinutes
                }
            });

            if (fetchResult?.content) {
                setVisitLogList(fetchResult?.content);
            }
        }
        init();
    }, [userPageContextValueHook?.userPage?.isLoading, userPageContextValueHook?.userPage?.id]);


    if (!visitLogList) {
        return null;
    }

    return (
        <>
            <St.Container>
                <FgTotalSummary
                    visitLogList={visitLogList}
                />
                <FgDateRangeInflowChart
                    visitLogList={visitLogList}
                    onChangeTargetVisitLog={handleChangeTargetVisitLog}
                />
                <FgReferrerInflowDetailChart
                    targetVisitLog={targetVisitLog}
                />
            </St.Container>
        </>
    );
}