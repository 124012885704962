import styled from 'styled-components';
import Ripple from '../../ripple/v1/Ripple';
import React from 'react';

const Button = styled.button`
    user-select: none;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1;
    width: 100%;
    background: white;
    border:1px solid #e0e0e0;
    height: 48px;
    font-size: 14px;
    font-weight: 500;
    box-sizing: border-box;
    color:#323232;
    cursor: pointer;
    -webkit-tap-highlight-color: #00000000;
    outline: none;
    margin:0;
    padding:0;
    text-align: unset;

    &:hover{
        color:${(props) => props.$hoverColor && props.$hoverColor};
        background:${(props) => props.$hoverBackground && props.$hoverBackground};
    }

    &:disabled{
        cursor: not-allowed;
        opacity: 0.7;
    }
`;

/**
 * V2
 */
const CustomBlockButton = React.forwardRef(function CustomBlockButton({
    children,
    hoverColor,
    hoverBackground,
    rippleColor,
    rippleDuration,
    ...props
}, ref) {
    return (
        <Button
            ref={ref}
            $hoverColor={hoverColor}
            $hoverBackground={hoverBackground}
            {...props}
        >
            {children}
            <Ripple color={rippleColor || '#e0e0e0'} duration={rippleDuration || 1000} />
        </Button>
    );
})

export default CustomBlockButton;

/**
 * V1
 * @deprecated
 */
// export default function CustomBlockButtonV1({
//     children,
//     hoverColor,
//     hoverBackground,
//     rippleColor,
//     rippleDuration,
//     ...props
// }) {
//     return (
//         <Button
//             $hoverColor={hoverColor}
//             $hoverBackground={hoverBackground}
//             {...props}
//         >
//             {children}
//             <Ripple color={rippleColor || '#e0e0e0'} duration={rippleDuration || 1000} />
//         </Button>
//     );
// }