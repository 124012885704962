import { useEffect, useState } from 'react';
import CustomBlockButton from '../../../components/button/CustomBlockButton';
import { CustomInput } from '../../../components/input/CustomInput';
import * as St from './index.styled';
import { useApiHook } from './hooks/useApiHook';
import { useUserContextValueHook } from '../../../contexts/UserContextProvider';
import { useNavigate } from 'react-router-dom';
import { CustomBackdropController } from '../../../components/backdrop/default/v1';
import { useUserPageContextActionsHook } from '../../../contexts/UserPageContextProvider';

const customBackdropController = CustomBackdropController();

export function MainView(props) {
    const navigate = useNavigate();

    const userContextValueHook = useUserContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();

    const userUid = userContextValueHook?.user?.uid;

    const apiHook = useApiHook();

    const [domainName, setDomainName] = useState('');

    useEffect(() => {
        if (!userUid) {
            navigate('/login', { replace: true })
        }
    }, [userUid]);


    const handleChangeDomainNameFromEvent = (e) => {
        let value = e.target.value;

        if (value?.length > 20) {
            return;
        }

        setDomainName(value);
    }

    const handleReqCreateUserPage = async (e) => {
        e.preventDefault();

        let regex = /^[a-z_0-9]{3,20}$/
        if (!regex.test(domainName)) {
            alert('3~20자 내외 영문 소문자[a-z], 숫자[0-9], underscore[_] 특수문자만 허용 됩니다.');
            return;
        }

        let body = {
            domainName: domainName
        }

        customBackdropController.showBackdrop()
        const createResult = await apiHook.reqCreateUserPage({ body: body })
        customBackdropController.hideBackdrop()

        if (createResult?.content) {
            userPageContextActionsHook.onSetUserPage({
                ...createResult?.content
            })
            navigate('/', { replace: true });
        }
    }

    if (userContextValueHook?.isLoading) {
        return null;
    }

    return (
        <>
            <St.Container>
                <form onSubmit={(e) => handleReqCreateUserPage(e)}>
                    <St.Wrapper>
                        <St.IntroduceWrapper>
                            <div className='mainDescription'>지금 바로 마이 페이지를 생성해 보세요!</div>
                            <div className='subDescription'>마이 페이지를 생성하고 여러 링크를 공유해 보세요.</div>
                        </St.IntroduceWrapper>
                        <St.InputGroupWrapper>
                            <div className='inputBox'>
                                <CustomInput
                                    type='text'
                                    value={domainName || ''}
                                    onChange={(e) => handleChangeDomainNameFromEvent(e)}
                                    placeholder='페이지 도메인'
                                />
                                <label className='footerLabel'>3~20자 내외 영문 소문자[a-z], 숫자[0-9], underscore[_] 특수문자만 허용 됩니다.</label>
                            </div>
                        </St.InputGroupWrapper>
                        <St.FooterButtonGroup>
                            <CustomBlockButton>페이지 생성</CustomBlockButton>
                        </St.FooterButtonGroup>
                    </St.Wrapper>
                </form>
            </St.Container>
        </>
    );
}