import styled from 'styled-components';

export const Container = styled.div`

`;

export const Wrapper = styled.div`
    gap: 10px;

    .flexBox{
        display: flex;
        flex-direction: row;

        &.justifyContent-spaceBetween{
            justify-content: space-between;
        }

        &.gap-20{
            gap: 20px;
        }
    }
`;

export const CardWrapper = styled.div`
    display: flex;
    gap: 40px;

    @media all and (max-width:768px){
        flex-direction: column;
        margin-top: 20px;
    }
`;

export const CardBox = styled.div`
    /* border: 1px solid #f0f0f0; */
    flex:1;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media all and (max-width:768px){
        flex-direction: row;
        justify-content: space-evenly;
        .lineBreaker{
            display: none;
        }

        h3{
            font-size: 16px;
        }
    }

    .lineBreaker{
        height: 1px;
        background-color: #f0f0f0;
    }

    h3{
        margin: 0;
    }

    h5{
        margin: 0;
    }

    span{
        color: #90EE90;
    }
`;

export const ChartWrapper = styled.div`
    display: flex;  
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    margin-top: 40px;

    @media all and (max-width:768px){
        flex-direction: column;
    }

    .chartBox{
        /* flex:1; */
        width: 300px;
        margin-left: auto;
        margin-right: auto;
    }
`;