import { getAuth } from "firebase/auth";
import { getApp } from "./firebaseConfig";

const auth = getAuth(getApp());

export default auth;

export {
    tokenRefreshBeforeRequest
}

async function tokenRefreshBeforeRequest(callback = (accessToken) => { }) {
    if (!auth.currentUser) {
        return null;
    }

    return await auth.currentUser.getIdToken()
        .then(idToken => {
            callback(idToken);
            return idToken;
        })
        .catch(err => {
            return null;
        })
}