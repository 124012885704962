import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, -5px);
    button{
        width: 100px;
        border-radius: 40px;
        border: 1px solid #f0f0f0;
        font-weight: 700;
        box-shadow: var(--defaultBoxShadow);
    }
`;

export const MdPreviewContainer = styled.div`
    /* width: 100%; */
    /* min-height: 100vh; */
`;

export const MdPreviewWrapper = styled.div`
    /* position: relative; */
    /* top: 0;
    right: 0;
    bottom: 0; */
    /* width: inherit; */
    /* height: 100vh; */
    border-left: 1px solid #e0e0e0;
`;

export const MdPreviewDeviceFrameContainer = styled.div`
    position: fixed;
    background: #fff;
    box-sizing: content-box;
    overflow: hidden;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    width: 100%;
    height: 100%;
    z-index: 100;
    border: 5px solid #000000;
    box-sizing: border-box;
    border-radius: 15px;

    .floatingClose{
        position: absolute;
        top: 10px;
        right: 10px;
        
        button{
            width: 50px;
            height: 50px;
            border-radius: 30px;
            background: #00000080;
            border: none;

            .iconFigure{
                width: 60%;
                height: 60%;
            }
        }
    }

    iframe{
        /* position: absolute; */
        width: 100%;
        height: 100%;
        border: none; /* 필요에 따라 border 제거 */
        cursor: not-allowed;

    }
`;

export const MdPreviewDeviceFrameWrapper = styled.div`
`;