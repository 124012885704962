import * as St from './FdEditor.styled';
import { SubFdCategory } from './components/SubFdCategory/SubFdCategory';
import { SubFdDesignEditor } from './components/SubFdDesignEditor/SubFdDesignEditor';
import { SubFdDomainInformation } from './components/SubFdDomainInformation/SubFdDomainInformation';
import { SubFdElements } from './components/SubFdElements/SubFdElements';

export function FdEditor({
    onRefreshPreviewViewer
}) {
    return (
        <>
            <St.Container>
                <SubFdDomainInformation onRefreshPreviewViewer={() => onRefreshPreviewViewer()} />
                <SubFdDesignEditor onRefreshPreviewViewer={() => onRefreshPreviewViewer()} />
                <SubFdCategory onRefreshPreviewViewer={() => onRefreshPreviewViewer()} />
                <SubFdElements onRefreshPreviewViewer={() => onRefreshPreviewViewer()} />
            </St.Container>
        </>
    );
}