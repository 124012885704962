import { useEffect, useRef } from "react";
import { TopNavBar } from "../../../layouts/TopNavBar/TopNavBar";
import { FdEditor } from "./components/FdEditor/FdEditor";
import { FdViewer } from "./components/FdViewer/FdViewer";
import * as St from './index.styled';
import { useMediaQuery } from "@mui/material";
import { FdLinkAlert } from "./components/FdLinkAlert/FdLinkAlert";
import { LinkCategoryContextProvider } from "./contexts/LinkCategoryContextProvider";
import { FdTab } from "./components/FdTab/FdTab";
import { useQueryParamsHook } from "../../../hooks/useQueryParamsHook";
import { FdInflowAnalytics } from "./components/FdInflowAnalytics/FdInflowAnalytics";
import { WegetNotice } from "./components/weget-notice/WegetNotice";
import { FdDesignSetting } from "./components/FdDesignSetting/FdDesignSetting";
import { CustomDialog } from "../../../components/dialog/v1/CustomDialog";
import { FdMobilePreview } from "./components/FdMobilePreview/FdMobilePreview";

export function MainView(props) {
    return (
        <LinkCategoryContextProvider>
            <MainViewCore />
        </LinkCategoryContextProvider>
    );
}

function MainViewCore() {
    const queryParamsHook = useQueryParamsHook();
    const tabType = queryParamsHook?.params?.tabType;

    const previewRef = useRef();
    const mobileView = useMediaQuery('(max-width: 992px)');

    const handleRefreshPreviewViewer = () => {
        if (!mobileView) {
            previewRef.current.src += '';
        }
    }

    const tabTypeList = TAB_LIST?.map(r => r.type);

    return (
        <>
            <TopNavBar />
            <St.Container>
                <FdLinkAlert />
                <FdTab
                    TAB_LIST={TAB_LIST}
                />
                {tabType === 'INFLOW_ANALYTICS' &&
                    <FdInflowAnalytics />
                }
                {tabType === 'DESIGN_SETTING' &&
                    <FdDesignSetting
                        onRefreshPreviewViewer={handleRefreshPreviewViewer}
                    />
                }

                {(!tabType || tabType === 'PAGE_MANAGE' || !tabTypeList?.includes(tabType)) &&
                    <FdEditor
                        onRefreshPreviewViewer={() => handleRefreshPreviewViewer()}
                    />
                }
                {!mobileView &&
                    <FdViewer
                        ref={previewRef}
                    />
                }
                {mobileView &&
                    <FdMobilePreview />
                }
            </St.Container>
            <WegetNotice />
        </>
    );
}

const TAB_LIST = [
    {
        type: 'PAGE_MANAGE',
        name: '페이지 관리',
        default: true,
    },
    {
        type: 'DESIGN_SETTING',
        name: '디자인 설정',
        default: false,
    },
    {
        type: 'INFLOW_ANALYTICS',
        name: '유입분석',
        default: false,
    },
]