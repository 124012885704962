import { CustomRatioImage } from '../../../../../components/image/CustomRatioImage';
import ElementLoading from '../../../../../components/loading/ElementLoading/ElementLoading';
import * as St from './FdPageLoading.styled';

export function FdPageLoading(props) {
    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <St.HeaderWrapper>
                        <div className='profileImageWrapper'>
                            <CustomRatioImage width={1} height={1}>
                                <ElementLoading
                                    className='imageLoading'
                                />
                            </CustomRatioImage>
                        </div>
                        <div className='domainNameWrapper'>
                            <ElementLoading
                                className='fakeBox'
                            />
                        </div>
                        <div className='descriptionWrapper'>
                            <ElementLoading
                                className='fakeBox'
                            />
                        </div>
                    </St.HeaderWrapper>
                    <St.LinkItemsContainer>
                        <St.LinkItemsWrapper>
                            <div className='linkItemWrapper'>
                                <ElementLoading
                                    className='fakeBox'
                                />
                            </div>

                            <div className='linkItemWrapper'>
                                <ElementLoading
                                    className='fakeBox'
                                />
                            </div>
                            <div className='linkItemWrapper'>
                                <ElementLoading
                                    className='fakeBox'
                                />
                            </div>
                            <div className='linkItemWrapper'>
                                <ElementLoading
                                    className='fakeBox'
                                />
                            </div>
                        </St.LinkItemsWrapper>
                    </St.LinkItemsContainer>
                </St.Wrapper>
            </St.Container>
        </>
    );
}