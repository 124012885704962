import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    width: 435px;
    top: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
`;

export const Wrapper = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: inherit;
    height: 100vh;
    border-left: 1px solid #e0e0e0;
`;

export const DeviceFrameContainer = styled.div`
    position: absolute;
    width: 352px;
    height: 724px;
    border-color: #202020;
    border-style: solid;
    border-width: 15px;
    border-radius: 40px;
    background: #fff;
    box-sizing: content-box;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: top left;
    overflow: hidden;

    iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        border: none; /* 필요에 따라 border 제거 */
        cursor: not-allowed;

    }
`;

export const DeviceFrameWrapper = styled.div`
`;