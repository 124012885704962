import Cookies from 'js-cookie';

export const CustomCookieUtils = {
    getCookieData: (cookieName) => {
        const cookie = Cookies.get(cookieName);

        if (!cookie) {
            return null; // 쿠키가 존재하지 않는 경우 null 반환
        }

        try {
            return JSON.parse(cookie);
        } catch (err) {
            Cookies.remove(cookieName);
            return null;
        }
    }
}