import { useLocation } from 'react-router-dom';
import * as St from './index.styled';
import { useApiHook } from './hooks/useApiHook';
import { useEffect, useState } from 'react';
import { FdLinkItems } from './components/FdLinkItems.js/FdLinkItems';
import { FdSearch } from './components/FdSearch/FdSearch';
import { Helmet } from 'react-helmet-async';
import { useQueryParamsHook } from '../../../hooks/useQueryParamsHook';
import { FdPageLoading } from './components/FdPageLoading/FdPageLoading';
import { useLinkItemListHook } from './hooks/useLinkItemListHook';
import { FdHeaderBanner } from './components/FdHeaderBanner/FdHeaderBanner';
import { FdHeaderClassic } from './components/FdHeaderClassic/FdHeaderClassic';
import { FdLinkCategory } from './components/FdLinkCategory/FdLinkCategory';
import { FdSocialLink } from './components/FdSocialLink/FdSocialLink';

export function MainView(props) {
    const location = useLocation();
    const pathDomainName = location?.pathname?.split('/preview/')?.filter(r => r !== '')[0];
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook.params?.lcId;

    const apiHook = useApiHook();
    const linkItemListHook = useLinkItemListHook();

    const [userPage, setUserPage] = useState({
        content: null,
        isLoading: true
    });
    const searchConfig = userPage?.content?.searchConfig;

    useEffect(() => {
        if (!pathDomainName) {
            return;
        }

        async function init() {
            const result = await apiHook.reqFetchUserPage({ params: { domainName: pathDomainName } });

            if (result?.content) {
                setUserPage({
                    content: result?.content,
                    isLoading: false
                });
            } else {
                setUserPage({
                    content: null,
                    isLoading: false
                });
                linkItemListHook.setContent(null);
                linkItemListHook.setIsLoading(false);
            }
        }

        init();

    }, [pathDomainName]);

    useEffect(() => {
        if (userPage?.isLoading || !userPage?.content) {
            return;
        }

        async function init() {
            await handleReqFetchLinkItemList({ mode: 'init' });
        }

        init();
    }, [userPage?.isLoading, userPage?.content, lcId])

    /*
        mode가 init 또는 searchQuery일 경우 최초 조회 api 형태를 유지해야 한다.
        mode가 default의 경우 조회 된 데이터를 이어 붙인다.
    */
    const handleReqFetchLinkItemList = async (req = {
        mode: 'default', // [default,init,searchQuery]
        searchQuery: null
    }) => {
        const { mode, searchQuery } = req;

        if (mode === 'searchQuery') {
            linkItemListHook.setCurrentSearchQuery(searchQuery);
        }

        let params = {
            userPageId: userPage?.content?.id,
            lcId: lcId,
            offset: ['init', 'searchQuery'].includes(mode) ? linkItemListHook?.INIT_OFFSET : linkItemListHook?.offset,
            limit: ['init', 'searchQuery'].includes(mode) ? linkItemListHook?.INIT_LIMIT : linkItemListHook?.limit,
            searchQuery: mode === 'searchQuery' ? searchQuery : linkItemListHook?.currentSearchQuery
        }

        const result = await apiHook.reqFetchLinkItemList({ params: params })
        if (result?.content) {
            linkItemListHook.setIsLoading(false);
            linkItemListHook.setHasMore(result?.content?.length > 0);
            linkItemListHook.setLimit(linkItemListHook.DEFAULT_LIMIT);

            linkItemListHook.setContent(
                ['init', 'searchQuery'].includes(mode) ?
                    result?.content
                    :
                    prev => !prev ? result?.content : [...prev, ...result?.content]
            );
            linkItemListHook.setOffset(
                ['init', 'searchQuery'].includes(mode) ?
                    result?.content?.length
                    :
                    prev => prev + result?.content?.length
            );
        } else {
            linkItemListHook.setContent(null);
            linkItemListHook.setIsLoading(false);
            linkItemListHook.setOffset(linkItemListHook.INIT_OFFSET);
            linkItemListHook.setLimit(linkItemListHook.INIT_LIMIT);
            linkItemListHook.setHasMore(false);
        }
    }

    if (userPage?.isLoading || !linkItemListHook?.content) {
        return (
            <FdPageLoading />
        )
    }

    if (!userPage?.isLoading && !userPage?.content) {
        return (
            <>페이지를 찾을 수 없습니다.</>
        );
    }

    return (
        <>
            {userPage?.content &&
                <Helmet>
                    <meta name="description" content={userPage?.content?.domainName}></meta>
                    <meta property="og:title" content={`@${userPage?.content?.domainName} | Shooluk`}></meta>
                    <meta property="og:description" content={userPage?.content?.description}></meta>
                    <meta property="og:url" content={`https://shool.uk/${userPage?.content?.domainName}`}></meta>
                    <meta property="og:image" content="https://shool.uk/assets/images/shooluk_logo.png"></meta>
                    <meta property="og:image:secure_url" content="https://shool.uk/assets/images/shooluk_logo.png"></meta>
                    <meta property="profile:username" content={userPage?.content?.domainName}></meta>
                    <title>{`@${userPage?.content?.domainName} | Shooluk`}</title>
                </Helmet>
            }
            <St.Container
                $backgroundType={userPage?.content?.backgroundType || 'HEX'}
                $backgroundImageUrl={userPage?.content?.backgroundImageUrl || ''}
                $backgroundColorHex={userPage?.content?.backgroundColorHex || '#ffffff'}
            >
                <St.Wrapper>
                    {(!userPage?.content?.domainDesignType || userPage?.content?.domainDesignType === 'CLASSIC') &&
                        <FdHeaderClassic
                            userPage={userPage}
                        />
                    }
                    {(userPage?.content?.domainDesignType === 'BANNER') &&
                        <FdHeaderBanner
                            userPage={userPage}
                        />
                    }
                    {(
                        userPage?.content?.socialConfig?.viewFlag &&
                        userPage?.content?.socialConfig?.position === 'TOP' &&
                        userPage?.content?.socialConfig?.socialList &&
                        userPage?.content?.socialConfig?.socialList?.length > 0
                    ) &&
                        <FdSocialLink
                            userPage={userPage?.content}
                        />
                    }
                    <FdLinkCategory
                        userPage={userPage?.content}
                    />
                    <FdLinkItems
                        linkItemList={linkItemListHook?.content}
                        hasMore={linkItemListHook?.hasMore}
                        onReqFetchLinkItemList={handleReqFetchLinkItemList}
                    />
                    {searchConfig?.viewFlag &&
                        <FdSearch
                            placeholder={searchConfig?.placeholder}
                            onReqFetchLinkItemList={handleReqFetchLinkItemList}
                        />
                    }
                    {(
                        userPage?.content?.socialConfig?.viewFlag &&
                        userPage?.content?.socialConfig?.position === 'BOTTOM' &&
                        userPage?.content?.socialConfig?.socialList &&
                        userPage?.content?.socialConfig?.socialList?.length > 0
                    ) &&
                        <FdSocialLink
                            style={{ marginTop: '40px' }}
                            userPage={userPage?.content}
                        />
                    }
                </St.Wrapper>
            </St.Container>
        </>
    );
}