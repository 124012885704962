import { useState } from 'react';
import { CustomPopover, useCustomPopoverHook } from '../../../../../../../../../components/popover/CustomPopover';
import { useLinkCategoryContextValueHook } from '../../../../../../contexts/LinkCategoryContextProvider';
import * as St from './WegetTextItem.styled';
import { Draggable } from 'react-beautiful-dnd';
import { Switch, Tooltip } from '@mui/material';
import CustomBlockButton from '../../../../../../../../../components/button/CustomBlockButton';
import { DeleteIcon } from '../../../../../../../../../components/svg-icons/DeleteIcon';
import { CustomRatioImage } from '../../../../../../../../../components/image/CustomRatioImage';
import { useUserPageContextValueHook } from '../../../../../../../../../contexts/UserPageContextProvider';
import { customToast } from '../../../../../../../../../components/toast/custom-react-toastify/v1';
import { CustomDialog } from '../../../../../../../../../components/dialog/v1/CustomDialog';
import { CustomLabelTextarea } from '../../../../../../../../../components/textarea/CustomLabelTextarea';
import CustomSelect from '../../../../../../../../../components/select/default/v1/CustomSelect';

export function WegetTextItem({
    moduleItemIndex,
    moduleItem,
    onReqDelete,
    onReqChangeIsHide,
    onReqUpdateInfo
}) {
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();
    const descriptionPopoverHook = useCustomPopoverHook();
    const thumbnailPopoverHook = useCustomPopoverHook();
    const deletePopoverHook = useCustomPopoverHook();

    const [updateLinkItemModalOpen, setUpdateLinkItemModalOpen] = useState(false);

    const textConfig = moduleItem?.textConfig;

    const toggleUpdateLinkItemModalOpen = (bool) => {
        setUpdateLinkItemModalOpen(bool);
    }

    const handleDelete = async () => {
        deletePopoverHook.closePopover();
        await onReqDelete(moduleItemIndex);
    }

    const handleSubmitUpdateLinkItem = (updateForm) => {
        updateForm = {
            id: moduleItem?.id,
            ...updateForm
        }

        onReqUpdateInfo(updateForm);
        toggleUpdateLinkItemModalOpen(false);
    }
    return (
        <>
            <Draggable key={moduleItem.id} draggableId={`SubFdElements-${moduleItem.id}`} index={moduleItemIndex}>
                {(draggableProvided) => (
                    <St.ModuleContainer
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                    >
                        <div className='deleteButtonField'>
                            <Tooltip title='삭제'>
                                <CustomBlockButton
                                    type='button'
                                    onClick={(e) => deletePopoverHook.openPopover(e, moduleItemIndex)}
                                >
                                    <DeleteIcon />
                                </CustomBlockButton>
                            </Tooltip>
                        </div>
                        <section className='first'>
                            <div className='icon' {...draggableProvided.dragHandleProps}>
                                <CustomRatioImage width={1} height={1}>
                                    <CustomRatioImage.Image src='/assets/icons/dragIndicator_808080.svg' />
                                </CustomRatioImage>
                            </div>
                        </section>
                        <section className='second'>
                            <section className='second_1'>
                                <section className='second_1_1'>
                                    <div className='badgeBox'>
                                        <div className='iconFigure'>
                                            <CustomRatioImage>
                                                <CustomRatioImage.Image src='/assets/icons/text_default_mainColor.svg' />
                                            </CustomRatioImage>
                                        </div>
                                        <div className='badge'>텍스트</div>
                                    </div>
                                    <div className='title'>{textConfig?.text}</div>
                                </section>
                                <section className='second_1_2'>
                                    <Tooltip title='수정'>
                                        <CustomBlockButton
                                            type='button'
                                            onClick={() => toggleUpdateLinkItemModalOpen(true)}
                                        >
                                            <div className='icon'>
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src='/assets/icons/edit2_000000.svg' />
                                                </CustomRatioImage>
                                            </div>
                                        </CustomBlockButton>
                                    </Tooltip>
                                    <div className='switchBox'>
                                        <Tooltip title='보이기 or 숨기기'>
                                            <Switch size='small' color='success' onChange={e => onReqChangeIsHide(moduleItemIndex)} checked={!moduleItem?.isHide} />
                                        </Tooltip>
                                    </div>
                                </section>
                            </section>
                            <section className='second_2'>
                                <section className='second_2_1'>
                                </section>
                                {moduleItem?.linkCategoryId &&
                                    <section className='second_2_2'>
                                        <div className='category'>
                                            {linkCategoryContextValueHook?.content?.find(r => r.id === moduleItem?.linkCategoryId)?.name}
                                        </div>
                                    </section>
                                }
                            </section>
                        </section>
                        {draggableProvided.placeholder}
                    </St.ModuleContainer>
                )}
            </Draggable>
            {descriptionPopoverHook?.anchorEl &&
                <CustomPopover
                    anchorEl={descriptionPopoverHook?.anchorEl}
                    onClose={() => descriptionPopoverHook?.closePopover()}
                    layoutStyle={!descriptionPopoverHook?.element ? { color: '#808080' } : {}}
                >
                    {descriptionPopoverHook?.element ? descriptionPopoverHook?.element : '등록된 설명이 없습니다.'}
                </CustomPopover>
            }

            {thumbnailPopoverHook?.anchorEl &&
                <CustomPopover
                    anchorEl={thumbnailPopoverHook?.anchorEl}
                    onClose={() => thumbnailPopoverHook?.closePopover()}
                    layoutStyle={!thumbnailPopoverHook?.element ? { color: '#808080' } : {}}
                >
                    {thumbnailPopoverHook?.element ?
                        (
                            <St.ThumbnailPopoverElement>
                                <CustomRatioImage>
                                    <CustomRatioImage.Image src={thumbnailPopoverHook?.element} />
                                </CustomRatioImage>
                            </St.ThumbnailPopoverElement>
                        )
                        :
                        '등록된 썸네일이 없습니다.'
                    }
                </CustomPopover>
            }
            <CustomPopover
                anchorEl={deletePopoverHook?.anchorEl}
                onClose={() => deletePopoverHook?.closePopover()}
                customLayout
            >
                <St.DeletePopoverElement>
                    <div className='notice'>삭제 버튼을 누르면 영구적으로 삭제됩니다.</div>
                    <CustomBlockButton
                        type='button'
                        onClick={() => handleDelete()}
                    >
                        삭제
                    </CustomBlockButton>
                </St.DeletePopoverElement>
            </CustomPopover>

            {(updateLinkItemModalOpen && moduleItem) &&
                <UpdateModuleModal
                    open={updateLinkItemModalOpen}
                    moduleItem={moduleItem}
                    toggleOpenClose={toggleUpdateLinkItemModalOpen}
                    onConfirm={(body) => handleSubmitUpdateLinkItem(body)}
                />
            }
        </>
    );
}

function UpdateModuleModal({
    open,
    moduleItem,
    toggleOpenClose,
    onConfirm,
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();

    const textConfig = moduleItem?.textConfig;

    const [text, setText] = useState(textConfig?.text);
    const [textColorHex, setTextColorHex] = useState(textConfig?.textColorHex);
    const [linkCategoryId, setLinkCategoryId] = useState(linkCategoryContextValueHook?.content?.find(r => r.id === moduleItem?.linkCategoryId)?.id || '');

    const handleChangeText = (e) => {
        const value = e.target.value;
        setText(value);
    }

    const handleChangeLinkCategoryId = (e) => {
        const value = e.target.value;

        setLinkCategoryId(value || '');
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (text?.length < 1 || text?.length > 500) {
            customToast.error('텍스트는 1-500자 내외로 입력해 주세요.');
            return;
        }

        let body = {
            ...moduleItem,
            type: 'TEXT',
            linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r?.id === linkCategoryId)?.id || '',
            userPageId: userPageContextValueHook?.userPage?.id,
            textConfig: {
                text: text,
                textColorHex: textColorHex
            }
        }
        onConfirm(body);
    }

    return (
        <>
            <CustomDialog open={open} onClose={() => toggleOpenClose(false)} maxWidth='sm'>
                <St.UpdateModuleContainer>
                    <CustomDialog.Header style={{ marginBottom: '20px' }}>
                        <CustomDialog.Header.Fake />
                        <CustomDialog.Header.Title>텍스트 모듈</CustomDialog.Header.Title>
                        <CustomDialog.Header.Close onClick={() => toggleOpenClose(false)} />
                    </CustomDialog.Header>
                    <div className='wrapper'>
                        <div className='textareaBox'>
                            <CustomLabelTextarea
                                labelElement={<><span style={{ color: 'var(--redColor1)' }}>*</span> 텍스트</>}
                                value={text || ''}
                                onChange={(e) => handleChangeText(e)}
                                maxLength={500}
                            />
                            <div className='counter'>{text?.length} / 500</div>
                        </div>
                        <div className='wrapper__selectBox'>
                            <CustomSelect
                                value={linkCategoryId}
                                onChange={(e) => handleChangeLinkCategoryId(e)}
                            >
                                <option value=''>전체</option>
                                {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                    return (
                                        <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                    );
                                })}
                            </CustomSelect>
                            <label>카테고리{linkCategoryId && <span style={{ color: 'var(--redColor1)', paddingLeft: '10px' }}>* 카테고리가 선택 되었어요!</span>}</label>
                        </div>
                        <div className='wrapper__footerButtonGroup'>
                            <CustomBlockButton
                                type='button'
                                onClick={(e) => handleSubmit(e)}
                            >
                                수정
                            </CustomBlockButton>
                        </div>
                    </div>
                </St.UpdateModuleContainer>
            </CustomDialog>
        </>
    )
}