import styled from 'styled-components';

export const Container = styled.div`
`;

export const AddButtonWrapper = styled.div`
    button{
        height: 60px;
        font-weight: 700;
        font-size: 18px;
        border-radius: 30px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;

        &:hover{
            background-color: var(--mainColorHover);
        }

        .content{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            width: 100%;
            height: 100%;
            justify-content: center;

            .content__icon{
                width: 28px;
            }
        }
    }
`;

export const LinkItemListContainer = {
    Root: styled.div`
        margin-top: 20px;

        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    `,
    LinkItemWrapper: styled.div`
        position: relative;
        border-radius: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: var(--defaultBoxShadow);
        background: #fff;

        .deleteButtonField{
            position: absolute;
            top: -10px;
            right: -5px;

            button{
                width: 30px;
                height: 30px;
                border-radius: 100%;
                border: 1px solid var(--redColor1);
                /* background-color: var(--redColor1); */

                &:hover{
                    box-shadow: var(--defaultBoxShadow3);
                    background-color: var(--redColor1);
                    svg{
                        fill:#eee;
                    }
                }

                svg{
                    width: 18px;
                    fill: var(--redColor1);
                }
            }
        }

        .categoryBadgeField{
            position: absolute;
            top: -10px;
            left: 50%;
            background-color: #fff;
            transform: translate(-50%, 0);
            
            .categoryBadge{
                display: inline-block;
                border: 1px solid #e0e0e0;
                border-radius: 10px;
                font-size: 12px;
                padding: 5px 10px;
                color: #808080;
                font-weight: 600;
                cursor:default;
            }

            .categoryBadge.active{
                color: var(--blueColor1);
                border-color: var(--blueColor1);
            }
        }

        section.first{
            display: flex;
            align-items: center;
            padding: 0 10px;
            .icon{
                width: 20px;
            }
        }

        section.second{
            flex:1;
            padding: 20px 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            
            section.second_1{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                section.second_1_1{
                    .title{
                        font-size: 18px;
                        font-weight: 700;
                        word-wrap: break-word;
                        word-break: break-all;
                    }
        
                    .linkUrl{
                        font-size: 14px;
                        color: #303030;
                        font-weight: 700;
    
                        word-wrap: break-word;
                        word-break: break-all;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                    }
                }

                section.second_1_2{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 10px;
        
                    button{
                        width: 40px;
                        height: 40px;
                        border-radius: 100%;
                        border: none;

                        &:hover{
                            box-shadow: var(--defaultBoxShadow3);
                        }
        
                        .icon{
                            width: 50%;
                        }
                    }
        
                    .switchBox{
                    }
                }
            }

            section.second_2{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                

                section.second_2_1{
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    align-items: center;
                    flex-wrap: wrap;

                    .clickLogGroup {
                        display: flex;
                        gap: 10px;

                        /* @media all and (max-width:768px){
                            flex-direction: column;
                            gap: 5px;
                        } */
                    }

                    button{
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        border: none;
        
                        &.rectangle{
                            width: auto;
                            border-radius: 10px;
                            padding:0 5px;

                            .flexBox{
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                gap: 5px;
                                .icon{
                                    width: 20px;
                                }

                                .count{
                                    font-size: 11px;
                                    font-weight: 700;
                                }
                            }
                        }

                        &.social{
                            background: #efefef;
                            color: #000;
                            font-weight: 700;
                            width: auto;
                            border-radius: 10px;
                            padding:0 10px;
                            font-size: 12px;
                        }

                        &:hover{
                            box-shadow: var(--defaultBoxShadow3);
                        }
        
                        .icon{
                            width: 20px;
                        }
                    }
                }
            }
        }

        section.thrid{
            .editIconButtonBox{

            }
        }
    `,
}

export const LoadMoreButtonWrapper = styled.div`
    margin-top: 20px;

    button{
        border-radius: 15px;
        font-weight: 700;
        color: #000;
        background-color: #efefef;
        border: none;
    }

    .lastContent{
        text-align: center;
        font-weight: 600;
        font-size: 14px;
        color: #606060;
    }

    .moreLoadingBox{
        display: flex;
        justify-content: center;
        height: 50px;

        .progress{
            color: #b0b0b0;
        }
    }
`;

export const ThumbnailPopoverElement = styled.div`
    width: 150px;
    padding: 10px;
`;

export const DeletePopoverElement = styled.div`
    /* width: 150px; */
    padding: 20px;
    box-sizing: border-box;
    .notice{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    button{
        border-radius: 30px;
        background-color: var(--redColor1);
        font-weight: 700;
        color: #fff;
        font-size: 14px;
        border: none;
    }
`;

export const AddLinkItemModalContainer = styled.div`
    padding:20px;

    .wrapper{
        display: flex;
        flex-direction: column;
        gap: 40px;

        
        .wrapper__inputBox{
            position: relative;
            label {
                position: absolute;
                top: 15px;
                left: 20px;
                transition: top 0.3s, left 0.3s;
                pointer-events: none;
                font-size: 14px;
                color: #606060;
                font-weight: 500;
            }

            input{
                border-radius: 30px;
                height: 50px;
                border: 2px solid #00000000;
                background: var(--defaultElementBC);
                padding: 0 20px;
                font-weight: 700;
                &:focus{
                    border-color: #000000;
                }
            }

            input:focus ~ label,
            input:not(:placeholder-shown) ~ label {
                top: -20px;
                left: 20px;
                font-size: 12px; /* 라벨 크기를 조정할 수 있습니다. */
            }
        }

        .wrapper__selectBox{
            position: relative;
            label {
                position: absolute;
                top: -20px;
                left: 20px;
                transition: top 0.3s, left 0.3s;
                pointer-events: none;
                font-size: 12px;
                color: #606060;
                font-weight: 500;
            }

            select{
                border-radius: 30px;
                height: 50px;
                border: 2px solid #00000000;
                background-color: var(--defaultElementBC);
                padding: 0 20px;
                font-weight: 700;
                &:focus{
                    border-color: #000000;
                }
            }
        }

        .wrapper__thumbnailWrapper{
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 30px;
            overflow: hidden;
            
            &.open{
                box-shadow: var(--defaultBoxShadow2);
            }

            .wrapper__thumbnailWrapper__header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 20px;
                height: 50px;
                background-color: var(--defaultElementBC);
                cursor: pointer;

                &.open{
                    border-bottom: 1px solid #f0f0f0;
                }

                &:hover{
                    background-color: #f0f0f0;
                }
                
                .wrapper__thumbnailWrapper__header__title{
                    font-size: 14px;
                    font-weight: 600;
                    color: #606060;
                }

                .wrapper__thumbnailWrapper__header__icon{
                    width: 30px;
                }
            }

            .wrapper__thumbnailWrapper__contentLayout{
                display: flex;
                flex-direction: row;
                gap: 20px;
                align-items: center;    
                padding: 20px;


                @media all and (max-width:992px){
                    flex-direction: column;
                }

                .wrapper__thumbnailWrapper__contentLayout__thumbnailBox{
                    position: relative;
                    width: 150px;
                    height: 150px;
                    border: 1px solid #f0f0f0;
                    border-radius: 15px;
                    background: #fff;
                    overflow: hidden;

                    .wrapper__thumbnailWrapper__contentLayout__thumbnailBox__blankText{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        box-sizing: border-box;
                        width: 100%;
                        padding: 10px;
                        text-align: center;
                        color: #606060;
                        font-size: 14px;
                    }
                }

                .wrapper__thumbnailWrapper__contentLayout__buttonGroup{
                    flex:1;
                    display: flex;
                    flex-direction: column;
                    gap: 20px;

                    @media all and (max-width:992px){
                        width:100%;
                    }

                    button{
                        height: 50px;
                        border-radius: 30px;
                        color: #fff;
                        font-weight: 700;
                        border: none;
                    }

                    button.select{
                        background: #000;
                    }

                    button.remove{
                        background:var(--defaultElementBC);
                        color: #000;
                    }
                }
            }
        }

        .wrapper__footerButtonGroup{
            display: flex;
            gap: 10px;

            button{
                padding: 0 16px;
                border-radius: 30px;
                height: 50px;
                background-color: var(--mainColor);
                border: none;
                font-size: 16px;
                font-weight: 700;
                color: #fff;

                &:hover{
                    background-color: var(--mainColorHover);
                }
            }
        }
    }
`;