import { useEffect, useState } from 'react';
import CustomBlockButton from '../../../../../../../components/button/CustomBlockButton';
import CustomSelect from '../../../../../../../components/select/default/v1/CustomSelect';
import * as St from './SubFdCategory.styled';
import { Switch } from '@mui/material';
import { useLinkCategoryContextActionsHook, useLinkCategoryContextValueHook } from '../../../../contexts/LinkCategoryContextProvider';
import { CustomDialog } from '../../../../../../../components/dialog/v1/CustomDialog';
import { CustomRatioImage } from '../../../../../../../components/image/CustomRatioImage';
import { CustomInput } from '../../../../../../../components/input/CustomInput';
import { useApiHook } from '../../../../hooks/useApiHook';
import { useUserPageContextActionsHook, useUserPageContextValueHook } from '../../../../../../../contexts/UserPageContextProvider';
import { CircularProgress } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListUtils } from '../../../../../../../utils/ListUtils';
import { customToast } from '../../../../../../../components/toast/custom-react-toastify/v1';
import { useQueryParamsHook } from '../../../../../../../hooks/useQueryParamsHook';

const listUtils = ListUtils();

export function SubFdCategory({
    onRefreshPreviewViewer
}) {
    const origin = window.location.origin;
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook?.params?.lcId;

    const apiHook = useApiHook();

    const userPageContextValueHook = useUserPageContextValueHook();
    const userPageContextActionsHook = useUserPageContextActionsHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();
    const linkCategoryContextActionsHook = useLinkCategoryContextActionsHook();

    const [settingModalOpen, setSettingModalOpen] = useState(false);

    const handleReqFetchLinkCategoryList = async () => {
        let params = {
            userPageId: userPageContextValueHook?.userPage?.id
        }

        const fetchResult = await apiHook.reqFetchLinkCategoryList({ params })

        if (fetchResult?.content) {
            linkCategoryContextActionsHook.content.onSet(fetchResult?.content);
            linkCategoryContextActionsHook.isLoading.onSet(false);
        }
    }

    const handleReqChangeIsCategoryHide = async () => {
        let body = {
            id: userPageContextValueHook?.userPage?.id,
            viewCategoryFlag: !userPageContextValueHook?.userPage?.viewCategoryFlag
        }

        const result = await apiHook.reqChangeUserPage_viewCategoryFlag({ body: body });

        if (result?.content) {
            userPageContextActionsHook.onSetUserPage({ ...result?.content });

            const newQueryParams = { ...queryParamsHook?.params };
            if (!result?.content?.viewCategoryFlag && newQueryParams.lcId) {
                delete newQueryParams.lcId;
                queryParamsHook.setSearchParams({ ...newQueryParams }, { replace: true });
            } else {
                onRefreshPreviewViewer();
            }

        }
    }

    const toggleSettingModalOpen = (bool) => {
        setSettingModalOpen(bool);
    }

    const handleSelectLinkCategory = (e) => {
        const value = e.target.value;
        const newQueryParams = { ...queryParamsHook?.params };

        if (!value) {
            delete newQueryParams.lcId;
        } else {
            newQueryParams.lcId = value;
        }

        queryParamsHook.setSearchParams({ ...newQueryParams }, { replace: true });

    }

    const handleCopyLink = async () => {
        try {
            let url = `${origin}/${userPageContextValueHook?.userPage?.domainName}`;
            if (lcId) {
                url = `${origin}/${userPageContextValueHook?.userPage?.domainName}?lcId=${lcId}`;
            }
            await navigator.clipboard.writeText(url);
            customToast.success('URL을 복사했습니다!');
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        if (userPageContextValueHook?.userPage?.isLoading || !userPageContextValueHook?.userPage?.id) {
            return;
        }

        handleReqFetchLinkCategoryList();
    }, [userPageContextValueHook?.userPage?.isLoading, userPageContextValueHook?.userPage?.id]);

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <div className='titleWrapper'>
                        <h3>카테고리</h3>
                        <Switch
                            size='small'
                            color='success'
                            onChange={e => handleReqChangeIsCategoryHide()}
                            checked={userPageContextValueHook?.userPage?.viewCategoryFlag ? true : false}
                        />
                    </div>
                    <div className='flexWrapper'>
                        <section className='first'>
                            <CustomSelect value={lcId || ''} onChange={(e) => handleSelectLinkCategory(e)}>
                                <option value={''}>카테고리 전체</option>
                                {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                    return (
                                        <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                    );
                                })}
                            </CustomSelect>
                            <CustomBlockButton
                                type='button'
                                onClick={() => toggleSettingModalOpen(true)}
                            >
                                설정
                            </CustomBlockButton>
                        </section>
                        <section className='second'>
                            <CustomBlockButton
                                type='button'
                                onClick={() => handleCopyLink()}
                            >
                                URL 복사
                            </CustomBlockButton>
                        </section>
                    </div>
                </St.Wrapper>
            </St.Container>

            {settingModalOpen &&
                <MdSetting
                    open={settingModalOpen}
                    onClose={() => toggleSettingModalOpen(false)}
                    onRefreshPreviewViewer={onRefreshPreviewViewer}
                />
            }
        </>
    );
}

function MdSetting({
    open = false,
    onClose = () => { },
    onRefreshPreviewViewer
}) {
    const apiHook = useApiHook();

    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();
    const linkCategoryContextActionsHook = useLinkCategoryContextActionsHook();

    const [addModeOpen, setAddModeOpen] = useState(false);
    const [addInputValue, setAddInputValue] = useState('');
    const [targetLinkCategory, setTargetLinkCategory] = useState(null);

    const toggleAddModeOpen = (bool) => {
        setAddInputValue('');
        setAddModeOpen(bool)
    }
    const handleChangeAddInputValue = (e) => {
        const value = e.target.value;

        setAddInputValue(value);
    }

    const handleReqCreateLinkCategory = async (e) => {
        e.preventDefault();

        const categoryName = addInputValue;
        toggleAddModeOpen(false);

        const body = {
            name: categoryName,
            userPageId: userPageContextValueHook?.userPage?.id
        }

        linkCategoryContextActionsHook.isLoading.onSet(true);
        const createResult = await apiHook.reqCreateLinkCategory({ body });
        if (createResult?.content) {
            let newLinkCategoryList = [...linkCategoryContextValueHook?.content, createResult?.content];
            linkCategoryContextActionsHook.content.onSet(newLinkCategoryList);
            onRefreshPreviewViewer();
        }
        linkCategoryContextActionsHook.isLoading.onSet(false);
    }

    const handleChangeTargetLinkCategory = (linkCategory) => {
        setTargetLinkCategory(linkCategory);
    }

    const handleDragEnd = async (e) => {
        const source = e.source;
        const destination = e.destination;

        if (!destination) {
            return;
        }
        let newList = listUtils.reorder(linkCategoryContextValueHook.content, source.index, destination.index)
            .map((r, index) => { return { ...r, orderNumber: index } });

        if (JSON.stringify(newList) === JSON.stringify(linkCategoryContextValueHook.content)) {
            return;
        }

        let body = {
            content: [...newList].map(r => {
                return {
                    id: r.id,
                    orderNumber: r.orderNumber
                }
            }),
            userPageId: userPageContextValueHook?.userPage?.id
        }

        linkCategoryContextActionsHook.content.onSet(newList);

        // 비동기 요청
        const changeResult = await apiHook.reqChangeLinkCategories_orderNumber({ body });

        onRefreshPreviewViewer();
        if (!changeResult?.content) {
            customToast.error('카테고리 순서 변경중 에러가 발생했습니다. 새로고침 후 다시 시도해 주세요.')
            return;
        }

    }
    return (
        <>
            <CustomDialog
                open={open}
                onClose={() => onClose()}
            >
                <CustomDialog.CloseButton onClose={() => onClose()} />
                <St.MdSettingBodyContainer>
                    <div className='addWrapper'>
                        {!addModeOpen && (
                            <>
                                {linkCategoryContextValueHook?.isLoading ?
                                    <CustomBlockButton
                                        type='button'
                                        className='addMode'
                                    >
                                        <CircularProgress color="inherit" />
                                    </CustomBlockButton>
                                    :
                                    <CustomBlockButton
                                        type='button'
                                        className='addMode'
                                        onClick={() => toggleAddModeOpen(true)}
                                        disabled={targetLinkCategory ? true : false}
                                    >
                                        카테고리 추가
                                    </CustomBlockButton>
                                }
                            </>
                        )
                        }

                        {addModeOpen &&
                            <form className='addFormBox' onSubmit={(e) => handleReqCreateLinkCategory(e)}>
                                <section className='first'>
                                    <div className='inputBox'>
                                        <CustomInput
                                            type='text'
                                            value={addInputValue || ''}
                                            onChange={(e) => handleChangeAddInputValue(e)}
                                            autoFocus
                                        />
                                        <label>카테고리명</label>
                                    </div>
                                </section>
                                <section className='second'>
                                    <CustomBlockButton
                                        type='submit'
                                    >
                                        <div className='icon'>
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/check_default_green1.svg' />
                                            </CustomRatioImage>
                                        </div>
                                    </CustomBlockButton>
                                    <CustomBlockButton
                                        type='button'
                                        onClick={(e) => toggleAddModeOpen(false)}
                                    >
                                        <div className='icon'>
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/close_default_red1.svg' />
                                            </CustomRatioImage>
                                        </div>
                                    </CustomBlockButton>
                                </section>
                            </form>
                        }
                    </div>
                    <div className='listWrapper'>
                        <DragDropContext
                            onDragEnd={handleDragEnd}
                        >
                            <Droppable
                                droppableId={'sortDroppableId'}
                                direction="vertical"
                                isCombineEnabled
                            >
                                {(droppableProvided) => (
                                    <div
                                        ref={droppableProvided.innerRef}
                                        {...droppableProvided.droppableProps}
                                    >
                                        {linkCategoryContextValueHook?.content?.map((linkCategory, index) => {
                                            return (
                                                <LinkCategoryItem
                                                    key={linkCategory?.id}
                                                    index={index}
                                                    linkCategory={linkCategory}
                                                    addModeOpen={addModeOpen}
                                                    targetLinkCategory={targetLinkCategory}
                                                    onChangeTargetLinkCategory={handleChangeTargetLinkCategory}
                                                    onRefreshPreviewViewer={onRefreshPreviewViewer}
                                                />
                                            );
                                        })}
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </St.MdSettingBodyContainer>
            </CustomDialog>
        </>
    );
}

function LinkCategoryItem({
    linkCategory,
    index,
    addModeOpen,
    targetLinkCategory,
    onChangeTargetLinkCategory,
    onRefreshPreviewViewer
}) {
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook?.params?.lcId;

    const apiHook = useApiHook();

    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();
    const linkCategoryContextActionsHook = useLinkCategoryContextActionsHook();

    const [itemEventMode, setItemEventMode] = useState(null);
    const [editCategoryName, setEditCategoryName] = useState('');

    const toggleItemEventModeOpen = (type) => {
        if (type) {
            setItemEventMode(type);
            onChangeTargetLinkCategory(linkCategory);
            if (type === 'EDIT') {
                setEditCategoryName(linkCategory?.name);
            }
        } else {
            setItemEventMode(null);
            onChangeTargetLinkCategory(null);
            setEditCategoryName(null);
        }
    }

    const handleChangeEditCategoryName = (e) => {
        let value = e.target.value;
        setEditCategoryName(value);
    }

    const handleReqDeleteLinkCategory = async () => {
        const currentTargetLinkCategory = { ...targetLinkCategory };
        const body = {
            id: currentTargetLinkCategory?.id,
            userPageId: userPageContextValueHook?.userPage?.id
        }

        linkCategoryContextActionsHook.isLoading.onSet(true);
        const deleteResult = await apiHook.reqDeleteLinkCategory({ body });
        if (deleteResult?.content) {
            let newLinkCategoryList = [...linkCategoryContextValueHook?.content].filter(r => r.id !== deleteResult?.content?.id);
            linkCategoryContextActionsHook.content.onSet(newLinkCategoryList);
            onRefreshPreviewViewer();

            if (lcId === deleteResult?.content?.id) {
                let newQueryParams = queryParamsHook?.params;
                delete newQueryParams?.lcId;

                queryParamsHook.setSearchParams({ ...newQueryParams }, { replace: true })
            }
        }
        toggleItemEventModeOpen(null);
        linkCategoryContextActionsHook.isLoading.onSet(false);
    }

    const handleReqChangeLinkCategory_name = async (e) => {
        e.preventDefault();
        const currentTargetLinkCategory = { ...targetLinkCategory };
        const body = {
            id: currentTargetLinkCategory?.id,
            userPageId: userPageContextValueHook?.userPage?.id,
            name: editCategoryName
        }

        linkCategoryContextActionsHook.isLoading.onSet(true);
        const changeResult = await apiHook.reqChangeLinkCategory_name({ body });
        if (changeResult?.content) {
            let newLinkCategoryList = [...linkCategoryContextValueHook?.content].map(linkCategory => {
                if (linkCategory?.id === changeResult?.content?.id) {
                    return {
                        ...changeResult?.content
                    }
                } else {
                    return { ...linkCategory }
                }
            })
            onRefreshPreviewViewer();
            linkCategoryContextActionsHook.content.onSet(newLinkCategoryList);
        }
        toggleItemEventModeOpen(null);
        linkCategoryContextActionsHook.isLoading.onSet(false);
    }

    return (
        <>
            {(targetLinkCategory?.id !== linkCategory?.id) &&
                <Draggable key={linkCategory.id} draggableId={`SubFdCategory-${linkCategory.id}`} index={index}>
                    {(draggableProvided) => (
                        <div
                            className='listWrapper__itemBox'
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                        >
                            <div className='listWrapper__itemBox__flexWrapper'>
                                <section className='first'>
                                    <section className='first_1'>
                                        <div className='icon' {...draggableProvided.dragHandleProps}>
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/dragIndicator_808080.svg' />
                                            </CustomRatioImage>
                                        </div>
                                    </section>
                                    <section className='first_2'>
                                        {linkCategory?.name}
                                    </section>
                                    <section className='first_3'>
                                        <CustomBlockButton
                                            type='button'
                                            onClick={(e) => toggleItemEventModeOpen('EDIT')}
                                        >
                                            <div className='icon'>
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src='/assets/icons/edit_000000.svg' />
                                                </CustomRatioImage>
                                            </div>
                                        </CustomBlockButton>
                                    </section>
                                </section>
                                <section className='second'>
                                    <CustomBlockButton
                                        type='button'
                                        onClick={() => toggleItemEventModeOpen('DELETE')}
                                    >
                                        <div className='icon'>
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/delete_000000.svg' />
                                            </CustomRatioImage>
                                        </div>
                                    </CustomBlockButton>
                                </section>
                            </div>
                            {(addModeOpen || linkCategoryContextValueHook?.isLoading || (targetLinkCategory && targetLinkCategory?.id !== linkCategory?.id)) &&
                                <div className='actionBlockBox'></div>
                            }
                            {draggableProvided.placeholder}
                        </div>
                    )}
                </Draggable>
            }
            {itemEventMode === 'DELETE' &&
                <div
                    className='listWrapper__itemBox'
                >
                    <div className='deleteModeBox'>
                        <div className='notice'>{linkCategory?.name} 를 삭제 하시겠습니까?</div>
                        <div className='buttonGroup'>
                            <CustomBlockButton
                                type='button'
                                className='cancel'
                                onClick={(e) => toggleItemEventModeOpen(null)}
                            >
                                취소
                            </CustomBlockButton>
                            <CustomBlockButton
                                type='button'
                                className='confirm'
                                onClick={() => handleReqDeleteLinkCategory()}
                            >
                                삭제
                            </CustomBlockButton>
                        </div>
                    </div>
                </div>
            }
            {itemEventMode === 'EDIT' &&
                <div
                    className='listWrapper__itemBox'
                >
                    <form className='editModeBox' onSubmit={(e) => handleReqChangeLinkCategory_name(e)}>
                        <section className='first'>
                            <div className='inputBox'>
                                <CustomInput
                                    type='text'
                                    value={editCategoryName || ''}
                                    onChange={(e) => handleChangeEditCategoryName(e)}
                                />
                                <label>카테고리명</label>
                            </div>
                        </section>
                        <section className='second'>
                            <CustomBlockButton
                                type='submit'
                            >
                                <div className='icon'>
                                    <CustomRatioImage width={1} height={1}>
                                        <CustomRatioImage.Image src='/assets/icons/check_default_green1.svg' />
                                    </CustomRatioImage>
                                </div>
                            </CustomBlockButton>
                            <CustomBlockButton
                                type='button'
                                onClick={(e) => toggleItemEventModeOpen(null)}
                            >
                                <div className='icon'>
                                    <CustomRatioImage width={1} height={1}>
                                        <CustomRatioImage.Image src='/assets/icons/close_default_red1.svg' />
                                    </CustomRatioImage>
                                </div>
                            </CustomBlockButton>
                        </section>
                    </form>
                </div>
            }
        </>
    );
}