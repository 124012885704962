import styled from 'styled-components';

export const Container = styled.div`

`;

export const HeaderWrapper = styled.div`
    padding: 20px;
    .headerBox{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title{
            font-size: 18px;
            font-weight: 600;
        }

        .icon{
            width: 30px;
        }
    }
`;

export const ColorSelectorWrapper = styled.div`
    padding: 0 20px;
    margin-top: 20px;
    .currentColorTitle{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .currentColor{
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        justify-content: flex-start;
        width: fit-content;
        .colorBox{
            width: 45px;
            height: 45px;
            background-color: red;
            border-radius: 45px;
            border: 1px solid #f0f0f0;
        }

        .colorHex{
            font-weight: 700;
        }
    }

    .sampleColorList{
        margin-top: 20px;
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        .colorBox{
            cursor: pointer;
            width: 40px;
            height: 40px;
            background-color: red;
            border-radius: 40px;
            border: 1px solid #f0f0f0;
        }
    }
`;

export const ResultWrapper = styled.div`
    margin-top: 20px;
    padding: 0 20px;

    .title{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
    }

    .background{
        padding: 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        .text{
            font-weight: 600;
            flex:1;
            white-space: nowrap;        /* 텍스트를 한 줄로 유지 */
            overflow: hidden;           /* 넘치는 텍스트를 숨김 */
            text-overflow: ellipsis;    /* 넘치는 부분에 "..." 표시 */
        }
    }
`;

export const FooterWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    padding: 0 20px;
    justify-content: flex-end;

    button{
        width: 80px;
        height: 40px;
        border-radius: 10px;
        background: var(--mainColor);
        color: #fff;
        border: none;
        font-weight: 700;
    }

`;