import { useEffect, useState } from 'react';
import * as St from './FgTotalSummary.styled';
import { CustomChart } from '../../../../../../components/chart/CustomChart';
import _ from 'lodash';
import { DefinedVariable } from '../DefinedVariable';

function getRandomPastelColor() {
    // 각 RGB 값을 랜덤하게 생성하되, 파스텔 톤을 유지하도록 127~255 범위로 설정
    const r = Math.floor((Math.random() * 127) + 127);
    const g = Math.floor((Math.random() * 127) + 127);
    const b = Math.floor((Math.random() * 127) + 127);

    // 16진수로 변환하고 2자리 숫자로 패드
    const toHex = (value) => {
        const hex = value.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };

    // 최종 색상 코드 반환
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

function getSortedReferrerGroupedDataListAggregation(referrerGroupedDataList) {
    const othersPieColor = getRandomPastelColor();
    const top7Offset = 7;
    const focusOnTotalVisitCount = _.cloneDeep(referrerGroupedDataList);
    const focusOnFirstVisitCount = _.cloneDeep(referrerGroupedDataList);
    let focusOnTotalVisitCountOthers = null;
    let focusOnFirstVisitCountOthers = null;

    focusOnTotalVisitCount.sort((a, b) => b.totalVisitCount - a.totalVisitCount);
    focusOnFirstVisitCount.sort((a, b) => b.firstVisitCount - a.firstVisitCount);

    if (referrerGroupedDataList?.length > top7Offset) {
        focusOnTotalVisitCountOthers = focusOnTotalVisitCount?.slice(top7Offset)?.reduce((acc, curr) => {
            acc.firstVisitCount += curr?.firstVisitCount;
            acc.totalVisitCount += curr?.totalVisitCount;

            return acc;
        }, {
            referrer: 'others',
            pieColor: othersPieColor,
            firstVisitCount: 0,
            totalVisitCount: 0,
        })
        focusOnFirstVisitCountOthers = focusOnTotalVisitCount?.slice(top7Offset)?.reduce((acc, curr) => {
            acc.firstVisitCount += curr?.firstVisitCount;
            acc.totalVisitCount += curr?.totalVisitCount;

            return acc;
        }, {
            referrer: 'others',
            pieColor: othersPieColor,
            firstVisitCount: 0,
            totalVisitCount: 0,
        })
    }

    return {
        totalVisitCountTop7: focusOnTotalVisitCount?.slice(0, top7Offset),
        firstVisitCountTop7: focusOnFirstVisitCount?.slice(0, top7Offset),
        totalVisitCountOthers: focusOnTotalVisitCountOthers,
        firstVisitCountOthers: focusOnFirstVisitCountOthers
    }
}

export function FgTotalSummary({
    visitLogList
}) {
    const [firstVisitCountSumForToday, setFirstVisitCountSumForToday] = useState(0);
    const [totalVisitCountSumForToday, setTotalVisitCountSumForToday] = useState(0);
    const [firstVisitCountSumForDateRange, setFirstVisitCountSumForDateRange] = useState(0);
    const [totalVisitCountSumForDateRange, setTotalVisitCountSumForDateRange] = useState(0);
    const [referrerGroupedDataList, setReferrerGroupedDataList] = useState(null);

    useEffect(() => {
        if (!visitLogList) {
            return;
        }

        const flattenedDataList = visitLogList.flatMap(log => log.dataList);

        const todayVisitLog = visitLogList[visitLogList?.length - 1];

        let newFirstVisitCountSumForToday = todayVisitLog?.firstVisitCountAmount;
        let newTotalVisitCountSumForToday = todayVisitLog?.totalVisitCountAmount;
        let newFirstVisitCountSumForDateRange = 0;
        let newTotalVisitCountSumForDateRange = 0;

        const referrerGroupedData = flattenedDataList.reduce((acc, curr) => {
            // 현재 referrer를 기준으로 기존 그룹을 찾음
            const referrer = curr.referrer;
            if (!acc[referrer]) {
                // 아직 그룹이 없으면 초기화
                const definedReferrer = DefinedVariable.DEFINED_REFERRER_LIST?.find(r => r.referrer === referrer);

                if (definedReferrer) {
                    acc[referrer] = {
                        referrer: definedReferrer?.convertedName,
                        firstVisitCount: 0,
                        totalVisitCount: 0,
                        pieColor: definedReferrer?.color
                    };
                } else {
                    acc[referrer] = {
                        referrer: referrer,
                        firstVisitCount: 0,
                        totalVisitCount: 0,
                        pieColor: getRandomPastelColor()
                    };
                }
            }
            // 기존 그룹에 카운트 추가
            acc[referrer].firstVisitCount += curr.firstVisitCount;
            acc[referrer].totalVisitCount += curr.totalVisitCount;

            newFirstVisitCountSumForDateRange += curr.firstVisitCount;
            newTotalVisitCountSumForDateRange += curr.totalVisitCount;
            return acc;
        }, {});

        const referrerGroupedDataList = Object.values(referrerGroupedData);

        setReferrerGroupedDataList(referrerGroupedDataList);
        setFirstVisitCountSumForToday(newFirstVisitCountSumForToday);
        setTotalVisitCountSumForToday(newTotalVisitCountSumForToday);
        setFirstVisitCountSumForDateRange(newFirstVisitCountSumForDateRange);
        setTotalVisitCountSumForDateRange(newTotalVisitCountSumForDateRange);
    }, [visitLogList]);

    if (!visitLogList || !referrerGroupedDataList) {
        return null;
    }

    const sortedReferrerGroupedAggregation = getSortedReferrerGroupedDataListAggregation(referrerGroupedDataList);

    if (!sortedReferrerGroupedAggregation) {
        return null;
    }

    // Others 가 null이 아니라면 전체 리스트에 others 데이터도 병합해서 보여준다.
    const totalVisitPieData = {
        labels: sortedReferrerGroupedAggregation?.totalVisitCountOthers ? sortedReferrerGroupedAggregation?.totalVisitCountTop7?.map(r => r.referrer).concat(sortedReferrerGroupedAggregation?.totalVisitCountOthers?.referrer) : sortedReferrerGroupedAggregation?.totalVisitCountTop7?.map(r => r.referrer),
        datasets: [
            {
                label: 'Dataset 1',
                data: sortedReferrerGroupedAggregation?.totalVisitCountOthers ? sortedReferrerGroupedAggregation?.totalVisitCountTop7?.map(r => r.totalVisitCount).concat(sortedReferrerGroupedAggregation?.totalVisitCountOthers?.totalVisitCount) : sortedReferrerGroupedAggregation?.totalVisitCountTop7?.map(r => r.totalVisitCount),
                backgroundColor: sortedReferrerGroupedAggregation?.totalVisitCountOthers ? sortedReferrerGroupedAggregation?.totalVisitCountTop7?.map(r => r.pieColor).concat(sortedReferrerGroupedAggregation?.totalVisitCountOthers?.pieColor) : sortedReferrerGroupedAggregation?.totalVisitCountTop7?.map(r => r.pieColor),
            },
        ]
    };

    const firstVisitPieData = {
        labels: sortedReferrerGroupedAggregation?.firstVisitCountOthers ? sortedReferrerGroupedAggregation?.firstVisitCountTop7?.map(r => r.referrer).concat(sortedReferrerGroupedAggregation?.firstVisitCountOthers?.referrer) : sortedReferrerGroupedAggregation?.firstVisitCountTop7?.map(r => r.referrer),
        datasets: [
            {
                label: 'Dataset 1',
                data: sortedReferrerGroupedAggregation?.firstVisitCountOthers ? sortedReferrerGroupedAggregation?.firstVisitCountTop7?.map(r => r.firstVisitCount).concat(sortedReferrerGroupedAggregation?.firstVisitCountOthers?.firstVisitCount) : sortedReferrerGroupedAggregation?.firstVisitCountTop7?.map(r => r.firstVisitCount),
                backgroundColor: sortedReferrerGroupedAggregation?.firstVisitCountOthers ? sortedReferrerGroupedAggregation?.firstVisitCountTop7?.map(r => r.pieColor).concat(sortedReferrerGroupedAggregation?.firstVisitCountOthers?.pieColor) : sortedReferrerGroupedAggregation?.firstVisitCountTop7?.map(r => r.pieColor),
            },
        ]
    };

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    <St.CardWrapper>
                        <St.CardBox>
                            <h3>
                                오늘 총 방문 수 : {totalVisitCountSumForToday}
                            </h3>
                            <div className='lineBreaker'></div>
                            <h3>
                                기간 총 방문 수 : {totalVisitCountSumForDateRange}
                            </h3>
                        </St.CardBox>
                        <St.CardBox>
                            <h3>
                                오늘 유효 방문 수 : {firstVisitCountSumForToday}
                            </h3>
                            <div className='lineBreaker'></div>
                            <h3>
                                기간 유효 방문 수 : {firstVisitCountSumForDateRange}
                            </h3>
                        </St.CardBox>
                    </St.CardWrapper>
                    <St.ChartWrapper>
                        <div className='chartBox'>
                            <CustomChart.ReactChart
                                type={'doughnut'}
                                data={totalVisitPieData}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: '기간 총 방문 수의 유입 경로 비율'
                                        },
                                        tooltip: {
                                            callbacks: {
                                                label: (context) => {
                                                    const data = context.dataset.data;
                                                    const currentValue = data[context.dataIndex];
                                                    const percentage = totalVisitCountSumForDateRange === 0 ? 0 : ((currentValue / totalVisitCountSumForDateRange) * 100).toFixed(2);
                                                    return `${context.label}: ${currentValue} (${percentage}%)`;
                                                }
                                            }
                                        }
                                    },
                                }}
                            />
                        </div>
                        <div className='chartBox'>
                            <CustomChart.ReactChart
                                type={'doughnut'}
                                data={firstVisitPieData}
                                options={{
                                    responsive: true,
                                    plugins: {
                                        legend: {
                                            position: 'top',
                                        },
                                        title: {
                                            display: true,
                                            text: '기간 유효 방문 수의 유입 경로 비율'
                                        },
                                        tooltip: {
                                            callbacks: {
                                                label: (context) => {
                                                    const data = context.dataset.data;
                                                    const currentValue = data[context.dataIndex];
                                                    const percentage = firstVisitCountSumForDateRange === 0 ? 0 : ((currentValue / firstVisitCountSumForDateRange) * 100).toFixed(2);
                                                    return `${context.label}: ${currentValue} (${percentage}%)`;
                                                }
                                            }
                                        }
                                    },
                                }
                                }
                            />
                        </div>
                    </St.ChartWrapper>
                </St.Wrapper>
            </St.Container>
        </>
    );
}