import styled from 'styled-components';

export const Container = styled.div`
    background: #fff;
    padding: 20px 20px 20px 20px;
    margin-right: 435px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media all and (max-width:992px){
        margin-right: 0;

        padding: 20px 10px 0 10px;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    background-color: var(--mainColor300);
    border-radius: 30px;
    border: none;
    box-shadow: var(--defaultBoxShadow3);
    padding: 10px 20px;
    box-sizing: border-box;

    .flexWrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        section.first{
            flex:1;
            font-size: 15px;
            font-weight: 600;
            color:#000;
            word-break: keep-all;
            @media all and (max-width:992px){
                font-size: 13px;
            }
        }

        section.second{
            width: 120px;

            @media all and (max-width:992px){
                width: 100px;
            }

            button{
                border-radius: 30px;
                font-size: 16px;
                font-weight: 700;
                background: #fff;
                border: none;

                @media all and (max-width:992px){
                    font-size: 13px;
                }
            }
        }
    }
`;