import { useQueryParamsHook } from '../../../../../hooks/useQueryParamsHook';
import * as St from './FdTab.styled';

export function FdTab({
    TAB_LIST
}) {
    const queryParamsHook = useQueryParamsHook();

    const tabType = queryParamsHook.params?.tabType;


    const handleChangeRoute = (tabType) => {
        const newQueryParams = { ...queryParamsHook?.params };

        queryParamsHook.setSearchParams({ ...newQueryParams, tabType: tabType }, { replace: true });
    }

    return (
        <>
            <St.Container>
                <St.Wrapper>
                    {TAB_LIST?.map(tab => {
                        if (tab?.default) {
                            return (
                                <St.TabButton
                                    key={tab?.type}
                                    type='button'
                                    className={`${(!tabType || tabType === tab?.type) ? 'active' : ''}`}
                                    onClick={() => handleChangeRoute(tab?.type)}
                                >
                                    {tab?.name}
                                </St.TabButton>
                            );
                        }
                        return (
                            <St.TabButton
                                key={tab?.type}
                                type='button'
                                className={`${(tabType === tab?.type) ? 'active' : ''}`}
                                onClick={() => handleChangeRoute(tab?.type)}
                            >
                                {tab?.name}
                            </St.TabButton>
                        );
                    })}
                </St.Wrapper>
            </St.Container>
        </>
    );
}