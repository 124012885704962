import { CustomChart } from '../../../../../../components/chart/CustomChart';
import * as St from './FgDateRangeInflowChart.styled';

export function FgDateRangeInflowChart({
    visitLogList,
    onChangeTargetVisitLog
}) {
    const chartOptions = {
        ...CustomChart.defaultOptions,
        plugins: {
            ...CustomChart.defaultOptions.plugins,
            title: {
                display: true,
                text: '기간 별 방문 수',
            },
        },
        scales: {
            ...CustomChart.defaultOptions.scales,
            x: {
                ...CustomChart.defaultOptions.scales.x,
                title: {
                    display: true,
                    text: '날짜',
                    font: {
                        size: 11,
                        weight: 600,
                    },
                },
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 15,
                }
            },
            y: {
                ...CustomChart.defaultOptions.scales.y,
                title: {
                    display: true,
                    text: '방문 수',
                    font: {
                        size: 11,
                        weight: 600,
                    },
                },
                min: 0,
                // max: visitLogList ? visitLogList.reduce((max, visitLog) => (visitLog.totalVisitCountAmount > max.totalVisitCountAmount ? visitLog : max), visitLogList[0])?.totalVisitCountAmount + 5 : null,
                ticks: {
                    autoSkip: true,
                    maxTicksLimit: 7,
                    // y축 눈금을 정수로 설정
                    callback: function (value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    },
                },
                stacked: false
            }
        },
        onClick: (event, item) => {
            if (item?.length <= 0) {
                return;
            }
            let clickedIndex = item[0].index;

            onChangeTargetVisitLog(visitLogList[clickedIndex]);
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
    }

    const data = {
        labels: visitLogList ? [...visitLogList?.map(r => r.visitDate)] : [],
        datasets: [
            {
                type: 'line',
                label: '유효 방문 수',
                data: visitLogList ? [...visitLogList?.map(r => r.firstVisitCountAmount)] : [],
                borderColor: '#90EE90',
                backgroundColor: '#90EE90',
                borderWidth: 2,
                fill: false,
                barPercentage: 0.4,
                categoryPercentage: 1,
                order: 0,
                borderRadius: 5,
                tension: 0.3,
                stack: 'Stack 0',
            },
            {
                type: 'line',
                label: '총 방문 수',
                data: visitLogList ? [...visitLogList?.map(r => r.totalVisitCountAmount)] : [],
                borderColor: '#a0a0a0',
                backgroundColor: '#a0a0a0',
                borderWidth: 2,
                fill: false,
                barPercentage: 0.4,
                categoryPercentage: 1,
                order: 0,
                borderRadius: 5,
                tension: 0.3,
                stack: 'Stack 0'
            },
        ],
    }

    return (
        <>
            <St.Container>
                <CustomChart.Chart
                    options={chartOptions}
                    data={data}
                />
            </St.Container>
        </>
    );
}