import styled from 'styled-components';

export const ModuleContainer = styled.div`
    position: relative;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    box-shadow: var(--defaultBoxShadow);
    background: #fff;
    min-height: 120px;

    .deleteButtonField{
        position: absolute;
        top: -10px;
        right: -5px;

        button{
            width: 30px;
            height: 30px;
            border-radius: 100%;
            border: 1px solid var(--redColor1);
            /* background-color: var(--redColor1); */

            &:hover{
                box-shadow: var(--defaultBoxShadow3);
                background-color: var(--redColor1);
                svg{
                    fill:#eee;
                }
            }

            svg{
                width: 18px;
                fill: var(--redColor1);
            }
        }
    }

    .categoryBadgeField{
        position: absolute;
        top: -10px;
        left: 5%;
        background-color: #fff;
        transform: translate(-5%, 0);
        
        .categoryBadge{
            display: inline-block;
            border: 1px solid #e0e0e0;
            border-radius: 10px;
            font-size: 12px;
            padding: 5px 10px;
            color: #808080;
            font-weight: 600;
            cursor:default;
        }

        .categoryBadge.active{
            color: var(--blueColor1);
            border-color: var(--blueColor1);
        }
    }

    section.first{
        display: flex;
        align-items: center;
        padding: 0 10px;
        .icon{
            width: 20px;
        }
    }

    section.second{
        flex:1;
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        section.second_1{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 10px;

            section.second_1_1{
                flex:1;

                .badgeBox{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    margin-bottom: 5px;

                    .iconFigure{
                        width: 20px;
                        height: 20px;
                    }

                    .badge{
                        white-space: nowrap;
                        font-size: 13px;
                        color: var(--mainColor);
                        border-radius: 10px;
                        font-weight: 700;
                    }
                }

                .title{
                    max-height: 100px;
                    overflow: auto;
                    font-size: 14px;
                    font-weight: 600;
                    word-wrap: break-word;
                    word-break: break-all;
                    text-align: center;
                    white-space: pre-line;
                }
    
                .linkUrl{
                    font-size: 14px;
                    color: #303030;
                    font-weight: 700;

                    word-wrap: break-word;
                    word-break: break-all;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }
            }

            section.second_1_2{
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 10px;
    
                button{
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    border: none;

                    &:hover{
                        box-shadow: var(--defaultBoxShadow3);
                    }
    
                    .icon{
                        width: 50%;
                    }
                }
    
                .switchBox{
                }
            }
        }

        section.second_2{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            

            section.second_2_1{
                display: flex;
                flex-direction: row;
                gap: 10px;
                align-items: center;
                flex-wrap: wrap;

                .clickLogGroup {
                    display: flex;
                    gap: 10px;

                    /* @media all and (max-width:768px){
                        flex-direction: column;
                        gap: 5px;
                    } */
                }

                button{
                    width: 30px;
                    height: 30px;
                    border-radius: 100%;
                    border: none;
    
                    &.rectangle{
                        width: auto;
                        border-radius: 10px;
                        padding:0 5px;

                        .flexBox{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 5px;
                            .icon{
                                width: 20px;
                            }

                            .count{
                                font-size: 11px;
                                font-weight: 700;
                            }
                        }
                    }

                    &.social{
                        background: #efefef;
                        color: #000;
                        font-weight: 700;
                        width: auto;
                        border-radius: 10px;
                        padding:0 10px;
                        font-size: 12px;
                    }

                    &:hover{
                        box-shadow: var(--defaultBoxShadow3);
                    }
    
                    .icon{
                        width: 20px;
                    }
                }
            }

            section.second_2_2{
                .category{
                    white-space: nowrap;
                    font-size: 12px;
                    color: #606060;
                    border-radius: 10px;
                    text-align: center;
                    font-weight: 600;
                }
            }
        }
    }

    section.thrid{
        .editIconButtonBox{

        }
    }
`;

export const ThumbnailPopoverElement = styled.div`
    width: 150px;
    padding: 10px;
`;

export const DeletePopoverElement = styled.div`
    /* width: 150px; */
    padding: 20px;
    box-sizing: border-box;
    .notice{
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    button{
        border-radius: 30px;
        background-color: var(--redColor1);
        font-weight: 700;
        color: #fff;
        font-size: 14px;
        border: none;
    }
`;

export const UpdateModuleContainer = styled.div`
    padding:20px;

    .wrapper{
        display: flex;
        flex-direction: column;
        gap: 40px;

        .textareaBox{
            textarea{
                height: 100px;
            }

            .counter{
                font-size: 10px;
                margin-top: 5px;
                padding-right: 20px;
                text-align: right;
                font-weight: 600;
            }
        }

        .wrapper__selectBox{
            position: relative;
            label {
                position: absolute;
                top: -20px;
                left: 20px;
                transition: top 0.3s, left 0.3s;
                pointer-events: none;
                font-size: 12px;
                color: #606060;
                font-weight: 500;
            }

            select{
                border-radius: 15px;
                height: 50px;
                border: 1.5px solid #00000000;
                background-color: var(--defaultElementBC);
                padding: 0 20px;
                font-weight: 700;
                &:focus{
                    border-color: #000000;
                }
            }
        }

        .wrapper__footerButtonGroup{
            display: flex;
            gap: 10px;

            button{
                padding: 0 16px;
                border-radius: 30px;
                height: 50px;
                background-color: var(--mainColor);
                border: none;
                font-size: 16px;
                font-weight: 700;
                color: #fff;

                &:hover{
                    background-color: var(--mainColorHover);
                }
            }
        }
    }
`;