import { useEffect, useRef, useState } from 'react';
import CustomBlockButton from '../../../../../../../components/button/CustomBlockButton';
import { CustomRatioImage } from '../../../../../../../components/image/CustomRatioImage';
import { useUserPageContextValueHook } from '../../../../../../../contexts/UserPageContextProvider';
import { useApiHook } from '../../../../hooks/useApiHook';
import * as St from './SubFdElements.styled';
import { CustomDialog } from '../../../../../../../components/dialog/v1/CustomDialog';
import { CustomInput } from '../../../../../../../components/input/CustomInput';
import { customToast } from '../../../../../../../components/toast/custom-react-toastify/v1';
import { CustomRegexUtils } from '../../../../../../../utils/CustomRegexUtils';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import firebaseStorage from '../../../../../../../firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { CustomBackdropController } from '../../../../../../../components/backdrop/default/v1';
import { CircularProgress, Switch, Tooltip } from '@mui/material';
import { CustomPopover, useCustomPopoverHook } from '../../../../../../../components/popover/CustomPopover';
import _ from 'lodash';
import { useQueryParamsHook } from '../../../../../../../hooks/useQueryParamsHook';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ListUtils } from '../../../../../../../utils/ListUtils';
import { useLinkCategoryContextValueHook } from '../../../../contexts/LinkCategoryContextProvider';
import CustomSelect from '../../../../../../../components/select/default/v1/CustomSelect';
import { useLinkItemListHook } from '../../../../hooks/useLinkItemListHook';
import { DeleteIcon } from '../../../../../../../components/svg-icons/DeleteIcon';
import { WegetAddModule } from './weget/weget-add-module/WegetAddModule';
import { WegetLinkItem } from './weget/weget-link-item/WegetLinkItem';
import { WegetTextItem } from './weget/weget-text-item/WegetTextItem';
import { WegetSeparatorItem } from './weget/weget-separator-item/WegetSeparatorItem';

const customRegexUtils = CustomRegexUtils();
const customBackdropController = CustomBackdropController();
const listUtils = ListUtils();

export function SubFdElements({
    onRefreshPreviewViewer
}) {
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook.params?.lcId;

    const userPageContextValueHook = useUserPageContextValueHook();

    const apiHook = useApiHook();

    const linkItemListHook = useLinkItemListHook();
    const [moreDisabled, setMoreDisabled] = useState(false);

    const [clickLogList, setClickLogList] = useState(null);
    const [addLinkItemModalOpen, setAddLinkItemModalOpen] = useState(false);

    useEffect(() => {
        if (!userPageContextValueHook?.userPage?.id) {
            return;
        }

        async function init() {
            const linkItemListResult = await handleReqFetchLinkItemList({ mode: 'init' });

            if (linkItemListResult?.content) {

                const linkItemIdList = linkItemListResult?.content?.map(r => r.id);

                const countClickLogsResult = await apiHook.reqCountClickLogs({
                    body: {
                        userPageId: userPageContextValueHook?.userPage?.id,
                        linkItemIdList: linkItemIdList
                    }
                });

                if (countClickLogsResult?.content) {
                    setClickLogList(countClickLogsResult?.content);
                }
            }
        }

        init();

    }, [userPageContextValueHook?.userPage?.id, lcId]);

    const toggleAddLinkItemModalOpen = (bool) => {
        setAddLinkItemModalOpen(bool);
    }

    /*
    mode가 init 또는 searchQuery일 경우 최초 조회 api 형태를 유지해야 한다.
    mode가 default의 경우 조회 된 데이터를 이어 붙인다.
*/
    const handleReqFetchLinkItemList = async (req = {
        mode: 'default', // [default,init,searchQuery]
        searchQuery: null
    }) => {
        const { mode, searchQuery } = req;

        if (mode === 'searchQuery') {
            linkItemListHook.setCurrentSearchQuery(searchQuery);
        }

        let params = {
            userPageId: userPageContextValueHook?.userPage?.id,
            lcId: lcId,
            offset: ['init', 'searchQuery'].includes(mode) ? linkItemListHook?.INIT_OFFSET : linkItemListHook?.content?.length,
            limit: ['init', 'searchQuery'].includes(mode) ? linkItemListHook?.INIT_LIMIT : linkItemListHook?.limit,
            searchQuery: mode === 'searchQuery' ? searchQuery : linkItemListHook?.currentSearchQuery
        }

        const result = await apiHook.reqFetchLinkItemListByOwner({ params: params })
        if (result?.content) {
            linkItemListHook.setIsLoading(false);
            linkItemListHook.setHasMore(result?.content?.length > 0);
            linkItemListHook.setLimit(linkItemListHook.DEFAULT_LIMIT);

            linkItemListHook.setContent(
                ['init', 'searchQuery'].includes(mode) ?
                    result?.content
                    :
                    prev => !prev ? result?.content : [...prev, ...result?.content]
            );
            linkItemListHook.setOffset(
                ['init', 'searchQuery'].includes(mode) ?
                    result?.content?.length
                    :
                    prev => linkItemListHook?.content?.length + result?.content?.length
            );
        } else {
            linkItemListHook.setContent(null);
            linkItemListHook.setIsLoading(false);
            linkItemListHook.setOffset(linkItemListHook.INIT_OFFSET);
            linkItemListHook.setLimit(linkItemListHook.INIT_LIMIT);
            linkItemListHook.setHasMore(false);
        }
        return result;
    }

    /*
        body = {
            type: 'LINK', // LINK, TEXT, SEPARATOR
            userPageId: null,
            title: '',
            linkUrl: '',
            description: '',
            thumbnailUrl: '',
            linkCategoryId: '',
            separatorConfig: {
                type: 'SPACE', // SPACE, DOTTED, LINE, ZIGZAG
                iconColorHex: '#000000'
            },
            textConfig: {
                text: '',
                textColorHex: '#000000'
            }
        }
    */
    const handleReqCreateLink = async (body, successCallback) => {
        customBackdropController.showBackdrop();

        const result = await apiHook.reqCreateLinkItem({ body });

        customBackdropController.hideBackdrop();

        if (result?.content) {
            successCallback();
            onRefreshPreviewViewer();

            if (lcId && result?.content?.linkCategoryId !== lcId) {
                return;
            }

            linkItemListHook.setContent(prev => {
                return [result?.content, ...prev];
            });
        }
    }

    const handleReqDelete = async (linkItemIndex) => {
        if (linkItemIndex === null || linkItemIndex === undefined) {
            customToast.error('삭제 대상을 확인할 수 없습니다.');
            return;
        }

        const targetLinkItem = linkItemListHook?.content?.find((_, index) => index === linkItemIndex);

        if (!targetLinkItem) {
            customToast.error('삭제 대상을 확인할 수 없습니다.');
            return;
        }

        let body = {
            userPageId: userPageContextValueHook?.userPage?.id,
            id: targetLinkItem?.id
        }

        customBackdropController.showBackdrop();

        const result = await apiHook.reqDeleteLinkItem({ body });

        customBackdropController.hideBackdrop();

        if (result?.content) {
            linkItemListHook.setContent(prev => {
                return prev?.filter(r => r.id !== result?.content?.id)
            })
            onRefreshPreviewViewer();
        }
    }

    const handleReqChangeIsHide = async (linkItemIndex) => {
        if (linkItemIndex === null || linkItemIndex === undefined) {
            customToast.error('작업 대상을 확인할 수 없습니다.');
            return;
        }

        const targetLinkItem = linkItemListHook?.content?.find((_, index) => index === linkItemIndex);

        if (!targetLinkItem) {
            customToast.error('작업 대상을 확인할 수 없습니다.');
            return;
        }

        let body = {
            userPageId: userPageContextValueHook?.userPage?.id,
            id: targetLinkItem?.id,
            isHide: !targetLinkItem?.isHide
        }

        customBackdropController.showBackdrop();

        const result = await apiHook.reqUpdateLinkItemIsHide({ body });

        customBackdropController.hideBackdrop();

        if (result?.content) {
            linkItemListHook.setContent(prev => {
                return prev?.map(prevContent => {
                    if (prevContent?.id === result?.content?.id) {
                        return {
                            ...result?.content
                        }
                    } else {
                        return {
                            ...prevContent
                        }
                    }
                });
            });
            onRefreshPreviewViewer();
        }
    }

    const handleReqUpdateInfo = async (updateForm) => {
        let body = {
            ...updateForm
        }

        customBackdropController.showBackdrop();

        const result = await apiHook.reqUpdateLinkItemInfo({ body });

        customBackdropController.hideBackdrop();

        if (result?.content) {
            onRefreshPreviewViewer();

            let newContent = linkItemListHook?.content?.map(prevContent => {
                if (prevContent?.id === result?.content?.id) {
                    return {
                        ...result?.content
                    }
                } else {
                    return {
                        ...prevContent
                    }
                }
            });

            if (lcId && lcId !== result?.content?.linkCategoryId) {
                newContent = newContent.filter(r => r.id !== result?.content?.id)
            }

            linkItemListHook.setContent(prev => {
                return newContent;
            })

        }
    }

    const handleMoreItems = async () => {
        setMoreDisabled(true);
        await handleReqFetchLinkItemList();
        setMoreDisabled(false);
    }

    const handleDragEnd = async (e) => {
        const source = e.source;
        const destination = e.destination;

        if (!destination) {
            return;
        }
        let orderNumbers = linkItemListHook.content.map(r => r.orderNumber);
        let newList = listUtils.reorder(linkItemListHook.content, source.index, destination.index)
            .map((r, index) => { return { ...r, orderNumber: orderNumbers[index] } });

        if (JSON.stringify(newList) === JSON.stringify(linkItemListHook.content)) {
            return;
        }
        let body = {
            content: [...newList].map(r => {
                return {
                    id: r.id,
                    orderNumber: r.orderNumber
                }
            }),
            userPageId: userPageContextValueHook?.userPage?.id
        }

        linkItemListHook.setContent(prev => {
            return [...newList];
        })

        // 비동기 요청
        const changeResult = await apiHook.reqChangeLinkItems_orderNumber({ body });

        onRefreshPreviewViewer();
        if (!changeResult?.content) {
            customToast.error('링크 순서 변경중 에러가 발생했습니다. 새로고침 후 다시 시도해 주세요.')
            return;
        }
    }

    return (
        <>
            <St.Container>
                <WegetAddModule
                    onReqCreateLink={handleReqCreateLink}
                />
                {!linkItemListHook?.isLoading &&
                    <St.LinkItemListContainer.Root>
                        <DragDropContext
                            onDragEnd={handleDragEnd}
                        >
                            <Droppable
                                droppableId={'sortDroppableId'}
                                direction="vertical"
                                isCombineEnabled
                            >
                                {(droppableProvided) => (
                                    <div
                                        className='wrapper'
                                        ref={droppableProvided.innerRef}
                                        {...droppableProvided.droppableProps}
                                    >
                                        {linkItemListHook?.content?.map((moduleItem, index) => {
                                            const type = moduleItem?.type;
                                            if (type === 'LINK') {
                                                const clickLog = clickLogList?.find(r => r.linkItemId === moduleItem?.id);
                                                return (
                                                    <WegetLinkItem
                                                        key={moduleItem?.id}
                                                        linkItemIndex={index}
                                                        linkItem={moduleItem}
                                                        clickLog={clickLog}
                                                        onReqDelete={(linkItemIndex) => handleReqDelete(linkItemIndex)}
                                                        onReqChangeIsHide={(linkItemIndex) => handleReqChangeIsHide(linkItemIndex)}
                                                        onReqUpdateInfo={(updateForm) => handleReqUpdateInfo(updateForm)}
                                                    />
                                                );
                                            }

                                            if (type === 'TEXT') {
                                                return (
                                                    <WegetTextItem
                                                        key={moduleItem?.id}
                                                        moduleItemIndex={index}
                                                        moduleItem={moduleItem}
                                                        onReqDelete={(linkItemIndex) => handleReqDelete(linkItemIndex)}
                                                        onReqChangeIsHide={(linkItemIndex) => handleReqChangeIsHide(linkItemIndex)}
                                                        onReqUpdateInfo={(updateForm) => handleReqUpdateInfo(updateForm)}
                                                    />
                                                );
                                            }

                                            if (type === 'SEPARATOR') {
                                                return (
                                                    <WegetSeparatorItem
                                                        key={moduleItem?.id}
                                                        moduleItemIndex={index}
                                                        moduleItem={moduleItem}
                                                        onReqDelete={(linkItemIndex) => handleReqDelete(linkItemIndex)}
                                                        onReqChangeIsHide={(linkItemIndex) => handleReqChangeIsHide(linkItemIndex)}
                                                        onReqUpdateInfo={(updateForm) => handleReqUpdateInfo(updateForm)}
                                                    />
                                                );
                                            }
                                        })}
                                        {droppableProvided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </St.LinkItemListContainer.Root>
                }
                {!linkItemListHook?.isLoading &&
                    <St.LoadMoreButtonWrapper>
                        {linkItemListHook?.hasMore ?
                            <>
                                {moreDisabled ?
                                    <div className='moreLoadingBox'>
                                        <CircularProgress className='progress' size={30} />
                                    </div>
                                    :
                                    <CustomBlockButton
                                        type='button'
                                        className='moreButton'
                                        onClick={() => handleMoreItems()}
                                        disabled={moreDisabled}
                                    >
                                        더보기
                                    </CustomBlockButton>
                                }
                            </>
                            :
                            <div className='lastContent'>
                                마지막 콘텐츠 입니다.
                            </div>
                        }
                    </St.LoadMoreButtonWrapper>
                }
            </St.Container >

            {addLinkItemModalOpen &&
                <AddLinkItemModal
                    open={addLinkItemModalOpen}
                    onClose={() => toggleAddLinkItemModalOpen(false)}
                    onConfirm={(body) => handleReqCreateLink(body)}
                />
            }
        </>
    );
}

function LinkItem({
    linkItemIndex,
    linkItem,
    clickLog,
    onReqDelete,
    onReqChangeIsHide,
    onReqUpdateInfo
}) {
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();
    const descriptionPopoverHook = useCustomPopoverHook();
    const thumbnailPopoverHook = useCustomPopoverHook();
    const deletePopoverHook = useCustomPopoverHook();

    const [updateLinkItemModalOpen, setUpdateLinkItemModalOpen] = useState(false);

    const toggleUpdateLinkItemModalOpen = (bool) => {
        setUpdateLinkItemModalOpen(bool);
    }

    const handleDelete = async () => {
        deletePopoverHook.closePopover();
        await onReqDelete(linkItemIndex);
    }

    const handleSubmitUpdateLinkItem = (updateForm) => {
        updateForm = {
            id: linkItem?.id,
            ...updateForm
        }

        onReqUpdateInfo(updateForm);
        toggleUpdateLinkItemModalOpen(false);
    }

    return (
        <>
            <Draggable key={linkItem.id} draggableId={`SubFdElements-${linkItem.id}`} index={linkItemIndex}>
                {(draggableProvided) => (
                    <St.LinkItemListContainer.LinkItemWrapper
                        ref={draggableProvided.innerRef}
                        {...draggableProvided.draggableProps}
                    >
                        <div className='deleteButtonField'>
                            <Tooltip title='삭제'>
                                <CustomBlockButton
                                    type='button'
                                    onClick={(e) => deletePopoverHook.openPopover(e, linkItemIndex)}
                                >
                                    <DeleteIcon />
                                </CustomBlockButton>
                            </Tooltip>
                        </div>
                        {linkItem?.linkCategoryId &&
                            <div className='categoryBadgeField'>
                                <Tooltip title='카테고리'>
                                    <span className='categoryBadge active'>
                                        {linkCategoryContextValueHook?.content?.find(r => r.id === linkItem?.linkCategoryId)?.name}
                                    </span>
                                </Tooltip>

                            </div>
                        }
                        <section className='first'>
                            <div className='icon' {...draggableProvided.dragHandleProps}>
                                <CustomRatioImage width={1} height={1}>
                                    <CustomRatioImage.Image src='/assets/icons/dragIndicator_808080.svg' />
                                </CustomRatioImage>
                            </div>
                        </section>
                        <section className='second'>
                            <section className='second_1'>
                                <section className='second_1_1'>
                                    <div className='title'>{linkItem?.title}</div>
                                    <div className='linkUrl'>
                                        {linkItem?.linkUrl}
                                    </div>
                                </section>
                                <section className='second_1_2'>
                                    <Tooltip title='수정'>
                                        <CustomBlockButton
                                            type='button'
                                            onClick={() => toggleUpdateLinkItemModalOpen(true)}
                                        >
                                            <div className='icon'>
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src='/assets/icons/edit2_000000.svg' />
                                                </CustomRatioImage>
                                            </div>
                                        </CustomBlockButton>
                                    </Tooltip>
                                    <div className='switchBox'>
                                        <Tooltip title='보이기 or 숨기기'>
                                            <Switch size='small' color='success' onChange={e => onReqChangeIsHide(linkItemIndex)} checked={!linkItem?.isHide} />
                                        </Tooltip>
                                    </div>
                                </section>
                            </section>
                            <section className='second_2'>
                                <section className='second_2_1'>
                                    <div>
                                        <Tooltip title='설명'>
                                            <CustomBlockButton
                                                type='button'
                                                onClick={(e) => descriptionPopoverHook.openPopover(e, linkItem?.description)}
                                            >
                                                <div className='icon'>
                                                    <CustomRatioImage width={1} height={1}>
                                                        <CustomRatioImage.Image src='/assets/icons/description_000000.svg' />
                                                    </CustomRatioImage>
                                                </div>
                                            </CustomBlockButton>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <Tooltip title='썸네일'>
                                            <CustomBlockButton
                                                type='button'
                                                onClick={(e) => thumbnailPopoverHook.openPopover(e, linkItem?.thumbnailUrl)}
                                            >
                                                <div className='icon'>
                                                    <CustomRatioImage width={1} height={1}>
                                                        <CustomRatioImage.Image src='/assets/icons/image_000000.svg' />
                                                    </CustomRatioImage>
                                                </div>
                                            </CustomBlockButton>
                                        </Tooltip>
                                    </div>
                                    <div className='clickLogGroup'>
                                        <div>
                                            <Tooltip title='클릭 수'>
                                                <CustomBlockButton
                                                    type='button'
                                                    className='rectangle'
                                                // onClick={(e) => thumbnailPopoverHook.openPopover(e, linkItem?.thumbnailUrl)}
                                                >
                                                    <div className='flexBox'>
                                                        <div className='icon'>
                                                            <CustomRatioImage width={1} height={1}>
                                                                <CustomRatioImage.Image src='/assets/icons/click_totalClick.svg' />
                                                            </CustomRatioImage>
                                                        </div>
                                                        <div className='count'>{clickLog?.totalClickCount || 0}</div>
                                                    </div>
                                                </CustomBlockButton>
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <Tooltip title='유효 클릭 수'>
                                                <CustomBlockButton
                                                    type='button'
                                                    className='rectangle'
                                                // onClick={(e) => thumbnailPopoverHook.openPopover(e, linkItem?.thumbnailUrl)}
                                                >
                                                    <div className='flexBox'>
                                                        <div className='icon'>
                                                            <CustomRatioImage width={1} height={1}>
                                                                <CustomRatioImage.Image src='/assets/icons/click_firstClick.svg' />
                                                            </CustomRatioImage>
                                                        </div>
                                                        <div className='count'>{clickLog?.firstClickCount || 0}</div>
                                                    </div>
                                                </CustomBlockButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </section>
                            </section>
                        </section>
                        {draggableProvided.placeholder}
                    </St.LinkItemListContainer.LinkItemWrapper>
                )}
            </Draggable>
            {descriptionPopoverHook?.anchorEl &&
                <CustomPopover
                    anchorEl={descriptionPopoverHook?.anchorEl}
                    onClose={() => descriptionPopoverHook?.closePopover()}
                    layoutStyle={!descriptionPopoverHook?.element ? { color: '#808080' } : {}}
                >
                    {descriptionPopoverHook?.element ? descriptionPopoverHook?.element : '등록된 설명이 없습니다.'}
                </CustomPopover>
            }

            {thumbnailPopoverHook?.anchorEl &&
                <CustomPopover
                    anchorEl={thumbnailPopoverHook?.anchorEl}
                    onClose={() => thumbnailPopoverHook?.closePopover()}
                    layoutStyle={!thumbnailPopoverHook?.element ? { color: '#808080' } : {}}
                >
                    {thumbnailPopoverHook?.element ?
                        (
                            <St.ThumbnailPopoverElement>
                                <CustomRatioImage>
                                    <CustomRatioImage.Image src={thumbnailPopoverHook?.element} />
                                </CustomRatioImage>
                            </St.ThumbnailPopoverElement>
                        )
                        :
                        '등록된 썸네일이 없습니다.'
                    }
                </CustomPopover>
            }
            <CustomPopover
                anchorEl={deletePopoverHook?.anchorEl}
                onClose={() => deletePopoverHook?.closePopover()}
                customLayout
            >
                <St.DeletePopoverElement>
                    <div className='notice'>삭제 버튼을 누르면 영구적으로 삭제됩니다.</div>
                    <CustomBlockButton
                        type='button'
                        onClick={() => handleDelete()}
                    >
                        삭제
                    </CustomBlockButton>
                </St.DeletePopoverElement>
            </CustomPopover>

            {(updateLinkItemModalOpen && linkItem) &&
                <UpdateLinkItemModal
                    open={updateLinkItemModalOpen}
                    linkItem={linkItem}
                    onClose={() => toggleUpdateLinkItemModalOpen(false)}
                    onConfirm={(body) => handleSubmitUpdateLinkItem(body)}
                />
            }
        </>
    );
}

function AddLinkItemModal({
    open = false,
    onClose = () => { },
    onConfirm = () => { }
}) {
    const queryParamsHook = useQueryParamsHook();
    const lcId = queryParamsHook.params?.lcId;

    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();

    const imageUploaderRef = useRef();

    const [createForm, setCreateForm] = useState({
        title: '',
        linkUrl: '',
        description: '',
        thumbnailUrl: '',
        linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r.id === lcId)?.id || ''
    })

    const [thumbnailDropdownOpen, setThumbnailDropdownOpen] = useState(false);

    const toggleThumbnailDropdownOpen = (bool) => {
        setThumbnailDropdownOpen(bool);
    }


    const handleChangeTitle = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            title: value
        }))
    }

    const handleChangeLinkUrl = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            linkUrl: value
        }))
    }

    const handleChangeDescription = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            description: value
        }))
    }

    const handleChangeLinkCategoryId = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            linkCategoryId: value || ''
        }))
    }

    const handleImageUploaderOpen = () => {
        imageUploaderRef.current.click();
    }

    const handleSelectThumbnail = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            customToast('올바른 이미지 파일을 선택해 주세요.');
            return;
        }

        let fileSize = file.size;
        let extension = file.name.split('.').pop();

        if (fileSize > 5 * 1024 * 1024) {
            customToast.error('이미지 크기는 최대 5MB까지만 허용됩니다.');
            return;
        }

        customBackdropController.showBackdrop();

        const fileRef = ref(firebaseStorage, `/assets/user_pages/${userPageContextValueHook?.userPage?.id}/link_thumbnail_images/${uuidv4()}.${extension}`);

        const uploadedUrl = await uploadBytes(fileRef, file).then(async snapshot => {
            return await getDownloadURL(snapshot.ref)
                .then(url => {
                    return url
                })
        });
        customBackdropController.hideBackdrop();

        if (uploadedUrl) {
            setCreateForm(prev => ({
                ...prev,
                thumbnailUrl: uploadedUrl
            }))
        };
    }

    const handleRemoveThumbnail = async () => {
        setCreateForm(prev => ({
            ...prev,
            thumbnailUrl: ''
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let newUrl = createForm?.linkUrl;

        if (createForm?.title?.length < 1 || createForm?.title?.length > 50) {
            customToast.error('링크 주제는 1-50 자 내외 필수 입력 입니다.');
            return;
        }

        if (!customRegexUtils.hasHttpPrefix(newUrl)) {
            newUrl = `http://${newUrl}`;
        }

        if (!customRegexUtils.isValidUrlFormat(newUrl)) {
            customToast.error('올바른 URL을 입력해 주세요. \nex) https://www.example.com');
            return;
        }

        if (createForm?.description?.length > 100) {
            customToast.error('설명은 100자 이내로 입력 가능합니다.');
            return;
        }

        let body = {
            title: createForm?.title?.trim(),
            linkUrl: newUrl,
            description: createForm?.description.trim(),
            thumbnailUrl: createForm?.thumbnailUrl,
            linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r?.id === createForm?.linkCategoryId)?.id || ''
        }

        onConfirm(body);
    }

    return (
        <>
            <CustomDialog
                open={open}
                onClose={() => onClose()}
                maxWidth='sm'
            >
                <St.AddLinkItemModalContainer>
                    <CustomDialog.Header style={{ marginBottom: '20px' }}>
                        <CustomDialog.Header.Fake />
                        <CustomDialog.Header.Title>모듈추가</CustomDialog.Header.Title>
                        <CustomDialog.Header.Close onClick={() => onClose()} />
                    </CustomDialog.Header>
                    <div className='wrapper'>
                        <div className='wrapper__inputBox'>
                            <CustomInput
                                type='text'
                                value={createForm?.title}
                                onChange={(e) => handleChangeTitle(e)}
                            />
                            <label><span style={{ color: '#ef476f' }}>*</span> 링크 주제</label>
                        </div>
                        <div className='wrapper__inputBox'>
                            <CustomInput
                                type='text'
                                value={createForm?.linkUrl}
                                onChange={(e) => handleChangeLinkUrl(e)}
                            />
                            <label><span style={{ color: '#ef476f' }}>*</span> 링크 URL</label>
                        </div>
                        <div className='wrapper__inputBox'>
                            <CustomInput
                                type='text'
                                value={createForm?.description}
                                onChange={(e) => handleChangeDescription(e)}
                            />
                            <label>설명</label>
                        </div>
                        <div className={`wrapper__thumbnailWrapper ${thumbnailDropdownOpen ? 'open' : ''}`}>
                            <div className={`wrapper__thumbnailWrapper__header ${thumbnailDropdownOpen ? 'open' : ''}`} onClick={() => toggleThumbnailDropdownOpen(!thumbnailDropdownOpen)}>
                                <div className='wrapper__thumbnailWrapper__header__title'>썸네일</div>
                                <div className='wrapper__thumbnailWrapper__header__icon'>
                                    {thumbnailDropdownOpen ?
                                        (
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/expandLess_000000.svg' />
                                            </CustomRatioImage>
                                        )
                                        :
                                        (
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/expandMore_000000.svg' />
                                            </CustomRatioImage>
                                        )}
                                </div>
                            </div>
                            {thumbnailDropdownOpen &&
                                <div className='wrapper__thumbnailWrapper__contentLayout'>
                                    <div className='wrapper__thumbnailWrapper__contentLayout__thumbnailBox'>
                                        {createForm?.thumbnailUrl ?
                                            (
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src={createForm?.thumbnailUrl} />
                                                </CustomRatioImage>
                                            )
                                            :
                                            (
                                                <div className='wrapper__thumbnailWrapper__contentLayout__thumbnailBox__blankText'>
                                                    권장 이미지 사이즈<br />256 x 256
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='wrapper__thumbnailWrapper__contentLayout__buttonGroup'>
                                        <CustomBlockButton
                                            type='button'
                                            className='select'
                                            onClick={() => handleImageUploaderOpen()}
                                        >
                                            썸네일 선택
                                        </CustomBlockButton>
                                        <CustomBlockButton
                                            type='button'
                                            className='remove'
                                            onClick={() => handleRemoveThumbnail()}
                                        >
                                            썸네일 제거
                                        </CustomBlockButton>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='wrapper__selectBox'>
                            <CustomSelect
                                value={createForm?.linkCategoryId}
                                onChange={(e) => handleChangeLinkCategoryId(e)}
                            >
                                <option value=''>전체</option>
                                {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                    return (
                                        <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                    );
                                })}
                            </CustomSelect>
                            <label>카테고리{createForm?.linkCategoryId && <span style={{ color: 'var(--redColor1)', paddingLeft: '10px' }}>* 카테고리가 선택 되었어요!</span>}</label>
                        </div>
                        <div className='wrapper__footerButtonGroup'>
                            <CustomBlockButton
                                type='button'
                                onClick={(e) => handleSubmit(e)}
                            >
                                추가
                            </CustomBlockButton>
                        </div>
                    </div>
                </St.AddLinkItemModalContainer>
            </CustomDialog>

            <input
                ref={imageUploaderRef}
                type="file"
                accept="image/*"
                onClick={(e) => e.target.value = ''}
                onChange={(e) => handleSelectThumbnail(e)}
                hidden
            />
        </>
    );
}

function UpdateLinkItemModal({
    open = false,
    linkItem,
    onClose = () => { },
    onConfirm = () => { }
}) {
    const userPageContextValueHook = useUserPageContextValueHook();
    const linkCategoryContextValueHook = useLinkCategoryContextValueHook();

    const imageUploaderRef = useRef();

    const [createForm, setCreateForm] = useState({
        title: linkItem?.title,
        linkUrl: linkItem?.linkUrl,
        description: linkItem?.description,
        thumbnailUrl: linkItem?.thumbnailUrl,
        linkCategoryId: linkItem?.linkCategoryId || ''
    })

    const [thumbnailDropdownOpen, setThumbnailDropdownOpen] = useState(linkItem?.thumbnailUrl ? true : false);

    const toggleThumbnailDropdownOpen = (bool) => {
        setThumbnailDropdownOpen(bool);
    }


    const handleChangeTitle = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            title: value
        }))
    }

    const handleChangeLinkUrl = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            linkUrl: value
        }))
    }

    const handleChangeDescription = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            description: value
        }))
    }

    const handleChangeLinkCategoryId = (e) => {
        const value = e.target.value;

        setCreateForm(prev => ({
            ...prev,
            linkCategoryId: value || ''
        }))
    }

    const handleImageUploaderOpen = () => {
        imageUploaderRef.current.click();
    }

    const handleSelectThumbnail = async (e) => {
        let file = e.target.files[0];

        if (!file) {
            customToast('올바른 이미지 파일을 선택해 주세요.');
            return;
        }

        let fileSize = file.size;
        let extension = file.name.split('.').pop();

        if (fileSize > 5 * 1024 * 1024) {
            customToast.error('이미지 크기는 최대 5MB까지만 허용됩니다.');
            return;
        }

        customBackdropController.showBackdrop();

        const fileRef = ref(firebaseStorage, `/assets/user_pages/${userPageContextValueHook?.userPage?.id}/link_thumbnail_images/${uuidv4()}.${extension}`);

        const uploadedUrl = await uploadBytes(fileRef, file).then(async snapshot => {
            return await getDownloadURL(snapshot.ref)
                .then(url => {
                    return url
                })
        });
        customBackdropController.hideBackdrop();

        if (uploadedUrl) {
            setCreateForm(prev => ({
                ...prev,
                thumbnailUrl: uploadedUrl
            }))
        };
    }

    const handleRemoveThumbnail = async () => {
        setCreateForm(prev => ({
            ...prev,
            thumbnailUrl: ''
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        let newUrl = createForm?.linkUrl;

        if (createForm?.title?.length < 1 || createForm?.title?.length > 50) {
            customToast.error('링크 주제는 1-50 자 내외 필수 입력 입니다.');
            return;
        }

        if (!customRegexUtils.hasHttpPrefix(newUrl)) {
            newUrl = `http://${newUrl}`;
        }

        if (!customRegexUtils.isValidUrlFormat(newUrl)) {
            customToast.error('올바른 URL을 입력해 주세요. \nex) https://www.example.com');
            return;
        }

        if (createForm?.description?.length > 100) {
            customToast.error('설명은 100자 이내로 입력 가능합니다.');
            return;
        }

        let body = {
            title: createForm?.title?.trim(),
            linkUrl: newUrl,
            description: createForm?.description.trim(),
            thumbnailUrl: createForm?.thumbnailUrl,
            linkCategoryId: linkCategoryContextValueHook?.content?.find(r => r?.id === createForm?.linkCategoryId)?.id || ''
        }

        onConfirm(body);
    }

    return (
        <>
            <CustomDialog
                open={open}
                onClose={() => onClose()}
                maxWidth='sm'
            >
                <CustomDialog.CloseButton onClose={() => onClose()} />
                <St.AddLinkItemModalContainer>
                    <div className='wrapper'>
                        <div className='wrapper__inputBox'>
                            <CustomInput
                                type='text'
                                value={createForm?.title}
                                onChange={(e) => handleChangeTitle(e)}
                            />
                            <label><span style={{ color: '#ef476f' }}>*</span> 링크 주제</label>
                        </div>
                        <div className='wrapper__inputBox'>
                            <CustomInput
                                type='text'
                                value={createForm?.linkUrl}
                                onChange={(e) => handleChangeLinkUrl(e)}
                            />
                            <label><span style={{ color: '#ef476f' }}>*</span> 링크 URL</label>
                        </div>
                        <div className='wrapper__inputBox'>
                            <CustomInput
                                type='text'
                                value={createForm?.description}
                                onChange={(e) => handleChangeDescription(e)}
                            />
                            <label>설명</label>
                        </div>
                        <div className={`wrapper__thumbnailWrapper ${thumbnailDropdownOpen ? 'open' : ''}`}>
                            <div className={`wrapper__thumbnailWrapper__header ${thumbnailDropdownOpen ? 'open' : ''}`} onClick={() => toggleThumbnailDropdownOpen(!thumbnailDropdownOpen)}>
                                <div className='wrapper__thumbnailWrapper__header__title'>썸네일</div>
                                <div className='wrapper__thumbnailWrapper__header__icon'>
                                    {thumbnailDropdownOpen ?
                                        (
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/expandLess_000000.svg' />
                                            </CustomRatioImage>
                                        )
                                        :
                                        (
                                            <CustomRatioImage width={1} height={1}>
                                                <CustomRatioImage.Image src='/assets/icons/expandMore_000000.svg' />
                                            </CustomRatioImage>
                                        )}
                                </div>
                            </div>
                            {thumbnailDropdownOpen &&
                                <div className='wrapper__thumbnailWrapper__contentLayout'>
                                    <div className='wrapper__thumbnailWrapper__contentLayout__thumbnailBox'>
                                        {createForm?.thumbnailUrl ?
                                            (
                                                <CustomRatioImage width={1} height={1}>
                                                    <CustomRatioImage.Image src={createForm?.thumbnailUrl} />
                                                </CustomRatioImage>
                                            )
                                            :
                                            (
                                                <div className='wrapper__thumbnailWrapper__contentLayout__thumbnailBox__blankText'>
                                                    권장 이미지 사이즈<br />256 x 256
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='wrapper__thumbnailWrapper__contentLayout__buttonGroup'>
                                        <CustomBlockButton
                                            type='button'
                                            className='select'
                                            onClick={() => handleImageUploaderOpen()}
                                        >
                                            썸네일 선택
                                        </CustomBlockButton>
                                        <CustomBlockButton
                                            type='button'
                                            className='remove'
                                            onClick={() => handleRemoveThumbnail()}
                                        >
                                            썸네일 제거
                                        </CustomBlockButton>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='wrapper__selectBox'>
                            <CustomSelect
                                value={createForm?.linkCategoryId}
                                onChange={(e) => handleChangeLinkCategoryId(e)}
                            >
                                <option value=''>전체</option>
                                {linkCategoryContextValueHook?.content?.map(linkCategory => {
                                    return (
                                        <option key={linkCategory?.id} value={linkCategory?.id}>{linkCategory?.name}</option>
                                    );
                                })}
                            </CustomSelect>
                            <label>카테고리{createForm?.linkCategoryId && <span style={{ color: 'var(--redColor1)', paddingLeft: '10px' }}>* 카테고리가 선택 되었어요!</span>}</label>
                        </div>
                        <div className='wrapper__footerButtonGroup'>
                            <CustomBlockButton
                                type='button'
                                onClick={(e) => handleSubmit(e)}
                            >
                                수정
                            </CustomBlockButton>
                        </div>
                    </div>
                </St.AddLinkItemModalContainer>
            </CustomDialog>

            <input
                ref={imageUploaderRef}
                type="file"
                accept="image/*"
                onClick={(e) => e.target.value = ''}
                onChange={(e) => handleSelectThumbnail(e)}
                hidden
            />
        </>
    );
}