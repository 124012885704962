import { useSearchParams } from 'react-router-dom';

/*
    <setSearchParams>:
    navigate 와 비슷한 동작을 함.
    예시) setSearchParams({...query, link:'abc'}, {replace:true}) => {pathname}?link=abc
*/
export function useQueryParamsHook(props) {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = {};

    for (const [key, value] of searchParams) {
        if (params.hasOwnProperty(key)) {
            // 파라미터 키가 이미 존재하면 배열로 변환 (동일한 키에 다중 값 처리)
            params[key] = [].concat(params[key], value);
        } else {
            params[key] = value;
        }
    }

    return {
        params,
        searchParams,
        setSearchParams
    }
}