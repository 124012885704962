import styled from 'styled-components';

export const Title = styled.h2`
    padding: 0 20px;
    margin: 0;
    margin-top: 20px;
    word-break: keep-all;
`;

export const TabWrapper = styled.div`
    padding: 12px;
    border-bottom: 1px solid #f0f0f0;
    .tabList{
        display: flex;
        gap: 5px;

        button{
            width: 24px;
            height: 24px;
            border-radius: 50%;
            color: #444;

            &.active{
                border-color: var(--mainColor);
                color: var(--mainColor);
                font-weight: 700;
            }
        }
    }
`;

export const BodyWrapper = styled.div`
`;

export const ThumbnailImage = styled.div`
    margin-left: auto;
    margin-right: auto;
    
    .imageBox{
        overflow: hidden;
    }
`;

export const FooterWrapper = styled.div`
    border-top: 1px solid #f0f0f0;
    position: sticky;
    bottom: 0;
    left: 0;
    padding: 5px 12px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .closeButton{
        width: fit-content;
        height: fit-content;
        padding: 8px 16px;
        background: #000;
        color: #fff;
        border-radius: 10px;
        font-size:14px;
        font-weight: 700;
    }
    
    .closeToday{
        flex:1;
        display: flex;
        align-items: center;
        gap: 5px;
        input{
            cursor: pointer;
        }
        label{
            cursor: pointer;
            font-size: 14px;
            font-weight: 600;
        }
    }
`;