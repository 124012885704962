import styled from 'styled-components';

export const Container = styled.div`
    button{
        height: 60px;
        font-weight: 700;
        font-size: 18px;
        border-radius: 30px;
        background-color: var(--mainColor);
        color: #fff;
        border: none;

        &:hover{
            background-color: var(--mainColorHover);
        }

        .content{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;
            width: 100%;
            height: 100%;
            justify-content: center;

            .content__icon{
                width: 28px;
            }
        }
    }
`;

export const AddModal = {
    DefaultModeContainer: styled.div`
        padding: 20px;

        .body{
            .itemList{
                display: flex;
                flex-direction: column;

                .itemBox{
                    padding: 20px 10px;
                    border-radius: 15px;
                    cursor: pointer;

                    &:hover{
                        background: #efefef;
                    }

                    .flex{
                        display: flex;
                        align-items: center;

                        &.justifyContent_SpaceBetween{
                            justify-content: space-between;
                        }

                        &.gap_10{
                            gap:10px
                        }
                    }

                    .iconFigure{
                        width: 28px;
                        height: 28px;

                        &.small{
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .text{
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
        }
    `,
    AddLinkModeContainer: styled.div`
        padding:20px;

        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 40px;

            
            .wrapper__inputBox{
                position: relative;
                label {
                    position: absolute;
                    top: 15px;
                    left: 20px;
                    transition: top 0.3s, left 0.3s;
                    pointer-events: none;
                    font-size: 14px;
                    color: #606060;
                    font-weight: 500;
                }

                input{
                    border-radius: 30px;
                    height: 50px;
                    border: 2px solid #00000000;
                    background: var(--defaultElementBC);
                    padding: 0 20px;
                    font-weight: 700;
                    &:focus{
                        border-color: #000000;
                    }
                }

                input:focus ~ label,
                input:not(:placeholder-shown) ~ label {
                    top: -20px;
                    left: 20px;
                    font-size: 12px; /* 라벨 크기를 조정할 수 있습니다. */
                }
            }

            .wrapper__selectBox{
                position: relative;
                label {
                    position: absolute;
                    top: -20px;
                    left: 20px;
                    transition: top 0.3s, left 0.3s;
                    pointer-events: none;
                    font-size: 12px;
                    color: #606060;
                    font-weight: 500;
                }

                select{
                    border-radius: 15px;
                    height: 50px;
                    border: 1.5px solid #00000000;
                    background-color: var(--defaultElementBC);
                    padding: 0 20px;
                    font-weight: 700;
                    &:focus{
                        border-color: #000000;
                    }
                }
            }

            .wrapper__thumbnailWrapper{
                display: flex;
                flex-direction: column;
                justify-content: center;
                border-radius: 15px;
                overflow: hidden;
                
                &.open{
                    box-shadow: var(--defaultBoxShadow2);
                }

                .wrapper__thumbnailWrapper__header{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 20px;
                    height: 50px;
                    background-color: var(--defaultElementBC);
                    cursor: pointer;

                    &.open{
                        border-bottom: 1px solid #f0f0f0;
                    }

                    &:hover{
                        background-color: #f0f0f0;
                    }
                    
                    .wrapper__thumbnailWrapper__header__title{
                        font-size: 14px;
                        font-weight: 600;
                        color: #606060;
                    }

                    .wrapper__thumbnailWrapper__header__icon{
                        width: 30px;
                    }
                }

                .wrapper__thumbnailWrapper__contentLayout{
                    display: flex;
                    flex-direction: row;
                    gap: 20px;
                    align-items: center;    
                    padding: 20px;


                    @media all and (max-width:992px){
                        flex-direction: column;
                    }

                    .wrapper__thumbnailWrapper__contentLayout__thumbnailBox{
                        position: relative;
                        width: 150px;
                        height: 150px;
                        border: 1px solid #f0f0f0;
                        border-radius: 15px;
                        background: #fff;
                        overflow: hidden;

                        .wrapper__thumbnailWrapper__contentLayout__thumbnailBox__blankText{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            box-sizing: border-box;
                            width: 100%;
                            padding: 10px;
                            text-align: center;
                            color: #606060;
                            font-size: 14px;
                        }
                    }

                    .wrapper__thumbnailWrapper__contentLayout__buttonGroup{
                        flex:1;
                        display: flex;
                        flex-direction: column;
                        gap: 20px;

                        @media all and (max-width:992px){
                            width:100%;
                        }

                        button{
                            height: 50px;
                            border-radius: 30px;
                            color: #fff;
                            font-weight: 700;
                            border: none;
                        }

                        button.select{
                            background: #000;
                        }

                        button.remove{
                            background:var(--defaultElementBC);
                            color: #000;
                        }
                    }
                }
            }

            .wrapper__footerButtonGroup{
                display: flex;
                gap: 10px;

                button{
                    padding: 0 16px;
                    border-radius: 30px;
                    height: 50px;
                    background-color: var(--mainColor);
                    border: none;
                    font-size: 16px;
                    font-weight: 700;
                    color: #fff;

                    &:hover{
                        background-color: var(--mainColorHover);
                    }
                }
            }
        }
    `,
    AddTextModeContainer: styled.div`
        padding:20px;

        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 40px;

            .textareaBox{
                textarea{
                    height: 100px;
                }

                .counter{
                    font-size: 10px;
                    margin-top: 5px;
                    padding-right: 20px;
                    text-align: right;
                    font-weight: 600;
                }
            }

            .wrapper__selectBox{
                position: relative;
                label {
                    position: absolute;
                    top: -20px;
                    left: 20px;
                    transition: top 0.3s, left 0.3s;
                    pointer-events: none;
                    font-size: 12px;
                    color: #606060;
                    font-weight: 500;
                }

                select{
                    border-radius: 15px;
                    height: 50px;
                    border: 1.5px solid #00000000;
                    background-color: var(--defaultElementBC);
                    padding: 0 20px;
                    font-weight: 700;
                    &:focus{
                        border-color: #000000;
                    }
                }
            }

            .wrapper__footerButtonGroup{
                display: flex;
                gap: 10px;

                button{
                    padding: 0 16px;
                    border-radius: 30px;
                    height: 50px;
                    background-color: var(--mainColor);
                    border: none;
                    font-size: 16px;
                    font-weight: 700;
                    color: #fff;

                    &:hover{
                        background-color: var(--mainColorHover);
                    }
                }
            }
        }
    `,
    AddSeparatorModeContainer: styled.div`
        padding:20px;
        .wrapper{
            display: flex;
            flex-direction: column;
            gap: 40px;
            margin-top: 40px;
            
            &.default{
                display: flex;
                flex-direction: column;
                gap: 40px;
                .itemWrapper{
                    position: relative;
                    label {
                        position: absolute;
                        top: -20px;
                        left: 20px;
                        transition: top 0.3s, left 0.3s;
                        pointer-events: none;
                        font-size: 12px;
                        color: #606060;
                        font-weight: 500;
                    }
                    .itemBox{
                        cursor: pointer;
                        display: flex;
                        flex-direction: row;
                        padding: 20px 10px;
                        border-radius: 10px;
                        align-items: center;
                        justify-content: space-between;
                        gap: 20px;
                        background-color: var(--defaultElementBC);

                        &:hover{
                            background: #efefef;
                        }

                        section.content{
                            flex:1;

                            .name{
                                font-size: 14px;
                                font-weight: 700;
                                text-align: left;
                            }
                        }


                        section.go{
                            figure.icon{
                                margin:0;
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                }

                .wrapper__selectBox{
                    position: relative;
                    label {
                        position: absolute;
                        top: -20px;
                        left: 20px;
                        transition: top 0.3s, left 0.3s;
                        pointer-events: none;
                        font-size: 12px;
                        color: #606060;
                        font-weight: 500;
                    }

                    select{
                        border-radius: 15px;
                        height: 50px;
                        border: 1.5px solid #00000000;
                        background-color: var(--defaultElementBC);
                        padding: 0 20px;
                        font-weight: 700;
                        &:focus{
                            border-color: #000000;
                        }
                    }
                }

                .wrapper__footerButtonGroup{
                    display: flex;
                    gap: 10px;

                    button{
                        padding: 0 16px;
                        border-radius: 30px;
                        height: 50px;
                        background-color: var(--mainColor);
                        border: none;
                        font-size: 16px;
                        font-weight: 700;
                        color: #fff;

                        &:hover{
                            background-color: var(--mainColorHover);
                        }
                    }
                }
            }

            &.edit{
                .body{
                    .itemList{
                        display: flex;
                        flex-direction: column;
    
                        .itemBox{
                            cursor: pointer;
                            display: flex;
                            flex-direction: row;
                            padding: 20px 10px;
                            border-radius: 10px;
                            align-items: center;
                            justify-content: space-between;
                            gap: 20px;
    
                            &:hover{
                                background: #efefef;
                            }
    
                            section.content{
                                flex:1;
    
                                .name{
                                    font-size: 14px;
                                    font-weight: 700;
                                    text-align: left;
                                }
                            }
    
    
                            section.go{
                                figure.icon{
                                    margin:0;
                                    width: 18px;
                                    height: 18px;
                                }
                            }
                        }
                    }
                }
            }

        }
    `,
}